import { darken, transparentize } from "polished";
import styled from "styled-components";

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`;

export const ContainerModalEditOrNewAddress = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;

  div.addresses-bottom {
    display: flex;
    justify-content: flex-end;

    button.edit-address-btn,
    button.add-address-btn {
      font-size: 14px;
      color: ${(props) => props.theme.colors.primary};
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: none;
      font-weight: 700;
    }

    button.add-address-btn {
      margin-top: 12px;
      font-weight: 700;

      svg {
        margin-right: 8px;
      }
    }

    @media (max-width: 1100px) {
      justify-content: flex-start;
    }
  }
`;

export const AddressList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  gap: 16px;
  width: 100%;
`;

export const AddressItem = styled.div`
  width: 100%;
  padding: 24px;
  background: #ffffff;
  border: 1px solid
    ${(props) => (props.selected ? props.theme.colors.primary : "#ECECEC")};
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  transition: 100ms ease-in-out;
  justify-content: space-between;
  border-radius: 8px;

  div.address-info {
    display: flex;
    flex-direction: column;
    margin-right: 12px;

    h2 {
      color: var(--neutral-900, #151922);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    p {
      font-size: 14px;
      margin-top: 8px;
    }
  }

  button.choose-address-btn {
    width: 100%;
    max-width: 110px;
    height: 30px;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 1px solid
      ${(props) =>
        props.selected
          ? props.theme.colors.secondary
          : props.theme.colors.primary};
    color: ${(props) =>
      props.selected
        ? props.theme.colors.secondary
        : props.theme.colors.primary};
    transition: 200ms ease-in-out;
    font-weight: 500;

    :hover {
      background: ${(props) =>
        props.selected ? "transparent" : props.theme.colors.primary};
      color: ${(props) =>
        props.selected ? props.theme.colors.secondary : "#fff"};
    }

    &:disabled {
      background: none;
      color: ${(props) => transparentize(0.5, props.theme.colors.secondary)};
      border: 1px solid
        ${(props) => transparentize(0.5, props.theme.colors.secondary)};
    }
  }

  .line-address {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  :hover {
    border: 1px solid
      ${(props) =>
        props.selected
          ? props.theme.colors.neutral900
          : props.theme.colors.primary};
  }

  .radio {
    margin-top: 3px;
    margin-right: 10px;
    label {
      font-size: 14px;
    }
    input[type="radio"] {
      position: absolute;
      display: none;
      + .radio-label {
        &:before {
          content: "";
          background: #f4f4f4;
          border-radius: 100%;
          border: 1px solid ${(props) => props.theme.colors.primary};
          display: inline-block;
          width: 1.2em;
          height: 1.2em;
          position: relative;
          margin-right: 8px;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }
      &:checked {
        + .radio-label {
          &:before {
            background-color: ${(props) => props.theme.colors.primary};
            box-shadow: inset 0 0 0 4px #f4f4f4;
          }
        }
      }
      &:focus {
        + .radio-label {
          &:before {
            outline: none;
            border-color: ${(props) => props.theme.colors.primary};
          }
        }
      }
      &:disabled {
        + .radio-label {
          &:before {
            box-shadow: inset 0 0 0 4px #f4f4f4;
            border-color: ${darken(0.25, "#f4f4f4")};
            background: ${darken(0.25, "#f4f4f4")};
          }
        }
      }
      + .radio-label {
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }
  }
`;
