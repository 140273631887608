import { darken } from "polished";
import styled from "styled-components";
import { device } from "../../styles/device";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
`;

export const FormGroup = styled.div`
  flex-direction: column;
  display: flex;
  gap: 8px;
  
  #registerNumber::placeholder{
    font-size: 14px;
  }
`;

export const FieldErrorMessage = styled.div`
  color: ${(props) => props.theme.colors.warning};
  font-size: 14px;
  font-family: 'Red Hat Display', sans-serif;;
`;

export const Label = styled.label`
  font-size: 14px;
  font-size: 14px;
    color: ${props => (props.invalid ? props.theme.colors.warning : props.theme.colors.neutral900)};
`;

export const InputField = styled.input`
  width: 100%;
  height: 56px;  
  background: #ffffff;
  border: 1px solid
    ${props => (props.invalid ? props.theme.colors.warning : "#ECECEC")};
  box-sizing: border-box;
  padding-left: 10px;
  font-size: 16px;
  outline: none;
  transition: all 0.3s ease;
  -webkit-appearance: none;
  border-radius: 10px;

  :focus {
    border: 1px solid
      ${props =>
    props.invalid
      ? props.theme.colors.warning
      : props.theme.colors.primary};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  :focus + label {
    color: ${props =>
    props.invalid ? props.theme.colors.warning : props.theme.colors.primary};
  }

  &:disabled {
    background-color: ${darken(.06, "#ffffff")};
    opacity: 0.8;
    cursor: not-allowed;
  }

  ::placeholder {
    color: ${props => props.theme.colors.bodyLight};
  }
`;

export const Line = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media ${device.tablet}{
    flex-direction: row;
    justify-content: space-between;
  }
`;