import { darken, lighten } from "polished";
import styled from "styled-components";
import ButtonPrimary from "../../components/ButtonPrimary";
import GalleryImages from "../../components/GalleryImages";
import { device } from "../../styles/device";
import ButtonSecondary from "../../components/ButtonSecondary";

export const Container = styled.div`
  width: 100%;
  background: #fff;
  padding: 0 16px;

  span.new-product {
    background: #00bfdf;
    min-width: 80px;
    height: 30px;
    width: 20%;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 1.4rem;
    color: ${props => props.theme.colors.bodyDark};
    border-radius: 2px;
    margin-left: 10px;
    padding: 0 5px;
  }
`;

export const ContainerProduct = styled.div`

  .route-detail{
    max-width: 1440px;
    margin: 0 auto;
    margin-bottom: 40px;

    p{
      color: ${props => props.theme.colors.black};
    }
  }
  .detail-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

export const ContainerSpecialPricesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const BasketHeader = styled.div`
  display: flex;
  width: 100%;
  height: 75px;
  align-items: center;
  background: #f5f5f5;
  border-bottom: 1px solid #ecf0f1;
  margin-top: 86px;
  justify-content: center;

  @media (max-width: 900px) {
    margin-top: 130px;
  }

  @media (max-width: 420px) {
    height: 80px;
  }

  > div {
    max-width: 1440px;
    width: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;

    button {
      font-size: 1.4rem;
    }

    @media (max-width: 420px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;

      button {
        font-size: 1.4rem;
        padding: 10px;
        svg {
          font-size: 1.8rem;
        }
      }
    }

    > span {
      font-weight: 500;
      font-size: 2.2rem;
      color: ${props => props.theme.colors.primary};
      text-transform: uppercase;

      @media (max-width: 420px) {
        flex-direction: column;
      }
    }
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  min-height: 100vh;
  margin-top: 86px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ProductHeader = styled.div`

`;

export const Warning = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: 1px solid ${props => props.theme.colors.warning};
  border-radius: 2px;
  margin-bottom: 10px;
  align-self: flex-start;
  text-align: center;
  margin-top: 16px;

  span {
    font-weight: 500;
    color: ${props => props.theme.colors.warning};
    font-size: 1.4rem;
    line-height: 2rem;
  }
`;

export const ImageContainer = styled.div`
  align-self: center;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 202px;
  width: 328px;

  @media ${device.laptop} {
    height: 497px;
    width: 690px;
    align-self: flex-start;
    display: none;
  }

  border: 1px solid ${props => props.theme.colors.grayLight};
  img {
     object-fit: contain;
     max-width: 328px;
     max-height: 202px;

     @media ${device.laptop} {
        max-height: 497px;
        max-width: 690px;
    }
  }

`;

export const ProductShop = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 486px;

  @media ${device.tablet} {
    align-self: flex-start;
    margin-left: 24px;
  }

  .price{
    display: flex;
    flex-direction: column;
  }
`;

export const ProductDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  div.product-attributes {
    margin: 10px 0;
    ul {
      list-style: none;
      li {
        text-transform: uppercase;
        font-size: 1.4rem;
        color: ${props => lighten(0.05, props.theme.colors.secondary)};
        font-weight: 500;
      }
    }
  }
`;

export const GalleryImagesStyled = styled(GalleryImages)`
  margin-top: 16px;
  display: initial;
  @media ${device.laptop} {
    display: none;
  }
`;

export const ContainerPromo = styled.div`
    background-color: ${props => props.theme.colors.promotion};
    border-radius: 0px 8px 0px 8px;
    padding: 8px 16px;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    span{
        font-size: 14px;
        font-weight: 700;
        color: ${props => props.theme.colors.white};
    }
`;

export const ContainerGalleryImages = styled.div`
  max-width: 690px;
  display: none;
  @media ${device.laptop} {
    display: initial;
  }
`;

export const ContainerName = styled.div`
  max-width: 600px;

  h1 {
    font-size: 25px;
    font-weight: 700;
    color: ${props => props.theme.colors.black};
  }
`;

export const ContainerInteract = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  width: 100%;
`;

export const ContainerSummary = styled.div`
  margin-top: 20px;
`;

export const ContainerInteractFirstColumn = styled.div`
   display: flex;
   flex-direction: column;
`;

export const ContainerManufacturer = styled.div`
  align-self: flex-start;
  background-color: ${props => props.theme.colors.primary50};
  border-radius: 8px;
  padding: 4px 8px;
  display: flex;
  justify-content: center;

  span {
    color: ${props => props.theme.colors.primaryDark};
    font-size: 14px;
    font-weight: 700;
  }
`;

export const ContainerInteractSecondColumn = styled.div`
  display: inline-flex;
  gap: 16px;
`;

export const ProductPriceContainer = styled.div`
  display: inline-flex;
  justify-content: space-between;
  margin-top: 24px;
  width: 100%;

  @media ${device.laptop} {
    width: auto;
  }
`;

export const ProductPriceFirstColumn = styled.div`
  display: flex;
  flex-direction: column;

  span{
    font-size: 12px;
    font-weight: 700;
  }
`;

export const ContainerAdvantageCard = styled.div`
  margin-left: 28px;

  img{
    height: 35px;
    width: 52px;
  }
`;

export const ContainerInfoAdvantageCard = styled.div`
  display: inline-flex;
  background-color: ${props => props.theme.colors.primary30};
  border-radius: 8px;
  margin-top: 33px;
  justify-content: flex-start;
  margin-bottom: 32px;
`;

export const ContainerInfoAdvangateCardFirstColumn = styled.div`
  display: inline-flex;
  padding: 16px 12px 12px 14px;

  img{
    align-self: center;
    width: 91px;
    height: 59px;
    margin-right: 9px;
  }
`;

export const ContainerInfoEconomyWithAdvantageCard = styled.div`
border-right: 1px solid ${props => props.theme.colors.gray};
padding-right: 16px;
  span{
    font-size: 13px;
  }
`;

export const TextEconomyWithAdvantageCard = styled.span`
  font-size: 25px !important;
  color: ${props => props.theme.colors.error};
`;

export const ContainerInfoAdvantageCardSecondColumn = styled.div`
  display: inline;
  margin: auto 0px;

  span {
    font-size: 14px;
  }
`

export const TextOldValue = styled.span`
  text-decoration: line-through;
`;

export const ContainerNewValue = styled.div`
  display: inline-flex;
`;

export const TextNewValue = styled.span`
  font-size: 32px !important;
  color: ${props => props.theme.colors.primary};
`;

export const ContainerRealPrice = styled.div`
`;

export const ContainerDiscountPrice = styled.div`
`;

export const ProductPriceSecondColumn = styled.div`
  display: inline-flex;
  margin-left: 42px;
`

export const ContainerWithoutStock = styled.div`
    margin-top: 24px;
    display: flex;
    align-items: center;

    span{
        color: ${props => props.theme.colors.primary};
        font-size: 20px;
        font-weight: 700;
        
    }
`;


export const ProductOffer = styled.div`
  display: flex;
  margin: 0 0 10px 0;

  span.offer-discount {
    background: ${props => props.theme.colors.discountFlag};
    width: 100%;
    max-width: 120px;
    height: 30px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 1.4rem;
    color: ${props => props.theme.colors.bodyDark};
    border-radius: 2px;
  }

  span.offer-economy {
    background: ${props => props.theme.colors.success};
    min-width: 148px;
    height: 30px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 1.4rem;
    color: ${props => props.theme.colors.bodyDark};
    border-radius: 2px;
    margin-left: 10px;
    padding: 0 5px;
  }
`;

export const Counter = styled.div`
  margin: 10px 0;
  height: 50px;
  display: flex;
  width: 100%;
  max-width: 325px;
  justify-content: space-between;
  border-top: 1px solid #dde1e7;
  border-bottom: 1px solid #dde1e7;
  box-shadow: 0px 2px 0 rgba(0, 0, 0, 0.15);

  @media (max-width: 600px) {
    max-width: 100%;
  }

  .counter-content {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background: #fff;
    align-items: center;

    span.label {
      font-weight: 500;
      color: ${props => props.theme.colors.bodyLight};
      display: flex;
      align-self: center;
      display: flex;
      justify-content: flex-start;
      width: 90px;
      padding: 10px;
      font-size: 1.4rem;
      position: relative;

      span {
        top: 2px;
        right: 23px;
        height: 20px;
        display: flex;
        padding: 0 4px;
        z-index: 1;
        color: #ffffff;
        background: ${props => props.theme.colors.secondary};
        position: absolute;
        flex-wrap: wrap;
        font-size: 0.75rem;
        min-width: 20px;
        align-items: center;
        font-weight: 500;
        align-content: center;
        border-radius: 10px;
        flex-direction: row;
        justify-content: center;
        font-size: 1.2rem;
      }
    }

    .counter-content__input {
      border: none;
      height: 100%;
      background: #fff;
      font-size: 2.6rem;
      width: 40%;
      font-weight: 600;
      text-align: center;
      color: ${props => props.theme.colors.headingLight};
    }
  }

  .counter-button--increment {
    width: 35%;
    border: none;
    font-weight: 300;
    color: #909090;
    font-size: 3.4rem;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #dde1e7;
    border-right: 1px solid #dde1e7;

    :active {
      background: ${darken(0.05, "#fff")};
    }
  }

  .counter-button--decrement {
    width: 35%;
    border: none;
    border-left: 1px solid #dde1e7;
    border-right: 1px solid #dde1e7;
    font-weight: 300;
    color: #909090;
    font-size: 3.4rem;
    background: #fff;
    align-items: center;
    justify-content: center;

    :active {
      background: ${darken(0.05, "#fff")};
    }
  }
`;

export const ProductShopAction = styled.div`
  margin: 10px 0;
  button {
    width: 100%;
    max-width: 325px;
    background: ${props => props.theme.colors.primary};
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-size: 1.4rem;
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 4px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);

    :active {
      background: ${props => darken(0.03, props.theme.colors.primary)};
    }

    @media (max-width: 600px) {
      max-width: 100%;
    }
  }
`;

export const ProductAlerts = styled.div`
  display: inline-flex;
`;

export const ProductAlertsDescription = styled.div`

`

export const ProductInfo = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 10px;
  justify-content: center;
  display: flex;
`;

export const ProductContent = styled.div`
  width: 100%;
  max-width: 900px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    align-self: flex-start;
    font-weight: 500;
    font-size: 2.5rem;
    color: ${props => props.theme.colors.primary};
    padding: 20px 0;
    width: 100%;
    padding: 15px;
  }

  p {
    font-size: 1.6rem;
    color: ${props => props.theme.colors.bodyLight};
    line-height: 25px;
    width: 100%;
    padding: 5px 15px;
  }

  @media (max-width: 450px) {
    h1 {
      font-size: 2.2rem;
    }
  }
`;

export const ContainerProductIntroductionDesktop = styled.div`
    display: initial;
  @media ${device.laptop} {
    display: none;
  }
`;

export const ContainerProductIntroduction = styled.div`
  width: 100%;
  margin: 50px auto 120px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;

  .text-center {
    align-self: center;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  h2 {
      color: ${props => props.theme.colors.primary};
      font-size: 25px;
      font-weight: 700;
  }

  p {
    color: ${props => props.theme.colors.bodyLight};
    font-size: 16px;
  }
`;

export const Subtitle = styled.h2`
  font-size: 20px;
    font-weight: 700;
    color: ${props => props.theme.colors.primary};
    margin-bottom: 12px;
`;

export const ContainerProductCharacteristics = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;

  table {
    border-spacing: 0 4px;
    tr {
      background-color: #f0f4fc;
    }
    tr > td:first-child{
      font-weight: 700;
      color: ${props => props.theme.colors.black};
    }
    
    td {
      padding: 10px;
    }
    
  }
`;

export const ContainerCharacteristic = styled.div`
  margin-bottom: 30px;
  p{
    font-size: 16px;
    color: ${props => props.theme.colors.bodyLight};
  }
`;

export const InfoHeaderTitle = styled.div`
  width: 100%;
  height: 80px;
  background: #f5f5f5;
  margin-top: 50px;
  display: flex;
  align-items: center;

  h1 {
    padding-left: 15px;
    max-width: 1440px;
    margin: 0 auto;
    font-weight: 500;
    width: 100%;
    font-size: 2.5rem;
    color: ${props => props.theme.colors.primary};
    text-transform: uppercase;
  }

  @media (max-width: 600px) {
    margin-top: 15px;
    h1 {
      font-size: 2.2rem;
    }
  }

  @media (max-width: 450px) {
    h1 {
      font-size: 2.2rem;
    }
  }
`;

export const StyledButtonPrimary = styled(ButtonPrimary)`
  margin-top: 27px;
`;

export const StyledButtonSecondary = styled(ButtonSecondary)`
  margin-top: 27px;

  @media ${device.laptop} {
    margin-left: 16px;
  }
`;

export const StyledProductAlert = styled.div`
  margin-top: 49px;
`;

export const ContainerAddCart = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    flex-direction: row;
  }
`;

export const ContainerDatails = styled.div`
    width: 100%;
    padding: 12px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    border-radius: 0px 0px 8px 8px;
    background-color: red;
    display: flex;
    flex-direction: column;
    align-items: center;

    span{
        font-size: 11px;
        color: ${props => props.theme.colors.white};
        @media ${device.laptop} {
            font-size: 16px;
        }
        font-weight: 700;
    }
`;
