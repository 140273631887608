import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
`;

export const SearchOptionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-items: center;
`;

export const TabButton = styled.button`
    flex: 1;
    border: none;
    background: transparent;
    border-bottom: ${props => props.focused ? `3px solid ${props.theme.colors.primary}` : 'none'};
    font-size: 14px;
    font-weight: 700;
    padding: 8px 0;
    box-sizing: border-box;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px 0;
    overflow-y: auto;
`;

export const WithoutStoreContainer = styled.div`
    padding: 12px 18px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #ffc4d4;

    p {
        font-weight: 700;
        font-size: 14px;
        color: ${props => props.theme.colors.neutral900};
    }
`;