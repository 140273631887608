import React, { useEffect, useState } from 'react';
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import imgFechar from "../../assets/images/close-popup.svg";
import { Creators as CartActions } from "../../store/ducks/cart";
import { Creators as OrderActions } from "../../store/ducks/order";
import { Container, Item, ItemsList, Modal, ModalFooter, ModalHeader } from './styles';

function PartlyFulfilledOrderModal(props) {

  const dispatch = useDispatch();
  const { error } = useSelector(state => state.order);
  const { loading } = useSelector(state => state.cart);
  const [acceptedItems, setAcceptedItems] = useState([]);
  const [itemsNotAccepted, setItemsNotAccepted] = useState([]);
  const [enableButton, setEnableButton] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);

  useEffect(() => {
    if (error.data !== null) {
      setModalOpened(true);
      setAcceptedItems(error.data.itensAtendidos);

      error.data.itensNaoAtendidos.forEach((item) => {
        if (item.estoque_loja === 0) {
          item.rejected = true;
        }
      });

      setItemsNotAccepted(error.data.itensNaoAtendidos);
    } else if (modalOpened) {
      setModalOpened(false);
      handleClose();
    }
  }, [error.data]);

  useEffect(() => {
    let hasStock = false;
    let pendingItem = false;

    itemsNotAccepted.forEach((item) => {
      if (item.estoque_loja !== 0) {
        hasStock = true;
      }

      if (!item.rejected) {
        pendingItem = true;
      }
    });

    setEnableButton(itemsNotAccepted.length !== 0 && pendingItem);
  }, [itemsNotAccepted, acceptedItems])

  function transferItemToAccepted(item) {
    const newArrayItemsNotAccepted = itemsNotAccepted.filter(product => product.id !== item.id);
    const newArrayAcceptedItems = [...acceptedItems, { ...item, transfer: true }];

    setItemsNotAccepted(newArrayItemsNotAccepted);
    setAcceptedItems(newArrayAcceptedItems);
  }

  function transferItemToNotAccepted(item, rejected) {
    const newArrayAcceptedItems = acceptedItems.filter(product => product.id !== item.id);
    const newArrayItemsNotAccepted = itemsNotAccepted.filter(product => product.id !== item.id);
    const newNewArrayItemsNotAccepted = [...newArrayItemsNotAccepted, { ...item, transfer: false, rejected: rejected }];

    setItemsNotAccepted(newNewArrayItemsNotAccepted);
    setAcceptedItems(newArrayAcceptedItems);
  }

  function handleClose() {
    if (props != null && props.handleClose) {
      props.handleClose();
    }
  }

  return (
    <>
      {error.type === "ERROR_SELECTED_STORE_STOCK_ITEMS" && error.visible && (
        <Container>
          <Modal>
            <ModalHeader>
              <div>
                <h1>A farmácia não possui todos os produtos em estoque</h1>
                <p>
                  {ReactHtmlParser(error.data.user)}
                </p>
              </div>
              <button onClick={() => dispatch(OrderActions.resetError())}>
                <img src={imgFechar} alt={"Fechar"} />
              </button>
            </ModalHeader>

            <div className="wrap" >
              <ItemsList inStock={true}>
                <h2>Produtos com estoque</h2>
                {acceptedItems.map((item) => (
                  <Item inStock={true} key={item.id}>
                    <div className="content-area">
                      {item.imagens && item.imagens.length > 0 && (
                        <img
                          src={item.imagens[0].caminho_imagem}
                          alt={item.nome}
                        />
                      )}
                      <div className="text-area">
                        <strong>{item.nome}</strong>
                      </div>
                    </div>
                    <div className="btn-area">
                      {item?.transfer && (
                        <button type="button" className="btn-undo" onClick={() => transferItemToNotAccepted(item)}>Desfazer ação</button>
                      )}
                    </div>
                  </Item>
                ))}

                {acceptedItems.length === 0 && (
                  <span className="none-accepted">Nenhum produto atendido</span>
                )}

              </ItemsList>

              <ItemsList inStock={false}>
                <h2>Produtos sem estoque ou estoque parcial </h2>
                {itemsNotAccepted.map((item) => (
                  <Item inStock={false} rejected={item.rejected} key={item.id}>
                    <div className="content-area">
                      {item.imagens && item.imagens.length > 0 && (
                        <img
                          src={item.imagens[0].caminho_imagem}
                          alt={item.nome}
                        />
                      )}
                      <div className="text-area">
                        <strong>{item.nome}</strong>
                        <span>Estoque: {item.estoque_loja}</span>
                      </div>
                    </div>
                    <div className="btn-area">
                      <button className="btn-transfer" type="button" hidden={item.rejected} onClick={() => transferItemToAccepted(item)}>Quero levar {item.estoque_loja}</button>
                      <button className="btn-remove" type="button" hidden={item.rejected} onClick={() => transferItemToNotAccepted(item, true)}>Não quero levar</button>
                      <button className="btn-undo" type="button" hidden={!item.rejected || item.estoque_loja === 0} onClick={() => transferItemToNotAccepted(item, false)}>Desfazer ação</button>
                    </div>
                  </Item>
                ))}

                {itemsNotAccepted.length === 0 && (
                  <span className="none-accepted">Nenhum produto não atendido</span>
                )}
              </ItemsList>
            </div>


            <ModalFooter>
              <button disabled={enableButton} className={acceptedItems.length === 0 && itemsNotAccepted.findIndex(product => !product.rejected) === -1 ? "btn-clear" : "btn-confirm"} onClick={() => dispatch(CartActions.removeProductsCart(acceptedItems, itemsNotAccepted))}>
                {loading ? "Atualizando itens da cesta..." : (acceptedItems.length === 0 && itemsNotAccepted.findIndex(product => !product.rejected) === -1 ? "Limpar a cesta" : "Ir para cesta de compra")}
              </button>
            </ModalFooter>
          </Modal>
        </Container>
      )}
    </>
  );
}

export default PartlyFulfilledOrderModal;
