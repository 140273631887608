import { Formik } from "formik";
import React, { createRef, useEffect, useState } from "react";
import { StoreSelectorByAddressSchema } from "./validation";
import api from "../../services/api";
import { toast } from "react-toastify";
import { ChooseStoreForm } from "./styles";

export default function StoreSelectorByAddress({
  showOverlay,
  onChangeStoreAddress,
}) {
  const [ufOptions, setUfOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [neighborhoodOptions, setNeighborhoodOptions] = useState([]);

  const formRef = createRef();

  useEffect(() => {
    getStates();
  }, []);

  /**
   * Obtem os estados
   */
  async function getStates() {
    try {
      showOverlay(true);
      setCityOptions([]);
      setNeighborhoodOptions([]);

      const { data } = await api.get("/stores/states");

      setUfOptions(data);
    } catch (e) {
      toast.error(e.response?.data?.user || "Erro ao obter estados");
    } finally {
      showOverlay(false);
    }
  }

  /**
   * Obtem as cidades para um uf especificado
   * @param {string} uf UF para obter as cidades
   */
  async function getCitiesByState(uf) {
    try {
      formRef.current.setFieldValue("estado", uf);
      formRef.current.setFieldValue("cidade", "");
      formRef.current.setFieldValue("bairro", "");
      showOverlay(true);
      setNeighborhoodOptions([]);

      const { data } = await api.get(`/stores/cities/${uf}`);

      setCityOptions(data?.cidades || []);

      onChangeStoreAddress(uf);
    } catch (e) {
      toast.error(
        e.response?.data?.user || "Erro ao obter cidades para o UF " + uf
      );
      formRef.current.setFieldValue("estado", "");
    } finally {
      showOverlay(false);
    }
  }

  /**
   * Obtem os bairros para um uf e cidade especificada
   * @param {string} uf UF para obter os bairros
   * @param {string} city Cidade para obter os bairros
   */
  async function getNeighborhoodsByStateAndCity(uf, city) {
    try {
      formRef.current.setFieldValue("cidade", city);
      formRef.current.setFieldValue("bairro", "");
      showOverlay(true);

      const { data } = await api.get(`/stores/districts/${uf}/${city}`);

      setNeighborhoodOptions(data?.bairros || []);

      onChangeStoreAddress(uf, city);
    } catch (e) {
      toast.error(
        e.response?.data?.user || "Erro ao obter bairros para a cidade " + city
      );
      formRef.current.setFieldValue("cidade", "");
    } finally {
      showOverlay(false);
    }
  }

  function onSelectingAddress(state, city, neighborhood) {
    formRef.current.setFieldValue("bairro", neighborhood);
    onChangeStoreAddress(state, city, neighborhood);
  }

  return (
    <ChooseStoreForm>
      <Formik
        ref={formRef}
        validationSchema={StoreSelectorByAddressSchema}
        initialValues={{
          estado: "",
          cidade: "",
          bairro: "",
        }}
        onSubmit={() => {}}
      >
        {({ values, handleBlur, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div id="wrap">
              <div className="wrap-flex-row">
                <div className="form-control uf">
                  <label htmlFor="select-uf">UF</label>
                  <select
                    id="select-uf"
                    className="input-select"
                    name="uf"
                    value={values.estado}
                    onChange={(evt) => getCitiesByState(evt.target.value)}
                    onBlur={handleBlur}
                    disabled={!ufOptions || ufOptions.length === 0}
                  >
                    <option hidden>UF</option>
                    {ufOptions?.map((uf, index) => (
                      <option key={index} value={uf}>
                        {uf}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-control city">
                  <label htmlFor="select-city">Cidade</label>
                  <select
                    id="select-city"
                    className="input-select"
                    onChange={(evt) =>
                      getNeighborhoodsByStateAndCity(
                        values.estado,
                        evt.target.value
                      )
                    }
                    onBlur={handleBlur}
                    name="cidade"
                    value={values.cidade}
                    disabled={!cityOptions || cityOptions.length === 0}
                  >
                    <option hidden>Cidades</option>
                    {cityOptions?.map((city, index) => (
                      <option
                        key={`${values.estado}-${city.cidade}`}
                        value={city.cidade}
                      >
                        {city.cidade + " " + city.quantidade_farmacias}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-control">
                <label htmlFor="select-uf">Bairro</label>
                <select
                  className="input-select"
                  id="select-neighborhood"
                  onChange={(evt) =>
                    onSelectingAddress(
                      values.estado,
                      values.cidade,
                      evt.target.value
                    )
                  }
                  onBlur={handleBlur}
                  name="bairro"
                  value={values.bairro}
                  disabled={
                    !neighborhoodOptions || neighborhoodOptions.length === 0
                  }
                >
                  <option hidden>Bairros</option>
                  {neighborhoodOptions?.map((neighborhood, index) => (
                    <option
                      key={`${values.estado}-${values.cidade}-${neighborhood.bairro}`}
                      value={neighborhood.bairro.toUpperCase()}
                    >
                      {neighborhood.bairro +
                        " " +
                        neighborhood.quantidade_farmacias}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </ChooseStoreForm>
  );
}
