import { darken } from "polished";
import styled from "styled-components";

export const Container = styled.div`
    &.radio {
      margin-top: 3px;
      margin-right: 10px;
      label{
        font-size: 14px;
      }
      input[type="radio"] {
        position: absolute;
        display: none;
        + .radio-label {
          &:before {
            content: "";
            background: #f4f4f4;
            border-radius: 100%;
            border: 1px solid ${props => props.theme.colors.primary};
            display: inline-block;
            width: 1.2em;
            height: 1.2em;
            position: relative;
            margin-right: 8px;
            vertical-align: top;
            cursor: pointer;
            text-align: center;
            transition: all 250ms ease;
          }
        }
        &:checked {
          + .radio-label {
            &:before {
              background-color: ${props => props.theme.colors.primary};
              box-shadow: inset 0 0 0 4px #f4f4f4;
            }
          }
        }
        &:focus {
          + .radio-label {
            &:before {
              outline: none;
              border-color: ${props => props.theme.colors.primary};
            }
          }
        }
        &:disabled {
          + .radio-label {
            &:before {
              box-shadow: inset 0 0 0 4px #f4f4f4;
              border-color: ${darken(0.25, "#f4f4f4")};
              background: ${darken(0.25, "#f4f4f4")};
            }
          }
        }
        + .radio-label {
          &:empty {
            &:before {
              margin-right: 0;
            }
          }
        }
      }
    }
`;