import styled from "styled-components";

export const Container = styled.div`
  -webkit-touch-callout: none; /* iPhone OS, Safari */
  -webkit-user-select: none; /* Chrome, Safari 3 */
  -khtml-user-select: none; /* Safari 2 */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  user-select: none;
  width: 220px;
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 4px;

  h1 {
    margin-bottom: 12px;
  }
`;

export const Title = styled.h2`
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 16px;
  color: ${(props) => props.theme.colors.black};
`;

export const Header = styled.h3`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 24px;
  color: ${(props) => props.theme.colors.black};
`;

export const ContainerCheckFilter = styled.div`
  margin-bottom: 16px;
  label {
    margin-left: 8px;
    font-size: 13px;
  }

  input {
    cursor: pointer;
  }
`;

export const ContentFilter = styled.div`
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
  box-shadow: rgba(0, 0, 0, 0.025) 0px 0px 3px 3px;
  border: 1px solid #e8e8e9;

  hr {
    border: 0.5px solid #e8e8e9;
  }
  .items {
    max-height: 200px;
    overflow-y: scroll;
  }

  .div-custom-scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .div-custom-scroll::-webkit-scrollbar-track {
    border-radius: 4px;
  }

  .div-custom-scroll::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.primary};
    border-radius: 4px;
  }
`;

export const ContainerButton = styled.div`
  border-radius: 16px 16px 0px 0px;
  border-top: 1px solid ${(props) => props.theme.colors.gray};
  padding-bottom: 24px;

  .buttonFilter {
    margin-top: 26px;
    margin-bottom: 24px;
  }
`;

export const ContainerInfoFilter = styled.div`
  padding: 0px ${(props) => (props.hasPadding ? 24 : 0)}px;
`;

export const ContainerFilters = styled.div``;

export const ContainerFiltersSelected = styled.div`
  margin-bottom: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;

  ul {
    margin-top: 15px;
  }

  li {
    width: 100%;
    display: block;
    margin-bottom: 8px;

    span {
      padding: 10px;
      font-size: 14px;
    }

    a {
      float: right;
      margin-right: 10px;
      cursor: pointer;
    }
  }

  .cleanAll {
    cursor: pointer;
    padding: 10px;
    text-decoration: underline;
  }
`;

export const ContainerCloseRound = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .closeRound {
    margin-top: 40px;
    margin-right: 16px;
  }
`;

export const InputField = styled.input`
  height: 34px;
  border: 1px solid
    ${(props) => (props.invalid ? props.theme.colors.warning : "#ECECEC")};
  font-size: 16px;
  border-radius: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;

  input {
    width: 300px;
  }

  + label {
    font-size: 14px;
    color: ${(props) =>
      props.invalid
        ? props.theme.colors.warning
        : props.theme.colors.neutral900};
  }

  :focus {
    border: 1px solid
      ${(props) =>
        props.invalid
          ? props.theme.colors.warning
          : props.theme.colors.primary};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  :focus + label {
    color: ${(props) =>
      props.invalid ? props.theme.colors.warning : props.theme.colors.primary};
  }
`;
