import React from "react";
import { Formik } from "formik";
import { ThirdPartyWithdrawalSchema } from "./validation";
import { Container, FieldErrorMessage, FormGroup, InputField, Label, RadioGroup, WithdrawalByThirdForm } from "./styles";
import MaskedInput from "react-text-mask";
import { cpfMask, phoneMask } from "../../util/mask";

export default function ResponsibleWithdrawalForm({ formRef, responsibleWithdrawal: { withdrawalByThird, fullName, cpf, phone }, onSubmitResponsibleWithdrawalForm }) {
    return (
        <Formik
            ref={formRef}
            initialValues={{
                retirada_terceiro: withdrawalByThird,
                nome_completo: fullName,
                cpf: cpf,
                telefone_contato: phone
            }}
            validationSchema={ThirdPartyWithdrawalSchema}
            onSubmit={onSubmitResponsibleWithdrawalForm}
            isInitialValid
        >
            {({
                values,
                handleChange,
                handleBlur,
                errors,
                touched,
                handleReset,
                isSubmitting,
                isValid,
            }) => (
                <Container>
                    <FormGroup>
                        <RadioGroup>
                            <div className="radio" id="retirada-terceiro-nao">
                                <input
                                    id="radioRet-2"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onClick={handleReset}
                                    value={'false'}
                                    name="retirada_terceiro"
                                    type="radio"
                                    checked={values.retirada_terceiro === 'false' ? true : false}
                                />
                                <label htmlFor="radioRet-2" className="radio-label">
                                    Eu mesmo
                                </label>
                            </div>

                            <div className="radio" id="retirada-terceiro-sim">
                                <input
                                    id="radioRet-1"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={'true'}
                                    name="retirada_terceiro"
                                    type="radio"
                                    checked={values.retirada_terceiro === 'true' ? true : false}
                                />
                                <label htmlFor="radioRet-1" className="radio-label">
                                    Outra pessoa
                                </label>
                            </div>
                        </RadioGroup>
                        {errors.retirada_terceiro && touched.retirada_terceiro && (
                            <FieldErrorMessage>{errors.retirada_terceiro}</FieldErrorMessage>
                        )}
                    </FormGroup>

                    {values.retirada_terceiro === "true" && (
                        <>
                            <FormGroup>
                                <Label>Nome Completo*</Label>

                                <InputField
                                    invalid={errors.nome_completo && touched.nome_completo}
                                    id="nome_completo"
                                    type="text"
                                    name="nome_completo"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.nome_completo}
                                    placeholder="Ex: Fulano da Silva"
                                    disabled={values.retirada_terceiro === "false"}
                                />

                                {errors.nome_completo && touched.nome_completo && (
                                    <FieldErrorMessage>{errors.nome_completo}</FieldErrorMessage>
                                )}
                            </FormGroup>
                            <FormGroup className={"campoEsquerdo"}>
                                <Label>CPF*</Label>
                                <MaskedInput
                                    mask={cpfMask}
                                    invalid={errors.cpf && touched.cpf}
                                    id="cpf"
                                    placeholder="Ex: 000.000.000-00"
                                    name="cpf"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.cpf}
                                    disabled={values.retirada_terceiro === "false"}
                                    render={(ref, props) => (
                                        <InputField ref={input => ref(input)} {...props} />
                                    )}
                                />
                                {errors.cpf && touched.cpf && (
                                    <FieldErrorMessage>{errors.cpf}</FieldErrorMessage>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label>Seu Telefone*</Label>
                                <MaskedInput
                                    name="telefone_contato"
                                    type="text"
                                    placeholder="Ex: (__) ____-____"
                                    mask={phoneMask}
                                    value={values.telefone_contato}
                                    invalid={
                                        errors.telefone_contato && touched.telefone_contato
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={values.retirada_terceiro === "false"}
                                    render={(ref, props) => (
                                        <InputField ref={(input) => ref(input)} {...props} />
                                    )}
                                />
                                {errors.telefone_contato && touched.telefone_contato && (
                                    <FieldErrorMessage>{errors.telefone_contato}</FieldErrorMessage>
                                )}
                            </FormGroup>
                        </>
                    )}
                </Container>
            )}
        </Formik>
    );
}