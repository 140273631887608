import styled from "styled-components";

export const ButtonPrimary = styled.button`
    background-color: ${props => props.theme.colors.primary};
    padding: 12px 32px;
    border-radius: 40px;
    color: ${props => props.theme.colors.white};
    font-size: 16px;
    font-weight: 700;
    border-color: transparent;
    cursor: pointer;
    transition:all 200ms ease-in-out;

    
    &:hover{
        background-color: ${props => props.theme.colors.primaryDark};
    }

    &:disabled{
        background-color: ${props => props.theme.colors.gray};
        color: ${props => props.theme.colors.grayDark};
        cursor: not-allowed;
    }
`;