import styled from "styled-components";

export const ChooseStoreForm = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 20px;

  form {
    width: 100%;

    select.input-select {
      width: 100%;
      height: 53px;
      border: 1px solid var(--neutral-200, #d0d1d3);
      background: #fff;
      color: var(--neutral-900, #151922);
      font-family: Red Hat Display;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 26.5px; /* 165.625% */

      padding-left: 16px;
      -webkit-appearance: none;
      -moz-appearance: none;
      background: transparent;
      background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 97%;
      background-position-y: 13px;
      border-radius: 8px;
      &:disabled {
        color: var(--neutral-900, #151922);
      }
    }

    div.form-control {
      flex: 1;
      display: flex;
      flex-direction: column;

      label {
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: var(--neutral-900, #151922);
      }
    }

    #wrap {
      display: flex;
      flex-direction: column;
      gap: 14px;

      .wrap-flex-row {
        display: flex;
      }

      .uf {
        padding-right: 10px;
      }

      .city {
        flex: 4;
      }
    }
  }
`;
