export function formatCEP(cep) {
    if (!cep) return '';

    const formattedCEP = cep.replace(/[^0-9]/g, '');

    if (formattedCEP.length !== 8) return '';

    const slices = [
        formattedCEP.slice(0, 5),
        formattedCEP.slice(5, 8),
    ];

    return `${slices[0]}-${slices[1]}`;
}