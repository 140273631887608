import React, { useContext } from "react";
import { Container } from "./styles";
import { ThemeContext } from "styled-components";
import Loader from "react-loader-spinner";

export default function LoaderAsync({ message }) {
    const theme = useContext(ThemeContext);

    return (
        <Container>
            <span>{message}</span>
            <Loader
                type="ThreeDots"
                color={theme.colors.primary}
                height={40}
                width={40}
            />
        </Container>
    )
}