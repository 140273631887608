import { combineReducers } from "redux";
import banners from "./banners";
import products from "./products";
import cart from "./cart";
import error from "./error";
import search from "./search";
import auth from "./auth";
import signup from "./signup";
import user from "./user";
import suggest from "./suggest";
import store from "./store";
import address from "./address";
import password from "./password";
import order from "./order";
import myOrders from "./myOrders";
import sideMenu from "./sideMenu";
import storeByUser from "./storeByUser";
import domain from "./domain";
import coupon from "./coupon";
import menu from "./menu";
import category from "./category";

export default combineReducers({
  banners,
  products,
  cart,
  error,
  address,
  search,
  auth,
  signup,
  user,
  suggest,
  password,
  store,
  order,
  myOrders,
  sideMenu,
  storeByUser,
  domain,
  coupon,
  menu,
  category
});
