import React from "react";
import { FiMapPin } from "react-icons/fi";
import { Container, InfoContainer } from "./styles";
import Radio from "../Radio";
import { getFriendlyDateForDeliveryTypeString } from "../../util/friendlyDate";
import { DeliveryType } from "../../util/deliveryType";
import { useEffect } from "react";
import { useState } from "react";

export default function StoreCard({ name, store: { storeId, storeName, address: { street, number, neighborhood }, distance, expectedDate: { startTime, endTime, availabilityDate, timeToReceive } }, selectedStore, onSelectStore, responsibleWithdrawal }) {

    const [expectedDate, setExpectedDate] = useState(undefined);

    useEffect(() => {
        if (startTime, endTime, availabilityDate, timeToReceive)
            setExpectedDate(getFriendlyDateToPickup(startTime, endTime, availabilityDate, timeToReceive));
    }, [startTime, endTime, availabilityDate, timeToReceive])

    function getNextTimeStoreOpened(startTime, endTime, availabilityDate) {
        return `${availabilityDate} ${startTime.split(':').slice(0, 2).join(':')}-${endTime.split(':').slice(0, 2).join(':')}`;
    }

    function getFriendlyDateToPickup(startTime, endTime, availabilityDate, timeToReceive) {
        const nextTimeStoreOpened = getNextTimeStoreOpened(startTime, endTime, availabilityDate);
        return getFriendlyDateForDeliveryTypeString(nextTimeStoreOpened, DeliveryType.RETIRAR_NA_LOJA, timeToReceive);
    }

    function formatMetersToKilometers(meters) {
        const kilometers = meters / 1000;
        return `${kilometers.toFixed(2)} km`;
    }

    return (
        <Container>
            <Radio
                id={`radio-${name}` + storeId}
                name={`store-${name}`}
                value={storeId}
                checked={selectedStore}
                onChange={(evt) => {
                    onSelectStore(parseInt(evt.target.value));
                }} onBlur={(evt) => {
                    onSelectStore(parseInt(evt.target.value));
                }}
            />
            <InfoContainer>
                <h2>{storeName}</h2>
                {expectedDate && (
                    <span>{expectedDate}</span>
                )}
                <span>{street}, {number}, {neighborhood}</span>
                {distance && (
                    <div className="distance">
                        <FiMapPin size={14} />
                        <span>{formatMetersToKilometers(distance)}</span>
                    </div>
                )}
                {responsibleWithdrawal?.withdrawalByThird === 'true' && (
                    <div className="line">
                        <h3>Retirado por</h3>
                        <span>{responsibleWithdrawal?.fullName}</span>
                    </div>
                )}
            </InfoContainer>
        </Container>
    )
}