import styled from "styled-components";

export const Container = styled.div`
    flex: 1;
    padding: 16px;
    background: #FFFFFF;
    border: 1px solid ${props => props.selected ? props.theme.colors.primary : '#ECECEC'};
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.04);
    display: flex;
    gap: 6px;
    transition: 100ms ease-in-out;
    border-radius: 8px;
    align-items: center;


    :hover {
        border: 1px solid ${props => props.selected ? props.theme.colors.neutral900 : props.theme.colors.primary};
    }
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    h2 {
        font-size: 16px;
        font-weight: 700;
        color: ${props => props.theme.colors.neutral900};
    }

    h3 {
        font-size: 15px;
        font-weight: 700;
        color: ${props => props.theme.colors.neutral900};
    }

    span {
        font-size: 14px;
        font-weight: 400;
        color: ${props => props.theme.colors.neutral900};
    }

    .distance{
            display: flex;
            gap: 2px;
            align-items: center;
    }

    .line{
        display: flex;
        gap: 2px;

        span{
            align-self: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            
        }
    }
`;