import React from "react";
import { Paragraph } from "./styles";
import ModalLayout from '../ModalLayout';
import { DeliveryType } from "../../util/deliveryType";

const ShowAcceptGetItOnDifferentDays = ({ showModal, handleCloseModal, handleContinue, deliveryType }) => {
    
    const deliveryTerm = deliveryType === DeliveryType.RECEBER_EM_CASA ? "entregues" : "retirados";

    return (
        <ModalLayout title={"Fique atento!"} showModal={showModal}
            buttonLeft={{
                text: "Sim, continuar", action: () => {
                    handleContinue();
                }
            }}
            buttonRight={{
                text: "Não", action: () => {
                    handleCloseModal();
                }
            }}
        >
            <Paragraph>Alguns produtos da sua cesta, serão {deliveryTerm} em dias diferentes.</Paragraph>
            <Paragraph>Você deseja continuar com a compra?</Paragraph>
        </ModalLayout>
    );
};

export default ShowAcceptGetItOnDifferentDays;