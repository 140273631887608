import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FooterCompact from "./FooterCompact";
import { Container } from "./styles";
import { Link } from "react-router-dom";
import TermsOfUseModal from "../TermsOfUseModal";
import PrivacyPolicyModal from "../PrivacyPolicyModal";
import ExchangeReturnPolicy from "../ExchangeReturnPolicy";
import whatsapp from "../../assets/images/whatsapp.svg";
import { Creators as CategoryActions } from "../../store/ducks/category";

export default function Footer() {
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.store);
  const [showTermsOfUse, setShowTermsOfUse] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showExchangeReturnPolicy, setShowExchangeReturnPolicy] = useState(false);
  const { categories } = useSelector((state) => state.category);

  const showNossasMarcas = false;

  const { politica_troca_devolucao, politica_privacidade, politica_privacidade_html, termos_uso } = useSelector((state) =>
    state.store.data == null ? "" : state.store.data.empresa
  );

  useEffect(() => {
    dispatch(CategoryActions.getCategoriesRequest());
  }, [dispatch]);

  return (
    <Container>
      <TermsOfUseModal visible={showTermsOfUse} closeModal={() => setShowTermsOfUse(false)} />
      <PrivacyPolicyModal visible={showPrivacyPolicy} closeModal={() => setShowPrivacyPolicy(false)} />
      <ExchangeReturnPolicy visible={showExchangeReturnPolicy} closeModal={() => setShowExchangeReturnPolicy(false)} />
      <div className="footer-info">
        <div className="footer-contact">
          <div className="divEq">
            <div className="menus">
              <div className="categorias">
                <strong>Categorias</strong>
                <ul>
                  {
                    categories.map((category) => (
                      <li key={category.id}>
                        <Link to={`/produtos/categoria/${category.descricao}/${category.id}`}>{category.descricao}</Link>
                      </li>
                    ))
                  }
                </ul>
              </div>
              {showNossasMarcas && (
                <div className="nossas-marcas">
                  <strong>Nossas marcas</strong>
                  <ul>
                    <li><a>Natura</a></li>
                    <li><a>O Boticário</a></li>
                    <li><a>Neutrogena</a></li>
                    <li><a>L’Oréal Paris</a></li>
                    <li><a>Carolina Herrera</a></li>
                  </ul>
                </div>
              )}
              {((politica_privacidade || politica_privacidade_html) || termos_uso || politica_troca_devolucao) && (
                <div className="nossos-termos">
                <strong>Nossos termos</strong>
                <ul>
                  {(politica_privacidade || politica_privacidade_html) && (
                    <li>
                      <Link onClick={(event) => {
                        event.preventDefault()
                        setShowPrivacyPolicy(true)
                      }}>Política de privacidade</Link>
                    </li>
                  )}
                  {termos_uso && (
                    <li>
                      <Link onClick={(event) => {
                        event.preventDefault()
                        setShowTermsOfUse(true)
                      }}>Termos de uso</Link>
                    </li>
                  )}
                  {politica_troca_devolucao && (
                    <li>
                      <Link onClick={(event) => {
                        event.preventDefault()
                        setShowExchangeReturnPolicy(true)
                      }}>Política de troca e devolução</Link>
                    </li>
                  )}
                </ul>
              </div>
              )}
            </div>
          </div>
          <div className="divDr">
            <div className="contact">
              <strong>Entre em contato conosco</strong>
              <div className="contact-data">
                <a href={`https://api.whatsapp.com/send?phone=55${data?.empresa?.sac_telefone.replace(/\D/g, '')}`} target="_blank" rel="noopener noreferrer">
                  <div className="callcenter-contact">
                    <img src={whatsapp} alt="whatsapp" height={20} width={25} />
                    <div>
                      <p>Central de atendimento</p>
                      <b>{data?.empresa?.sac_telefone}</b>
                    </div>
                  </div>
                </a>
                <div>
                  <a className="link" href={`mailto:${data?.empresa?.sac_email}`}>{data?.empresa?.sac_email}</a>
                </div>
                {data?.empresa.site_institucional && (
                  <div>
                    <a className="link" target="_blank" href={data?.empresa.site_institucional}>Institucional</a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <FooterCompact />
      </div>
    </Container>
  );
}
