import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 24px;
    margin-bottom: 23px;
    border-bottom: 1px solid ${props => props.theme.colors.neutral100};

    h4 {
        font-size: 16px;
        color: ${props => props.theme.colors.neutral900};
        font-weight: 700;
    }

    

    .address-management{
        display: flex;
        flex-direction: column;
        gap: 12px;

        .info-customer{
            .customer-name {
                font-size: 14px;
                color: ${props => props.theme.colors.primary};
                font-weight: 700;
            }

            .customer-address{
                font-size: 14px;
                color: ${props => props.theme.colors.neutral900};
            }
        }

        .choose-address-btn{
            display: flex;
            gap: 6px;
            background: transparent;
            align-items: center;
            border: none;
            color: ${props => props.theme.colors.primary};
            font-size: 14px;
            font-weight: 700;
        }
    }
`;