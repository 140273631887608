import { darken } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
`;

export const FormGroup = styled.div`
  flex-direction: column;
  display: flex;
  gap: 8px;
`;

export const RadioGroup = styled.div`
  display: flex;
  font-size: 14px;
  color: ${props => props.theme.colors.neutral900};

  .radio {
    margin-top: 8px;
    margin-right: 24px;
    input[type="radio"] {
      position: absolute;
      opacity: 0;
      + .radio-label {
        &:before {
          content: "";
          background: #f4f4f4;
          border-radius: 100%;
          border: 1px solid ${props => props.theme.colors.primary};
          display: inline-block;
          width: 1.2em;
          height: 1.2em;
          position: relative;
          top: -0.2em;
          margin-right: 8px;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }
      &:checked {
        + .radio-label {
          &:before {
            background-color: ${props => props.theme.colors.primary};
            box-shadow: inset 0 0 0 4px #f4f4f4;
          }
        }
      }
      &:focus {
        + .radio-label {
          &:before {
            outline: none;
            border-color: ${props => props.theme.colors.primary};
          }
        }
      }
      &:disabled {
        + .radio-label {
          &:before {
            box-shadow: inset 0 0 0 4px #f4f4f4;
            border-color: ${darken(0.25, "#f4f4f4")};
            background: ${darken(0.25, "#f4f4f4")};
          }
        }
      }
      + .radio-label {
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }
  }
`;

export const FieldErrorMessage = styled.div`
  color: ${(props) => props.theme.colors.warning};
  font-size: 14px;
  font-family: 'Red Hat Display', sans-serif;;
`;

export const InputField = styled.input`
  width: 100%;
  height: 56px;  
  background: #ffffff;
  border: 1px solid
    ${props => (props.invalid ? props.theme.colors.warning : "#ECECEC")};
  box-sizing: border-box;
  padding-left: 10px;
  font-size: 16px;
  outline: none;
  transition: all 0.3s ease;
  -webkit-appearance: none;
  border-radius: 10px;

  :focus {
    border: 1px solid
      ${props =>
    props.invalid
      ? props.theme.colors.warning
      : props.theme.colors.primary};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  :focus + label {
    color: ${props =>
    props.invalid ? props.theme.colors.warning : props.theme.colors.primary};
  }

  &:disabled {
    background-color: ${darken(.06, "#ffffff")};
    opacity: 0.8;
    cursor: not-allowed;
  }

  ::placeholder {
    color: ${props => props.theme.colors.bodyLight};
  }
`;

export const Label = styled.label`
  font-size: 14px;
    color: ${props => (props.invalid ? props.theme.colors.warning : props.theme.colors.neutral900)};
`;