import React, { useContext, useEffect, useState } from "react";

import ItemList from "../../components/ItemList";

import {
  ButtonFilterMobile,
  Container,
  ContainerFilter,
  ContainerHeaderItemList,
  ContainerProductFound,
  FilterInMobile,
  ProductContainer
} from "./styles";

import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../../components/DropdownFilter";
import { DropdownSelectOrdenation } from "../../components/DropdownSelectOrdenation";
import { FilterContext } from "../../contexts/FilterContext";
import { OrdenationContext } from "../../contexts/OrdenationContext";
import { setTitle } from "../../services/browser";
import { Creators as SearchProductsActions } from "../../store/ducks/search";

import iconFilter from "../../assets/images/icon-filter.svg";
import { FilterContainer } from "../../components/ItemList/styles";
import MainLayout from "../../components/MainLayout";

export default function SearchResultProducts({ history, match }) {
  const termReceived = match.params.term || ""
  const dispatch = useDispatch();
  const { productsFound, loading, totalizadorGeral } = useSelector(
    state => state.search
  );
  const [hasModalOpen, setHasModalOpen] = useState(false);
  const [isViewMobile, setIsViewMobile] = useState(window.innerWidth <= 520);
  const { setOrdenations, ordenation } = useContext(OrdenationContext);
  const { filter } = useContext(FilterContext);
  const [title, setTitle] = useState("");

  const options = [
    { value: "relevancia", label: "Relevância" },
    { value: "maior-preco", label: "Maior Preço" },
    { value: "menor-preco", label: "Menor Preço" },
    { value: "lancamento", label: "Lançamento" },
    { value: "programa-fidelidade", label: " Cartão Vantagem" }
  ];

  useEffect(() => {
    setTitle("Resultados para " + termReceived)
  }, [termReceived]);

  useEffect(() => {
    dispatch(SearchProductsActions.searchProductsRequest(
      termReceived,
      match.params.ctg ? match.params.ctgId : null,
      match.params.subCtg ? match.params.subCtgId : null
    ));
    setTitle(termReceived ? `Resultado da pesquisa por "${termReceived}"` : match.params.ctg || match.params.subCtg ? `Resultado de busca feita por categoria` : `Resultado da busca`);
  }, [dispatch, match.params.ctg, match.params.ctgId, match.params.subCtg, match.params.subCtgId, termReceived]);

  function loadMore(offset, limit) {
    dispatch(SearchProductsActions.loadMoreRequest(
      termReceived,
      filter,
      ordenation,
      match.params.ctg ? match.params.ctgId : null,
      match.params.subCtg ? match.params.subCtgId : null,
      offset,
      limit
    ));
  }

  useEffect(() => {
    if (hasModalOpen) {
      document.getElementById("filterMobile").style.display = "block";
      document.querySelector("header").style.display = "none";
      document.getElementById("searchStore").style.display = "none";
      document.getElementById("containerMainPage").style.display = "none";
    } else {
      document.querySelector("header").style.display = 'block';
      document.getElementById("searchStore").style.display = 'flex';
      document.getElementById("filterMobile").style.display = 'none'
      document.getElementById("containerMainPage").style.display = "block";
    }
  }, [hasModalOpen])

  useEffect(() => {
    function createElementFilterResponsive() {
      setIsViewMobile(window.innerWidth <= 520);
    }
    window.addEventListener('pageshow', () => {
      createElementFilterResponsive()
    });
    window.addEventListener('resize', () => {
      createElementFilterResponsive()
    });
  }, []);

  const showProductsCount = (count) => {
    return (
      <ContainerProductFound>
        {count > 0 && (
          <span>{count} produtos encontrados</span>
        )}
      </ContainerProductFound>
    );
  }

  return (
    <MainLayout history={history}>
      <FilterInMobile id="filterMobile">
        <Dropdown params={match.params && match.params} isModal={true} setIsOpen={() => {
          setHasModalOpen(!hasModalOpen)
        }} />
      </FilterInMobile>
      <div id="containerMainPage">
        <Container>

          <FilterContainer>
            <Dropdown params={match.params} isModal={false} />
          </FilterContainer>

          <ProductContainer>
            <ContainerHeaderItemList>
              {title && <h1>{title}</h1>}
              {isViewMobile && (
                showProductsCount(totalizadorGeral)
              )}
              <ContainerFilter hasMarginTop={isViewMobile}>
                {!isViewMobile ? (
                  showProductsCount(totalizadorGeral)
                ) : (
                  <ButtonFilterMobile onClick={() => {
                    setHasModalOpen(!hasModalOpen)
                  }}>
                    <span>Filtro</span>
                    <img src={iconFilter} alt="Icon Filter" />
                  </ButtonFilterMobile>
                )}
                <div className="ordenation">
                  <DropdownSelectOrdenation options={options} onChange={setOrdenations} />
                </div>
              </ContainerFilter>
            </ContainerHeaderItemList>

            <ItemList
              products={productsFound}
              loading={loading}
              loadMore={loadMore}
              haveFilter={true}
              searchTerm={match.params}
              isMobile={isViewMobile}
              totalizadorGeral={totalizadorGeral}
            />


          </ProductContainer>

        </Container>
      </div>
    </MainLayout>
  );
}
