
import React, { useState, useContext } from "react";
import { Step, Stepper, StepConnector, StepLabel } from  "@material-ui/core";
import { Container } from "./styles";
import ModalConfirm from "../../../components/ModalConfirm";
import { ThemeContext } from "styled-components";
import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp';
import { SvgIcon } from '@material-ui/core';
import { PedidoStatus, convertPedidoStatusExtenso } from '../../../util/order';
import { DeliveryType  } from '../../../util/deliveryType';

// Atenção, neste component, não utilizar a customização dos icones com styled component, 
// pois há um bug em que em produção que não respeita a ordem de carregamento dos css

const CustomStepConnector = (props) => {
  const { active, completed, className, ...otherProps } = props;

  return (
    <StepConnector
      className={`${className} step-connector  ${(active || completed) ? 'complete-active' : ''}`}
      {...otherProps}
    />
  );
};

const CustomStepIcon = ({step}) => {
  const { colors } = useContext(ThemeContext);

  let colorIcon = colors.disabled;

  if(step.completed)
    colorIcon = colors.alertsuccess;
  else if (step.active && step.error)
    colorIcon = colors.error;
  else if (step.active)
    colorIcon = colors.warning;
 
  if(step.completed)
  return (<CheckCircleSharpIcon style={{color: colorIcon, fontSize: 28}}/>
  );

  return (
    <SvgIcon style={{ fontSize: 25, color: colorIcon }}>
      <circle cx="12" cy="12" r="12" />
      <text x="12" y="16" textAnchor="middle" fill={"#FFF"} fontSize="10" fontWeight="bold">
        {step.id}
      </text>
    </SvgIcon>
  );
};
 

export default function OrderTimeline ({order}) {
  const [showModalAlert, setShowModalAlert] = useState(false);
  const [msgModalAlert, setMsgModalAlert] = useState("");

    order.pedidoEvento = order.pedido_evento

    const listaCheckpoints = montarListaCheckpointPedido(order);

    let chegouStatusAtual = false;

    listaCheckpoints.forEach(check => {
      if (check.label === order.status) chegouStatusAtual = true;

      if (
        check.label === order.status &&
        order.status !== PedidoStatus.pedidoEntregue
      )
        check.active = true;

        order.pedidoEvento = ordenarPedidoEventos(order.pedidoEvento);

        order.pedidoEvento.some(evento => {
        if (evento.status === check.label) {
          if (!check.active) check.completed = true;

          check.dateTime = new Date(evento.data_criacao).toLocaleString();

          return true;
        }

        if (chegouStatusAtual) return true;

        if (
          evento.status === PedidoStatus.falhaPagamento &&
          check.label === PedidoStatus.aguardandoPagamento &&
          order.status !== PedidoStatus.aguardandoPagamento
        ) {
          check.label = PedidoStatus.falhaPagamento;
          check.active = true;
          check.completed = false;
          check.error = true;
          check.errorMessage = evento.observacao;
          check.dateTime = new Date(evento.data_criacao).toLocaleString();
        } else if (
          evento.status === PedidoStatus.pagamentoAprovado &&
          check.label === PedidoStatus.aguardandoPagamento
        ) {
          check.label = PedidoStatus.pagamentoAprovado;
          check.completed = true;
          check.dateTime = new Date(evento.data_criacao).toLocaleString();
          
          if (order.status === PedidoStatus.pagamentoAprovado)
            chegouStatusAtual = true;
        } else if (
          (evento.status === PedidoStatus.aguardandoEntrega ||
            evento.status === PedidoStatus.aguardandoRetirada) &&
          check.label === PedidoStatus.pedidoSeparacao
        ) {
          check.label = PedidoStatus.pedidoLido;
          check.completed = true;
          check.dateTime = new Date(evento.data_criacao).toLocaleString();
        }

        return false;
      });

      check.label = convertPedidoStatusExtenso(check.label);

      if (check.active || check.completed || check.error) {
        if (check.error) {
          window.showErrorFalhaPagamento = () =>
            {
              setShowModalAlert(true)
              setMsgModalAlert([check.errorMessage])
            }
          check.label = `<a style="cursor: pointer; color: blue; text-decoration: underline;" onclick="showErrorFalhaPagamento()">${check.label}</a>`;
        }
        check.label = `${check.label}<br>${check.dateTime}`;
      }
    });

    if (order.status === PedidoStatus.pedidoCancelado) {
      for (let i = listaCheckpoints.length - 1; i >= 0; i--) {
        if (listaCheckpoints[i].dateTime) {
          listaCheckpoints[i].error = true;
          listaCheckpoints[i].active = true;
          listaCheckpoints[i].completed = false;
          break;
        }
      }
    }

    const timeLineProps = {
      steps: listaCheckpoints
    }

    const activeStepIndex =
    timeLineProps.steps?.findIndex(step => step.active) ?? 0;

  return (
    <Container>
    <Stepper
      className="step-row"
      alternativeLabel
      activeStep={activeStepIndex}
      connector={
        <CustomStepConnector />
      }
    >
      {timeLineProps.steps.map(step => (
        <Step key={step.label} completed={step.completed} alternativeLabel={true} >
          <StepLabel fail={step.error} 
          icon={(<CustomStepIcon step={step}/>)} >
            {<div style={{fontSize: 12}} dangerouslySetInnerHTML={{ __html: step.label }} />}
          </StepLabel>
        </Step>
      ))}

      {showModalAlert && (
        <ModalConfirm
        message={msgModalAlert}
        visible={showModalAlert}
        textBtnCancel="Fechar"
        cancel={() => {
          setShowModalAlert(false);
          setMsgModalAlert("");
        }}
      />
      )}
        
    </Stepper>
    </Container>
  );
};

const montarListaCheckpointPedido = (pedido) => {
  const listaStatus = (pedido) => {
    const listaStatus = [];
    listaStatus.push(PedidoStatus.pedidoRealizado);

    if (pedido.pagamento?.tipo_pagamento === "P") {
      listaStatus.push(PedidoStatus.pedidoSeparacao);

      if (pedido.tipo_entrega === DeliveryType.RETIRAR_NA_LOJA)
        listaStatus.push(PedidoStatus.aguardandoRetirada);
      else if (pedido.tipo_entrega === DeliveryType.RECEBER_EM_CASA)
        listaStatus.push(PedidoStatus.aguardandoEntrega);

      listaStatus.push(PedidoStatus.aguardandoPagamento);
    } else if (pedido.pagamento?.tipo_pagamento === "O") {
      listaStatus.push(PedidoStatus.aguardandoPagamento);
      listaStatus.push(PedidoStatus.pedidoSeparacao);

      if (pedido.tipo_entrega === DeliveryType.RETIRAR_NA_LOJA)
        listaStatus.push(PedidoStatus.aguardandoRetirada);
      else if (pedido.tipo_entrega === DeliveryType.RECEBER_EM_CASA)
        listaStatus.push(PedidoStatus.aguardandoEntrega);
    }

    listaStatus.push(PedidoStatus.pedidoEntregue);

    return listaStatus;
  };

  const criarListaRetorno = (pedido) => {
    const statusList = listaStatus(pedido);
    return statusList.map((status, index) => ({
      id: index + 1,
      label: status,
      dateTime: "",
      active: false,
      completed: false,
      error: false,
    }));
  };

  const novaLista = criarListaRetorno(pedido);
  return novaLista;
};

const ordenarPedidoEventos = (eventos) => {
  const statusSet = new Set();

  const evtRet = eventos
    .sort((a, b) => b.id - a.id)
    .filter(evento => {
      if (!statusSet.has(evento.status)) {
        statusSet.add(evento.status);
        return true;
      }
      return false;
    });

  return evtRet;
};

