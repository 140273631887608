import styled from "styled-components";
import { device } from "../../styles/device";
import ButtonSecondary from "../../components/ButtonSecondary";
import InputRadioCustom from "../../components/InputRadioCustom";
import ButtonPrimary from "../../components/ButtonPrimary";

export const ContentWrap = styled.div`
  display: flex;
  max-width: 1440px;
  width: 100%;
  overflow: hidden;
  margin: 32px auto 0 auto;
  align-items: flex-start;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  @media (max-width: 1440px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const RevisionWrap = styled.div`
  padding-bottom: 56px;
  border-bottom: solid 1px ${props => props.theme.colors.neutral100};
  margin-bottom: 56px;
  width: 100%;
  max-width: 500px;

  @media ${device.laptop}{
    max-width: none;
    border-bottom: none;
    /* padding: 0 70px 175px 0; */
    border-right: 1px solid ${props => props.theme.colors.neutral100};
  }
`;

export const WarnContainer = styled.div`
  @media ${device.laptop}{
   	margin-right: 21px;
  }
`;

export const ContainerProducts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (min-width: 1024px) {
        margin-right: 61px;
        max-width: none;
    }
`;

export const PriceInfoWrap = styled.div`
  display: flex;
  width: 100%;
  max-width: 500px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .linkCompra {
    font-size: 14px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.primary};
    padding: 20px;
    cursor: pointer;
  }

  @media ${device.laptop}{
    padding-left: 62px;
    max-width: none;
  }
`;

export const Overlay = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 10000;
  top: 0;

  > div {
    background: #fff;
    display: flex;
    width: calc(100% - 16px);
    max-width: 400px;
    padding: 16px;
    border-radius: 10px;
    justify-content: center;

    span {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      margin-left: 8px;
      text-transform: capitalize;
    }
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  max-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    align-items: center;
    display: flex;
  }
`;

export const TitleWrap = styled.div`
  margin-bottom: 30px;
  color: ${props => props.theme.colors.black};
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  align-self: start;
`;

export const WhatWouldLikeToDoTitleWrap = styled(TitleWrap)`
  margin-top: 40px;
`;

export const StyledInputRadioCustom = styled(InputRadioCustom)`
  margin-bottom: 56px;
`;

export const PriceInfoContent = styled.div`
  display: flex;
  width: 100%;
  max-width: 500px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  :has(>.total) {
    padding-top: 32px;
    margin-top: 32px;
    border-top: solid 1px ${props => props.theme.colors.neutral100};
  }

  span.price-value {
    padding: 8px 25px;
    font-family: 'Red Hat Display', sans-serif;;
    font-size: 16px;
    color: ${props => props.theme.colors.black};
    font-weight: 700;
  }

  span.price-description {
    padding: 8px 50px 8px 0;
    font-family: 'Red Hat Display', sans-serif;;
    color: ${props => props.theme.colors.neutral900};
    font-size: 16px;
    font-weight: 500;
  }

  span.total {
    font-weight: 700;
    font-size: 20px;
  }
`;

export const ButtonFooterWrap = styled.button`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 90%;
  background: none;
  color: ${(props) => props.theme.colors.bodyDark};
  background: ${(props) => props.theme.colors.primary};
  font-size: 1.6rem;
  border-radius: 40px;
  font-family: 'Red Hat Display', sans-serif;;
  border: none;
  transition: 0.3s;
  cursor: pointer;
  border: 1px solid #cecece78;

  &:hover {
    opacity: 0.9;
  }

  :disabled {
    opacity: 0.8;
  }

  > div {
    margin-top: 5px;
    margin-right: 12px;
  }

  span {
    font-weight: 500;
    margin-left: 5px;
  }
`;

export const StyledButtonPrimary = styled(ButtonPrimary)`
  width: 90%;
`;

export const StyledButtonSecondary = styled(ButtonSecondary)`
  width: 90%;
  margin-top: 15px;
`;
