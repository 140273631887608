import React from 'react';
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import ModalReader from '../ModalReader';

function PrivacyPolicyModal({ visible, closeModal }) {

  const { politica_privacidade, politica_privacidade_html } = useSelector((state) =>
    state.store.data == null ? "" : state.store.data.empresa
  );

  return (
    <>
      {visible && (
        <ModalReader visible={visible} closeModal={closeModal}>
          {politica_privacidade ? (
            <iframe src={politica_privacidade} title='Politica de Privacidade'></iframe>
          ) : (
            ReactHtmlParser(politica_privacidade_html || '')
          )}
        </ModalReader>
      )}
    </>
  );
}

export default PrivacyPolicyModal;
