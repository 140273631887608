export default {
    title: "msv",
  
    colors: {
      primary: "#143C97",
      primary30: "#F1F3F9",
      primary40: "#E7EBF5",
      primary50: "#D0D8EA",
      primary500: "#143C97",
      primaryDark: "#0C245A",
      secondary: "#F35C19",
      headingLight: "#262626",
      headingDark: "#262626",
      subtitleLight: "#262626",
      subtitleDark: "#ffffff",
      bodyLight: "#666",
      bodyDark: "#ffffff",
      link: "#006a66",
      background: "#FDFDFD",
      warning: "#EEA619",
      success: "#57b85b",
      alertsuccess: "#30D371",
      discountFlag: "#F35C19",
      disabled: "#A5A5A5",
      gray: "#D0D1D3",
      grayDark: "#44474E",
      grayLight: "#E8E8E9",
      black: "#000",
      white: "#FFF",
      error: "#DE1616",
      promotion: "#860202",
      info: "#FDF7E0",
      neutral50: "#F3F3F4",
      neutral100: "#E8E8E9",
      neutral200: "#D0D1D3",
      neutral400: "#A1A3A7",
      neutral600: "#72757A",
      neutral800: "#44474E",
      neutral900: "#151922",
      politics: "#009774",
    }
  };
  