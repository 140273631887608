import React from 'react';
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import ModalReader from '../ModalReader';

function ExchangeReturnPolicy({ visible, closeModal }) {

  const { politica_troca_devolucao } = useSelector((state) =>
    state.store.data == null ? "" : state.store.data.empresa
  );

  return (
    <>
      {visible && (
        <ModalReader visible={visible} closeModal={closeModal}>
            {ReactHtmlParser(politica_troca_devolucao || '')}
        </ModalReader>
      )}
    </>
  );
}

export default ExchangeReturnPolicy;
