import { darken } from "polished";
import styled from "styled-components";
import InputCustom from "../../components/InputCustom";

export const CheckoutPage = styled.div`
  /* height: calc(100vh - 291px); */
  overflow-y: visible;
  @media (max-width: 1368px) {
    height: auto;
  }
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  /* padding: 0 40px; */
  margin-top: 10px;
  margin-bottom: 25px;

  @media (max-width: 1000px) {
    flex-direction: column;
    padding: 0 20px;
  }

  @media (max-width: 400px) {
    padding: 15px;
    padding-top: 0px;
  }
`;

export const StoreItem = styled.div`
  padding: 24px;
  width: 100%;
  min-height: 170px;
  background: #ffffff;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  border: 1px solid
    ${(props) => (props.selected ? props.theme.colors.secondary : "#ECECEC")};
  transition: 100ms ease-in-out;

  div.store-info {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  div.store-address-info p {
    color: #666;
    margin-top: 8px;
  }

  div.delivery-time-info {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    span.label-time-info {
      color: #666;
      font-weight: 400;
      font-size: 14px;
    }

    span.titulo {
      color: ${(props) => props.theme.colors.headingLight};
      font-weight: bold;
    }

    strong {
      font-weight: 600;
      color: #666;
      margin-top: 4px;
      font-size: 16px;
    }

    span.withdrawal-time {
      margin-top: 12px;
      color: #57b85b;
      font-weight: 400;

      strong {
        font-weight: 600;
        color: #57b85b;
      }
    }
  }

  strong {
    font-size: 1.6rem;
    line-height: 18px;
    color: ${(props) =>
      props.selected
        ? props.theme.colors.secondary
        : props.theme.colors.primary};
  }
`;

export const ErrorContainer = styled.div`
  width: calc(100% - 32px);
  max-width: 1200px;
  background: #f15b59;
  color: ${(props) => props.theme.colors.bodyDark};
  font-size: 1.6rem;
  border-radius: 4px;
  display: flex;
  margin: 25px auto 10px auto;
  align-items: center;
  padding: 10px;
  min-height: 50px;
`;

export const FieldErrorMessage = styled.div`
  color: ${(props) => props.theme.colors.warning};
  font-size: 12px;
  margin-top: 5px;
  font-family: "Red Hat Display", sans-serif;
`;

export const CouponErrorMessage = styled.div`
  color: ${props => props.theme.colors.error};
  font-size: 14px;
  align-self: flex-start;
`;

export const CreditCard = styled.section`
  display: flex;
  max-width: 384px;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 18px;

  > div {
    width: 384px;
    height: 225px;
    @media (max-width: 496px) {
      width: 100%;
    }
  }

  > div > div {
    width: 384px;
    height: 225px;
    @media (max-width: 496px) {
      width: 100%;
    }
  }
  .rccs__card--unknown > div {
    background: linear-gradient(25deg, #143c97, #08183c);
  }
`;

export const InputField = styled.input`
  height: 45px;
  background: #ffffff;
  border: 1px solid
    ${(props) => (props.invalid ? props.theme.colors.warning : "#cfcdcd")};
  box-sizing: border-box;
  padding-left: 10px;
  font-size: 16px;
  outline: none;
  transition: all 0.3s ease;
  -webkit-appearance: none;
  border-radius: 8px;

  + label {
    font-weight: 700;
    color: ${(props) =>
      props.invalid
        ? props.theme.colors.warning
        : props.theme.colors.neutral900};
  }

  :focus {
    border: 1px solid
      ${(props) =>
        props.invalid
          ? props.theme.colors.warning
          : props.theme.colors.neutral900};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-weight: 700;
  }

  :focus + label {
    font-weight: 700;
    color: ${(props) =>
      props.invalid
        ? props.theme.colors.warning
        : props.theme.colors.neutral900};
  }

  ::placeholder {
    color: ${(props) => props.theme.colors.bodyLight};
  }

  :disabled {
    background: #f1f1f1;
  }

  @media (max-width: 1000px) {
  }
`;

export const Tabs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e8e8e8;
  margin: 20px 0;
`;

export const Image = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 30px;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }

  img {
    object-fit: contain;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  span {
    color: ${(props) => props.theme.colors.neutral900};
    font-weight: bold;
    font-size: 16px;
    height: 100%;
    width: 150px;
    text-align: center;
  }

  span.selected {
    border-bottom: solid 3px ${(props) => props.theme.colors.primary};
  }
`;

export const RadioConteinerGroup = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  align-items: center;
  padding-top: 10px;
  width: 100%;

  & > div {
    display: flex;
    width: 100%;
  }

  span {
    font-size: 16px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.neutral900};
    padding-left: 34px;
    display:none;
  }

  .textLine {
    display: flex;
    gap: 24px;
    padding-left: 34px;
    width: 100%;
    align-items: end;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 0px;
    }
  }

  .textArea {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    width: 100%;

    input {
      margin-left: 0px;
      width: 100%;
    }

    span {
      font-size: 12px;
      font-weight: 700;
      color: ${(props) => props.theme.colors.neutral900};
      padding-left: 0;
    }
  }

  input {
    font-size: 16px;
    height: 53px;
  }

  .radio {
    flex-direction: column;
    display: flex;
    padding: 16px;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid var(--neutral-100, #e8e8e9);
    width: 100%;
    font-weight: bold;
    margin: 0.5rem;
    line-height: 26.5px;

    .radio-label {
      color: var(--neutral-900, #151922);
      font-family: Red Hat Display;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: 6px;
    }

    input[type="radio"] {
      position: absolute;
      opacity: 0;
      + .radio-label {
        &:before {
          content: "";
          background: #fff;
          border-radius: 100%;
          border: 1px solid #e8e8e8;
          display: inline-block;
          width: 17px;
          height: 17px;
          position: relative;
          margin-right: 16px;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }
      &:checked {
        + .radio-label {
          &:before {
            background-color: ${(props) => props.theme.colors.primary};
            box-shadow: inset 0 0 0 2.5px #f4f4f4;
            border: 1px solid ${(props) => props.theme.colors.primary};
          }
        }
      }
      &:focus {
        + .radio-label {
          &:before {
            outline: none;
            border-color: ${(props) => props.theme.colors.primary};
          }
        }
      }
      &:disabled {
        + .radio-label {
          &:before {
            box-shadow: inset 0 0 0 2.5px #f4f4f4;
            border-color: ${darken(0.25, "#f4f4f4")};
            background: ${darken(0.25, "#f4f4f4")};
          }
        }
      }
      + .radio-label {
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }
  }

  .selected {
    border: 1px solid ${(props) => props.theme.colors.primary};
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  width: 384px;
  margin: 10px 0;

  .combobox {
    label {
      color: ${(props) => props.theme.colors.neutral900};
      font-weight: 700;
    }
    > div {
      height: 53px !important;
      border-radius: 8px;
    }
  }

  .segundaCol {
    margin-left: 20px;
  }

  @media (max-width: 760px) {
    width: 100%;
    .segundaCol {
      margin-left: 0;
    }
  }
`;

export const Label = styled.label`
  transition: all 0.3s ease;
  font-family: "Red Hat Display", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: ${(props) => props.theme.colors.neutral900};
`;

export const Form = styled.form`
  overflow: auto;
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .pag-card {
    min-height: 650px;
    width: 100%;

    .cardGroup {
      display: flex;
      @media (max-width: 760px) {
        flex-direction: column;
        width: 100%;
      }
    }

    .menorCampo {
      width: 180px;
      @media (max-width: 760px) {
        width: 100%;
      }
    }

    @media (max-width: 760px) {
      min-height: 850px;
    }
  }

  .pag-local {
    min-height: 650px;
    width: 100%;
    .cardGroup {
      display: flex;
      @media (max-width: 760px) {
        flex-direction: column;
        width: 100%;
      }
    }

    .menorCampo {
      width: 180px;
      @media (max-width: 760px) {
        width: 100%;
      }
    }
  }

  h1 {
    margin-top: 40px;
  }

  @media (max-width: 760px) {
    grid-template-columns: 1fr;
    padding: 0;
  }
`;

export const PaymentDetails = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;

  h1 {
    color: var(--neutral-900, #151922);
    /* Subtitle H2 */
    font-family: Red Hat Display;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const OrderDetails = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 30px;

  @media (max-width: 1000px) {
    align-items: center;
    margin: 0;
    padding: 0;
  }

  h1 {
    color: ${(props) => props.theme.colors.headingLight};
    font-size: 1.8rem;
    font-weight: 500;
    display: flex;
    align-self: flex-start;
    padding: 10px 0;
  }
`;

export const PaymentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 10px;
  /* padding-left: 35px;
  padding-right: 35px; */
  margin: 5px 0;
  min-height: calc(100vh - 450px);

  @media (max-width: 1000px) {
    max-width: 100%;
    padding: 0px;
  }

  @media (max-width: 1000px) {
    max-width: 100%;
    padding: 0px;
    min-height: inherit;
  }

  h3 {
    padding: 5px 0;
    color: ${(props) => props.theme.colors.headingLight};
    font-size: 1.6rem;
    font-weight: 500;
  }

  a {
    color: ${(props) => props.theme.colors.primary};
    font-size: 1.6rem;
    cursor: pointer;
    text-decoration: underline;

    strong {
      font-weight: 500;
    }
  }
`;

export const Item = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  padding: 10px;
  padding-left: 35px;
  padding-right: 35px;
  margin: 5px 0;
  background: #fff;

  @media (max-width: 1000px) {
    max-width: 100%;
  }

  h3 {
    padding: 5px 0;
    color: ${(props) => props.theme.colors.headingLight};
    font-size: 1.6rem;
    font-weight: 500;
  }

  a {
    color: ${(props) => props.theme.colors.primary};
    font-size: 1.6rem;
    cursor: pointer;
    text-decoration: underline;

    strong {
      font-weight: 500;
    }
  }
`;

export const Address = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  padding: 20px;
  padding-left: 35px;
  margin: 5px 0;
  background: #fff;

  @media (max-width: 1000px) {
    max-width: 100%;
  }

  h3 {
    padding: 5px 0;
    color: ${(props) => props.theme.colors.headingLight};
    font-size: 1.6rem;
    font-weight: 500;
  }

  span {
    color: ${(props) => props.theme.colors.bodyLight};
    font-size: 1.6rem;
  }
`;

export const PriceInfoContent = styled.div`
  display: flex;
  width: 100%;
  max-width: 500px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  :has(> .total) {
    padding-top: 32px;
    margin-top: 32px;
    border-top: solid 1px ${(props) => props.theme.colors.neutral100};
  }

  @media (max-width: 900px) {
    max-width: 100%;
    padding: 0px;
  }

  span.price-value {
    padding: 8px 25px;
    font-family: "Red Hat Display", sans-serif;
    font-size: 16px;
    color: ${(props) => props.theme.colors.black};
    font-weight: 700;
  }

  span.price-description {
    padding: 8px 50px 8px 0;
    font-family: "Red Hat Display", sans-serif;
    color: ${(props) => props.theme.colors.neutral900};
    font-size: 16px;
    font-weight: 500;
  }

  span.coupon-discount {
    color: ${(props) => props.theme.colors.error};
  }

  span.total {
    font-weight: 700;
    font-size: 20px;
  }
`;

export const ButtonFooterWrap = styled.button`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 90%;
  background: none;
  color: ${(props) => props.theme.colors.bodyDark};
  background: ${(props) => props.theme.colors.primary};
  font-size: 1.6rem;
  border-radius: 40px;
  font-family: "Red Hat Display", sans-serif;
  border: none;
  transition: 0.3s;
  cursor: pointer;
  border: 1px solid #cecece78;

  &:hover {
    opacity: 0.9;
  }

  :disabled {
    opacity: 0.8;
    background: ${props => props.theme.colors.neutral200};
    color: ${props => props.theme.colors.neutral800};
  }

  > div {
    margin-top: 5px;
    margin-right: 12px;
  }

  span {
    font-weight: 500;
    margin-left: 5px;
  }

  @media (max-width: 900px) {
    max-width: 100%;
    padding: 0 10px;
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  max-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    align-items: center;
    display: flex;
  }
`;

export const OrderSubmittingOverlay = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 10000;
  top: 0;

  > div {
    background: #fff;
    display: flex;
    width: calc(100% - 16px);
    max-width: 400px;
    padding: 16px;
    border-radius: 10px;
    justify-content: center;

    span {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      margin-left: 8px;
      text-transform: capitalize;
    }
  }
`;

export const RadioSelectPayment = styled.div`
  display: flex;
  gap: 10px;
  padding: 2px 4px;
  align-items: baseline;

  .labelDiscount {
    background-color: #30D371;
    border-radius: 4px;
    padding: 0px 5px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    }
`;

export const StyledCouponInput = styled(InputCustom)`
  width: 100%;
  max-width: 280px;
  align-self: normal;
  .redFont{
        color: ${props => props.theme.colors.error};
    }
`;
