import React, { useRef, useState } from "react";
import {
  AddressItem,
  AddressList,
  ContainerModalEditOrNewAddress,
  FormContainer,
} from "./styles";
import { FiEdit, FiPlus } from "react-icons/fi";
import { formatAddress } from "../../util/formatAddress";
import ModalLayout from "../../components/ModalLayout";
import Radio from "../Radio";
import api from "../../services/api";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Creators as AddressAcions } from "../../store/ducks/address";
import AddressForm from "../AddressForm";

export default function AddressSelectionModal({
  addresses,
  selectedAddress,
  setSelectedAddress,
  closeModal,
  showOverlay,
}) {
  const [selectedCustomerAddress, setSelectedCustomerAddress] =
    useState(selectedAddress);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.profile);
  const [showAddressFormModal, setShowAddressFormModal] = useState(false);
  const [addressSelectedToEdit, setAddressSelectedToEdit] = useState(null);

  const formRef = useRef();

  function openAddressFormModal(address) {
    setShowAddressFormModal(true);
    setAddressSelectedToEdit(address);
  }

  function closeAddressFormModal() {
    setShowAddressFormModal(false);
    setAddressSelectedToEdit(null);
  }

  async function onAddressSubmit(values) {
    try {
      showOverlay(true);
      const {
        id,
        nome_contato,
        telefone_contato,
        cep,
        uf,
        cidade,
        bairro,
        numero,
        rua,
        complemento,
        ibge,
      } = values;

      const formattedPhoneNumber = telefone_contato.replace(
        /[^a-zA-Z0-9]/g,
        ""
      );
      const formattedCEP = cep.replace(/[^a-zA-Z0-9]/g, "");
      let address = null;
      if (id > 0) {
        const { data } = await api.put(`customers/${user.uuid}/address/${id}`, {
          nome_contato,
          telefone_contato: formattedPhoneNumber,
          cep: formattedCEP,
          uf,
          cidade,
          bairro,
          numero,
          rua,
          complemento,
          ibge,
        });
        address = data.address;
      } else {
        const { data } = await api.post(`customers/${user.uuid}/address`, {
          nome_contato,
          telefone_contato: formattedPhoneNumber,
          cep: formattedCEP,
          uf,
          cidade,
          bairro,
          numero,
          rua,
          complemento,
          ibge,
        });
        address = data.address;
      }
      closeModal();
      toast.success("Endereço salvo com sucesso");
      if (address) {
        dispatch(AddressAcions.pushAddress(address));
        setSelectedAddress(address);
      }
    } catch (e) {
      toast.error(e.response?.data?.user || "Erro ao salvar endereço");
    } finally {
      showOverlay(false);
    }
  }

  return showAddressFormModal ? (
    <ModalLayout
      showModal={true}
      handleCloseModal={closeAddressFormModal}
      title={addressSelectedToEdit ? "Editar Endereço" : "Novo Endereço"}
      buttonLeft={{
        text: "Salvar",
        action: () => {
          formRef.current.submitForm();
        },
      }}
      buttonRight={{
        text: "Voltar",
        action: closeAddressFormModal,
      }}
    >
      <FormContainer>
        <AddressForm
          formRef={formRef}
          address={{
            id: addressSelectedToEdit?.id,
            contactName: addressSelectedToEdit?.nome_contato,
            contactPhone: addressSelectedToEdit?.telefone_contato,
            zipCode: addressSelectedToEdit?.cep,
            state: addressSelectedToEdit?.cidade?.uf_sigla,
            city: addressSelectedToEdit?.cidade?.nome,
            neighborhood: addressSelectedToEdit?.bairro,
            number: addressSelectedToEdit?.numero,
            street: addressSelectedToEdit?.endereco,
            complement: addressSelectedToEdit?.complemento,
            ibge: addressSelectedToEdit?.ibge,
          }}
          onAddressSubmit={onAddressSubmit}
        />
      </FormContainer>
    </ModalLayout>
  ) : (
    <ModalLayout
      showModal={true}
      handleCloseModal={closeModal}
      title="Endereço de Entrega"
      buttonLeft={{
        text: "Selecionar Endereço",
        action: () => {
          setSelectedAddress(selectedCustomerAddress);
          closeModal();
        },
        disabled: !selectedCustomerAddress,
      }}
      buttonRight={{
        text: "Voltar",
        action: closeModal,
      }}
    >
      <ContainerModalEditOrNewAddress>
        {addresses && addresses.length > 0 && (
          <AddressList>
            {addresses.map((address) => (
              <AddressItem
                selected={address.id === selectedCustomerAddress?.id}
                key={address.id}
                className="choose-address-btn"
              >
                <Radio
                  id={"radio-" + address.id}
                  name="customer-address"
                  value={address.id}
                  checked={address.id === selectedCustomerAddress?.id}
                  onChange={(e) => setSelectedCustomerAddress(address)}
                  onBlur={(e) => setSelectedCustomerAddress(address)}
                />
                <div className="line-address">
                  <div className="address-info">
                    <h2>{address.endereco}</h2>
                    <p>
                      {formatAddress(
                        address.endereco,
                        address.numero,
                        address.bairro,
                        `${address.cidade.nome} - ${address.cidade.uf_sigla}`,
                        address.complemento
                      )}
                    </p>
                  </div>
                  <div className="addresses-bottom">
                    <button
                      className="edit-address-btn"
                      onClick={() => openAddressFormModal(address)}
                    >
                      <FiEdit size={18} />
                    </button>
                  </div>
                </div>
              </AddressItem>
            ))}
          </AddressList>
        )}
        <div className="addresses-bottom">
          <button
            className="add-address-btn"
            onClick={() => openAddressFormModal()}
          >
            {" "}
            <FiPlus size={18} /> Adicionar novo endereço
          </button>
        </div>
      </ContainerModalEditOrNewAddress>
    </ModalLayout>
  );
}
