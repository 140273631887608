import styled from "styled-components";
import { device } from "../../styles/device";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 6000 !important;
  top: 0;
`;

export const Modal = styled.div`
  width: 95vw;
  max-height: 95vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 40px;
  
  @media ${device.tablet} {
    width: 50%;
    max-width: 500px;
  }

  @media ${device.laptop} {
    width: 40%;
  }
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 0px 10px 0px;
  background: #fff;
  border-radius: 16px;

  @media ${device.tablet}{
    padding: 24px 18px 24px 18px;
 }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 16px 0px 16px;

    h1 {
        font-size: 20px;
        font-weight: 700;
        color: #151922;
    }

    button {
        align-self: flex-end;
        display: flex;
        align-items: center;
        background-color: #fff;
        border: none;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: hidden;
    padding: ${props => props.removePadding ? '0px' : "0px 16px 0px 16px"};
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    padding: 0px 16px 0px 16px;
    justify-content: center;
`;
