import produce from "immer";

export const Types = {
  GET_REQUEST: "suggest/GET_REQUEST",
  GET_SUCCESS: "suggest/GET_SUCCESS",
  CLEAR: "suggest/CLEAR"
};

const INITIAL_STATE = {
  suggestions: [],
  loading: false,
  offset: 0,
  limit: 6
};

export default function suggest(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_SUCCESS: {
        draft.suggestions = action.payload.suggestions;
        draft.loading = false;
        break;
      }
      case Types.CLEAR: {
        draft.suggestions = [];
        break;
      }
      default:
    }
  });
}

export const Creators = {
  getSuggestionsRequest: () => ({
    type: Types.GET_REQUEST
  }),

  getSuggestionsSuccess: suggestions => ({
    type: Types.GET_SUCCESS,
    payload: { suggestions }
  }),

  clearSuggestions: () => ({
    type: Types.CLEAR
  })
};
