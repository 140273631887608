import produce from "immer";

export const Types = {
  GET_REQUEST: "@menu/GET_REQUEST",
  GET_SUCCESS: "@menu/GET_SUCCESS",
  GET_FAILURE: "@menu/GET_FAILURE",
};


const INITIAL_STATE = {
  data: null,
  loading: false,
};

export default function menu(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_REQUEST:
        draft.loading = true;
        break;
      case Types.GET_SUCCESS:
        draft.data = action.payload.data;
        draft.loading = false;
        break;
      default:
    }
  });
}

export const Creators = {
  getRequest: () => ({
    type: Types.GET_REQUEST,
  }),

  getSuccess: (data) => ({
    type: Types.GET_SUCCESS,
    payload: { data },
  }),

  getFailure: (error) => ({
    type: Types.GET_FAILURE,
    payload: { error },
  }),
};
