import React from "react";
import { MdClose } from "react-icons/md";
import { Container, Content, ParagraphContainer } from "./styles";

function ModalReader({ visible, closeModal, children }) {

    if (!visible) 
        return null

    return (
        <Container>
            <Content>
                <button className="close" onClick={() => closeModal()}>
                    <MdClose />
                </button>
                <div>
                    <ParagraphContainer>
                        {children}
                    </ParagraphContainer>
                </div>
            </Content>
        </Container>
    )
}

export default ModalReader;
