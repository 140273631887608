export function formatCNPJ(cnpj) {
    if (!cnpj) return '';
  
    const formattedCNPJ = cnpj.replace(/[^0-9]/g, '');
  
    if (formattedCNPJ.length !== 14) return '';
  
    const partes = [
      formattedCNPJ.slice(0, 2),
      formattedCNPJ.slice(2, 5),
      formattedCNPJ.slice(5, 8),
      formattedCNPJ.slice(8, 12),
      formattedCNPJ.slice(12, 14),
    ];
  
    return `${partes[0]}.${partes[1]}.${partes[2]}/${partes[3]}-${partes[4]}`;
  }