import React, { useEffect, useRef, useState } from "react";
import ModalLayout from "../ModalLayout";
import {
  Container,
  Content,
  SearchOptionsContainer,
  TabButton,
  WithoutStoreContainer,
} from "./styles";
import LoaderAsync from "../LoaderAsync";
import api from "../../services/api";
import StoreCard from "../StoreCard";
import ResponsibleWithdrawalForm from "../ResponsibleWithdrawalForm";
import { DeliveryType } from "../../util/deliveryType";
import StoreSelectorByAddress from "../StoreSelectorByAddress";
import { toast } from "react-toastify";
import { MdWarning } from "react-icons/md";
import { useSelector } from "react-redux";

export default function PickupModal({
  customerAddressId,
  cart,
  store,
  closeModal,
  showOverlay,
  onChoosePickupStore,
}) {
  const searchOptions = {
    close: "C",
    neighborhood: "N",
  };

  const steps = {
    selectStore: 1,
    responsibleWithdrawal: 2,
  };

  const [step, setStep] = useState(steps.selectStore);
  const [loading, setLoading] = useState(false);
  const [searchOptionFocused, setSearchOptionFocused] = useState(
    searchOptions.close
  );
  const [
    allInformationsAreFilledInNeighborhoodSearchOption,
    setAllInformationsAreFilledInNeighborhoodSearchOption,
  ] = useState(false);
  const [stores, setStores] = useState([]);
  const user = useSelector((state) => state.user.profile);
  const [selectedStore, setSelectedStore] = useState(null);
  const [responsibleWithdrawal, setResponsibleWithdrawal] = useState(null);

  const formRef = useRef();

  useEffect(() => {
    clearScreenWhenSearchOptionIsChanged();
  }, [searchOptionFocused]);

  useEffect(() => {
    if (customerAddressId) {
      if (searchOptionFocused === searchOptions.close) {
        getClosestStores(customerAddressId, cart, store);
      }
    }
  }, [searchOptionFocused, customerAddressId, cart, store]);

  function clearScreenWhenSearchOptionIsChanged() {
    setSelectedStore(null);
    setStores([]);
  }

  async function getClosestStores(customerAddressId, cart, store) {
    try {
      setStores([]);
      setLoading(true);
      showOverlay(true);
      const products = getProductsInCart(cart.data);
      const { data } = await api.post(
        `/stores/customerAddress/${customerAddressId}/pickup`,
        { products: products, storeIdPrimary: store?.id }
      );
      if (data) setStores(data);
    } catch (error) {
      toast.error(
        error.response?.data?.user || "Erro ao obter opções de retirada"
      );
    } finally {
      setLoading(false);
      showOverlay(false);
    }
  }

  function getProductsInCart(updatedCart) {
    if (updatedCart) {
      return updatedCart.map((item) => {
        return {
          productId: item.id,
          amount: item.quantidade,
        };
      });
    } else {
      return cart.map((item) => {
        return {
          productId: item.id,
          amount: item.quantidade,
        };
      });
    }
  }

  function onSelectStore(storeId) {
    const selectedInfoStore = stores.find(
      (infoStore) => infoStore.id === storeId
    );
    setSelectedStore(selectedInfoStore);
  }

  function showSelectStoreModal() {
    setResponsibleWithdrawal(null);
    setStep(steps.selectStore);
  }

  function onSubmitResponsibleWithdrawalForm(values) {
    var cpfWithoutMask = values.cpf?.replace(/[^\d]+/g, "");
    if (cpfWithoutMask === user.cpf) {
      notifyCPFIsSameOwnerAccount();
      return;
    }

    onChoosePickupStore(selectedStore, values);
    closeModal();
  }

  function notifyCPFIsSameOwnerAccount() {
    toast.error(
      "O CPF informado é o mesmo do próprietário da conta. Por favor, digite outro CPF ou informe que não será retirado por um terceiro."
    );
  }

  async function getStoresByAddress(state, city, neighborhood) {
    try {
      setLoading(true);
      showOverlay(true);
      clearScreenWhenSearchOptionIsChanged();
      const products = getProductsInCart(cart.data);
      const { data } = await api.post(
        `/stores/pickup/${state}/${city}/${neighborhood}`,
        { products: products, storeIdPrimary: store?.id }
      );
      if (data) setStores(data);
    } catch (error) {
      toast.error(
        error.response?.data?.user || "Erro ao obter opções de retirada"
      );
      throw error;
    } finally {
      setLoading(false);
      showOverlay(false);
    }
  }

  async function onChangeStoreAddress(state, city, neighborhood) {
    if (state && city && neighborhood) {
      setAllInformationsAreFilledInNeighborhoodSearchOption(true);
      await getStoresByAddress(state, city, neighborhood);
    } else {
      setAllInformationsAreFilledInNeighborhoodSearchOption(false);
    }
  }

  return (
    <>
      {step === steps.responsibleWithdrawal && (
        <ModalLayout
          showModal={true}
          handleCloseModal={showSelectStoreModal}
          title="Quem vai retirar os produtos?"
          buttonLeft={{
            text: "Avançar",
            action: () => {
              if (formRef.current) formRef.current.submitForm();
            },
            disabled: !selectedStore,
          }}
          buttonRight={{
            text: "Voltar",
            action: showSelectStoreModal,
          }}
        >
          <Container>
            <ResponsibleWithdrawalForm
              formRef={formRef}
              responsibleWithdrawal={{
                withdrawalByThird: responsibleWithdrawal?.retirada_terceiro,
                fullName: responsibleWithdrawal?.nome_completo,
                cpf: responsibleWithdrawal?.cpf,
                phone: responsibleWithdrawal?.telefone_contato,
              }}
              onSubmitResponsibleWithdrawalForm={
                onSubmitResponsibleWithdrawalForm
              }
            />
          </Container>
        </ModalLayout>
      )}

      <div style={{ display: step === steps.selectStore ? "block" : "none" }}>
        <ModalLayout
          showModal={true}
          handleCloseModal={closeModal}
          title="Retire na farmácia"
          buttonLeft={{
            text: "Confirmar",
            action: () => {
              setStep(steps.responsibleWithdrawal);
            },
            disabled: !selectedStore,
          }}
          buttonRight={{
            text: "Voltar",
            action: closeModal,
          }}
        >
          <SearchOptionsContainer>
            <TabButton
              focused={searchOptionFocused === searchOptions.close}
              onClick={() => setSearchOptionFocused(searchOptions.close)}
            >
              Farmácias próximas
            </TabButton>
            <TabButton
              focused={searchOptionFocused === searchOptions.neighborhood}
              onClick={() => setSearchOptionFocused(searchOptions.neighborhood)}
            >
              Busca por bairro
            </TabButton>
          </SearchOptionsContainer>
          <Content>
            {searchOptionFocused === searchOptions.neighborhood && (
              <StoreSelectorByAddress
                showOverlay={showOverlay}
                onChangeStoreAddress={onChangeStoreAddress}
              />
            )}
            {loading ? (
              <LoaderAsync
                message={
                  searchOptionFocused === searchOptions.close
                    ? "Buscando lojas próximas ao seu endereço..."
                    : "Buscando lojas..."
                }
              />
            ) : stores && stores.length > 0 ? (
              stores.map((infoStore) => (
                <StoreCard
                  key={infoStore.id}
                  name="pickup-modal"
                  store={{
                    storeId: infoStore.id,
                    storeName: infoStore.nome_fantasia,
                    address: {
                      street: infoStore.endereco,
                      number: infoStore.endereco_numero,
                      neighborhood: infoStore.bairro,
                    },
                    distance: infoStore.distancia,
                    expectedDate: {
                      startTime: infoStore.lojaHorario.hora_inicio,
                      endTime: infoStore.lojaHorario.hora_fim,
                      availabilityDate:
                        infoStore.lojaHorario.data_disponibilidade,
                      timeToReceive: infoStore.lojaHorario.tempo_para_retirada,
                    },
                  }}
                  selectedStore={
                    selectedStore?.tipoEntrega ===
                      DeliveryType.RETIRAR_NA_LOJA &&
                    selectedStore?.id === infoStore.id
                  }
                  onSelectStore={onSelectStore}
                />
              ))
            ) : (
              (searchOptionFocused !== searchOptions.neighborhood ||
                (searchOptionFocused === searchOptions.neighborhood &&
                  allInformationsAreFilledInNeighborhoodSearchOption)) && (
                <WithoutStoreContainer>
                  <MdWarning size={24} color="yellow" />
                  <p>Não existem farmácias que atendam no seu endereço.</p>
                </WithoutStoreContainer>
              )
            )}
          </Content>
        </ModalLayout>
      </div>
    </>
  );
}
