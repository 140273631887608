import styled from "styled-components";

export const Container = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 70000;
  overflow-y: auto;
`;

export const LoaderContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
padding: 32px 0;
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  padding: 64px;
  max-width: 800px;
  margin-left: 20px;
  margin-right: 20px;
  height: calc(100vh - 64px);
  background: #fff;
  border-radius: 8px;

  > div {
    overflow: hidden;
    height: 100%;
    width: 100%;
  }

  @media (max-width: 600px) {
    margin: 0;
    border-radius: 0;
    max-width: 100%;
    min-height: 100vh;
    padding: 32px;
  }

  button.close {
    position: absolute;
    top: 16px ;
    right: 16px;
    width: 30px;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 3.4rem;
      color: ${props => props.theme.colors.politics};
    }

    @media (max-width: 600px) {
      right: 8px;
      svg {
        font-size: 3rem;
      }
    }
  }
`;

export const ParagraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;    
  width: 100%;
  height:100%;
  overflow-y: auto;

  iframe {
    border: none;
    width: 100%;
    height:100%;
  }

  ol {
    counter-reset: item;

    > ol {
      margin-left: 8px;
    }
  }
  li {
    display: block;
    font-size: 1.6rem;
    font-weight: 300;
    color: ${(props) => props.theme.colors.bodyLight};
    padding: 4px 0;
    line-height: 3rem;
    text-align: left;

    strong {
      font-size: 1.8rem;
      font-weight: 500;
      color: ${(props) => props.theme.colors.politics};
      line-height: 64px;
      text-transform: uppercase;
    }

    > ol {
      margin-left: 8px;
    }
  }

  li:before {
    content: counters(item, ".") " ";
    counter-increment: item;

    font-size: 1.8rem;
    font-weight: 400;
    color: ${(props) => props.theme.colors.politics};
  }

  h1 {
    font-size: 2.4rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors.politics};
    margin-bottom: 16px;
    text-transform: uppercase;
  }

  h2 {
    font-size: 2rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.politics};
    margin-bottom: 16px;
    margin-top: 16px;
    text-transform: uppercase;
  }

  p {
    font-size: 1.6rem;
    font-weight: 300;
    color: ${(props) => props.theme.colors.bodyLight};
    padding-bottom: 16px;
    line-height: 3rem;
    text-align: left;
  }

  b {
    color: ${(props) => props.theme.colors.bodyLight};
    font-weight: 400;
  }

  a {
    color: blue;
    text-decoration: underline;
  }
`;
