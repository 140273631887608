import React from "react";
import ButtonPrimary from "../ButtonPrimary";
import Link from "../Link";
import imgFechar from "../../assets/images/close-popup.svg";
import { Container, Modal, Header, Content, Footer } from "./styles"

const ModalLayout = ({ showModal, title, handleCloseModal, children, buttonLeft, buttonRight, removePaddingContent }) => {
    return (
        <>
            {showModal && (
                <Container>
                    <Modal>
                        <Header>
                            <button onClick={handleCloseModal}>
                                <img src={imgFechar} alt={"Fechar"} />
                            </button>
                            <h1>{title}</h1>
                        </Header>
                        <Content removePadding={removePaddingContent}>
                            {children}
                        </Content>
                        {buttonLeft && buttonRight && (
                            <Footer>
                                <ButtonPrimary text={buttonLeft.text} onClick={buttonLeft.action} disabled={buttonLeft.disabled} />
                                <Link text={buttonRight.text} disabled={buttonRight.disabled} action={buttonRight.action} />
                            </Footer>
                        )}
                    </Modal>
                </Container>
            )}
        </>

    )
}

export default ModalLayout;