import React from "react";
import { Formik } from "formik";
import {
  Container,
  FormContainer,
  Content,
  StyledButtonPrimary,
  StyledInputCustom
} from "./styles";
import { UpdatePasswordSchema } from "./validation";
import { useDispatch, useSelector } from "react-redux";
import { Creators as PasswordActions } from "../../store/ducks/password";
import Error from "../../components/Error";
import closePopup from "../../assets/images/close-popup.svg";
import Link from "../../components/Link";


export default function UpdatePasswordModal({closeModal}) {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.password);

  function handleSubmit({ senhaAtual, senhaNova, confirmacaoSenhaNova }) {
    dispatch(
      PasswordActions.updatePasswordRequest({
        senhaAtual,
        senhaNova,
        confirmacaoSenhaNova
      })
    );
  }

  return (
    <Container>
      <Content>
        <img className="closePopup" src={closePopup} alt="Close popup" onClick={closeModal}/>
        <h2>Alterar minha senha</h2>
        <Formik
          initialValues={{
            senhaAtual: "",
            senhaNova: "",
            confirmacaoSenhaNova: ""
          }}
          validationSchema={UpdatePasswordSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            handleChange,
            handleBlur,
            errors,
            touched,
            handleSubmit,
            isSubmitting,
            isValid,
            resetForm
          }) => (
            <FormContainer onSubmit={handleSubmit}>
              <Error errorKey="update-password" style={{ marginBottom: 20 }} />

              <StyledInputCustom id="senhaAtual" type="password" name="senhaAtual" description="Senha atual" value={values.senhaAtual} onChange={handleChange} onBlur={handleBlur} isInvalid={errors.senhaAtual && touched.senhaAtual} errors={errors.senhaAtual} />

              <div className="novaSenhaContainer">
                <StyledInputCustom id="senhaNova" type="password" name="senhaNova" description="Nova senha" value={values.senhaNova} onChange={handleChange} onBlur={handleBlur} isInvalid={errors.senhaNova && touched.senhaNova} errors={errors.senhaNova} />

                <StyledInputCustom className="senhaNovaContainer" id="confirmacaoSenhaNova" type="password" name="confirmacaoSenhaNova" description="Confirmar nova senha" value={values.confirmacaoSenhaNova} onChange={handleChange} onBlur={handleBlur} isInvalid={errors.confirmacaoSenhaNova && touched.confirmacaoSenhaNova} errors={errors.confirmacaoSenhaNova} />
              </div>

              <div className="botoesContainer">
                <StyledButtonPrimary className="buttonSubmit" type="submit" disabled={loading || !isValid} text={loading ? "Salvando alterações" : "Salvar alterações"} />
                <div onClick={closeModal}>
                  <Link>Cancelar</Link>
                </div>
              </div>
            </FormContainer>
          )}
        </Formik>
      </Content>
    </Container>
  );
}
