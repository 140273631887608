import styled from "styled-components";
import { device } from "../../styles/device";

export const Container = styled.div`
    padding-bottom: 21px;
    border-bottom: 1px solid ${props => props.theme.colors.neutral100};
    margin-bottom: 23px;
    
    h2{
        font-size: 20px;
        font-weight: 700;
        color: ${props => props.theme.colors.neutral900};
        margin-bottom: 11px;
    }
`;

export const Options = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    .line {
        display: flex;
        flex-direction: column;
        gap: 17px;

        @media ${device.tablet} {
            flex-direction: row;
            margin-right: 21px;
            max-width: none;
        }
    }

    .choose-address-btn {
        align-self: center;
        display: flex;
        gap: 6px;
        background: transparent;
        align-items: center;
        border: none;
        color: ${props => props.theme.colors.primary};
        font-size: 14px;
        font-weight: 700;
    }
`;

export const PickupContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const Card = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    border: 1px solid ${props => props.selected ? props.theme.colors.primary : props.theme.colors.gray};
    border-radius: 4px;
    padding: 11px 18px;
    max-width: 363px;

    .info {
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-items: center;
        p {
            font-size: 14px;
            font-weight: ${props => props.selected ? 700 : 500};
            color: ${props => props.selected ? props.theme.colors.primary : props.theme.colors.neutral900};
        }
    }

    :hover {
        border: 1px solid ${props => props.theme.colors.primary};
      }
`;