import React from "react";
import { Container } from "./styles";

export default function Radio({ id, name, checked, value, onChange, onBlur, label }) {
    return (
        <Container className={`radio ${checked ? 'selected' : ''}`}>
            <input id={id} value={value}
                onChange={onChange}
                onBlur={onBlur}
                name={name}
                type="radio"
                checked={checked}
            />
            <label htmlFor={id} className="radio-label">{label}</label>
        </Container>
    )
}