import React from "react";
import { useSelector } from "react-redux";
import packageJson from "../../../package.json";
import compraSeguraBlindado from "../../assets/images/comprasegura-blindado.png";
import compraSeguraGoogle from "../../assets/images/comprasegura-google.png";
import compraSeguraNorton from "../../assets/images/comprasegura-norton.png";
import { Container } from "./styles";
import { formatCNPJ } from "../../util/formatCNPJ";
import { formatCEP } from "../../util/formatCEP";
import { formatPhone } from "../../util/formatPhone";

export default function FooterCompact() {
  const { data } = useSelector(state => state.store);
  return (
    <Container>
      <div className="footer-pags">
        <div>
          <div className="payment">
            <strong>Formas de pagamento</strong>
            {data?.empresa?.plataforma_pagamento?.nome && data?.empresa?.plataforma_pagamento?.nome !== 'undefined' && (
              <ul>
                <li>
                  <img
                    src={`${process.env.REACT_APP_API_URL}/files/${data?.empresa?.plataforma_pagamento?.nome}.visa.svg`}
                    alt="visa"
                    title="Visa"
                  />
                </li>
                <li>
                  <img
                    src={`${process.env.REACT_APP_API_URL}/files/${data?.empresa?.plataforma_pagamento?.nome}.mastercard.svg`}
                    alt="mastercard"
                    title="Mastercard"
                  />
                </li>
                <li>
                  <img
                    src={`${process.env.REACT_APP_API_URL}/files/${data?.empresa?.plataforma_pagamento?.nome}.elo.svg`}
                    alt="elo"
                    title="Elo"
                  />
                </li>
                <li>
                  <img
                    src={`${process.env.REACT_APP_API_URL}/files/${data?.empresa?.plataforma_pagamento?.nome}.amex.svg`}
                    alt="amex"
                    title="Amex"
                  />
                </li>
              </ul>
            )}
          </div>
        </div>
        <div>
          <strong>Compra segura!</strong>
          <ul>
            <li>
              <img
                src={compraSeguraGoogle}
                alt="Compra segura"
                className="selo"
              />
            </li>
            <li>
              <img
                src={compraSeguraBlindado}
                alt="Compra segura"
                className="selo"
              />
            </li>
            <li>
              <img
                src={compraSeguraNorton}
                alt="Compra segura"
                className="selo"
              />
            </li>
          </ul>
        </div>
        <div>
          <div className="logo-policy">
            <div className="logo">
              <img
                src={`${process.env.REACT_APP_API_URL}/companies/images/${data?.empresa?.url}/logos/logo.png`}
                alt={`${data?.empresa?.nome}`}
              />
            </div>
            <div className="policy">
              <p>
                © 2023{" "} {data?.empresa?.nome}
                <br />
                Todos os direitos reservados - v.{packageJson.version}
              </p>
            </div>
          </div>
        </div>
      </div>
      {data && (
        <div className="footer-general-information">
          <p>{data.razao_social} | {formatCNPJ(data.cnpj)} | {data.endereco}, {data.endereco_numero} - {formatCEP(data.cep)}  | {data.bairro} | {data.cidade} {data.estado} | Para dúvidas, elogios e reclamações acesse nossa Central de Atendimento no Whatsapp | {formatPhone(data.telefone)} | Farmacêutico responsável: {data.farmaceutico_nome} | CRF {data.farmaceutico_inscricao} | AFE: {data.caminho_afe} | As informações contidas neste site não devem ser usadas para automedicação e não substituem, em hipótese alguma, as orientações dadas pelo profissional da área médica. Somente o médico está apto a diagnosticar qualquer problema de saúde e prescrever o tratamento adequado. Ao persistirem os sintomas, um médico deverá ser consultado. Os preços e promoções divulgados no site são válidos apenas para compras feitas pela internet. Maiores esclarecimentos, consultar o site: www.anvisa.gov.br . Todos os pedidos efetuados estão sujeitos à confirmação da disponibilidade de produto em nosso estoque.</p>
        </div>
      )}
    </Container>
  );
}
