import { darken } from "polished";
import styled from "styled-components";
import { device } from "../../styles/device";

export const Center = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
`;

export const CategoryContainer = styled.div`
  padding: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  p {
    font-weight: 700;
    text-transform: uppercase;
    margin-left: 10px;
    color: ${(props) => props.theme.colors.primary};
    text-align: center;

    -webkit-touch-callout: none; /* iPhone OS, Safari */
    -webkit-user-select: none; /* Chrome, Safari 3 */
    -khtml-user-select: none; /* Safari 2 */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    user-select: none;
  }

  @media (max-width: 600px) {
    padding: 0;

    p {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  svg {
    color: ${(props) => props.theme.colors.primary};
    animation: fadein 0.7s;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const CategoryModalContainer = styled.nav`
  height: 100vh;
  width: 100%;
  display: flex;
  position: fixed;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  overflow-y: auto;
  z-index: 1000;
`;

export const ModalContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  min-height: 445px;
  background: #fff;
  border-radius: 8px;
  position: fixed;
  padding: 32px 64px 32px 64px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.36);
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`;

export const ModalMenu = styled.div`
  width: 100%;
  max-width: 670px;
  min-height: 350px;
  display: none;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 10px 15px -5px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 32px 6px 14px 6px;
  justify-content: flex-start;
  flex-direction: row;
  margin-left: -20px;
  margin-top: 4px;
  background: #f7f7f7;

  a {
    text-align: left;
  }
`;

export const LeftSideCategories = styled.ul`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 200px;
  width: 100%;
  height: 100%;
  min-height: 460px;
  border-right: 1px solid #eaeaea;
  list-style: none;

  a {
    font-size: 18px;
    line-height: 32px;
    color: #909090;
    text-transform: uppercase;
    text-decoration: none;
    padding: 0 15px;
    display: block;
    margin: 4px 0 4px 0;
  }
`;

export const RightSideCategories = styled.ul`
  list-style: none;
  width: 100%;

  li {
    margin: 12px 12px;
    height: auto;
    display: inline-flex;
  }
`;

export const CategoriesMobile = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 12px;
  flex-direction: column;
  width: 100%;
  height: 100%;
  list-style: none;

  max-height: 600px;
  overflow: auto;

  #category {
    font-size: 18px;
    line-height: 32px;
    border-bottom: 1px solid #eaeaea;
    color: #909090;
    text-transform: uppercase;
    text-decoration: none;
    margin: 12px;
    display: block;
  }
`;

export const ListSubcategoriesMobile = styled.a`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-left: 24px;
  padding: 12px;
`;

export const Signed = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    display: none;
  }

  strong {
    span {
      font-weight: 400;
    }
    font-size: 1.7rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.4rem;
`;

export const Menu = styled.header`
  display: ${(props) => (props.hidden ? "none" : "block")};
  position: relative;
  top: 0px;
  width: 100%;
  background: ${(props) => props.theme.colors.background};
  transition: 0.4s;
  z-index: 2000;
  padding-top: 19px;
  padding-bottom: 2px;
  border-bottom: 1px solid var(--neutral-50, #f3f3f4);
  background: #fff;
  margin-bottom: 24px;

  .menu-btn-abrir {
    display: none;
    gap: 4px;
    span {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      img {
        width: 50px;
      }
      h2 {
        color: var(--neutral-900, #151922);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
    @media (max-width: 768px) {
      display: flex;
    }
  }

  .menu-categorias{
    max-width: 1440px;
    width: 100%;
    margin-top: 25px;

    .menu-topo {
      display: none;
    }

    @media (max-width: 768px) {
      margin: 0;
      position: fixed;
      background-color: white;
      top: 0px;
      left: -100%;
      width: 100%;
      height: 100%;
      z-index: 11;
      overflow-y: auto;
      transition: left 0.3s;

      .menu-topo {
        display: flex;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 20px;

        h2 {
          color: #151922;
        }
        img {
          width: 36px;
        }
      }
      ul {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 20px;
      }
    }
  }

  .menu-mobile {
    @media (max-width: 768px) {
      position: fixed;
      background-color: white;
      top: 0px;
      left: -100%;
      width: 100%;
      height: 100%;
      z-index: 11;
      overflow-y: auto;
      transition: left 0.3s;
    }
  }

  .open-modal-mobile {
    left: 0px;
  }

  img {
    width: 50px;
    transition: 0.4s;
  }

  ${Center} {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  #menu {
    svg {
      color: ${(props) => props.theme.colors.primary};
    }
    display: none;
    margin: 0 20px 0 10px;
    background: transparent;
    border: none;
  }

  @media (max-width: 600px) {
    #menu {
      margin: 0 10px 0 10px;
    }
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .point {
      background: #f35c19;
      border-radius: 50%;
      width: 10px;
      height: 10px;

      position: absolute;
      top: 30px;
      margin-left: 6px;

      @media (max-width: 900px) {
        top: 16px;
        margin-top: 4px;
        margin-left: 8px;
      }
    }

    a {
      img {
        width: 134px;

        /* @media (max-width: 900px) {
          width: 100%;
        }

        @media (max-width: 660px) {
          display: none;
        } */
      }
    }
  }

  @media (max-width: 900px) {
    padding-left: 16px;
    padding-right: 16px;

    #menu svg {
      color: ${(props) => props.theme.colors.primary};
    }

    #menu {
      /* display: flex; */
      margin: 0 20px 0 10px;
    }

    @media (max-width: 600px) {
      #menu {
        margin: 0 10px 0 10px;
      }
    }

    /* img {
      width: 120px;
    } */
  }
`;

export const MenuUl = styled.div`
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  gap: 5px;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    margin-top: 20px;
    padding: 8px;
    gap: 15px;

    .menu-top-sub-item {
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 10px 20px 0px 30px;

      h2 {
        color: #151922;
      }
      img {
        width: 36px;
      }
    }

  }

  a {
    color: ${(props) => props.theme.colors.neutral900};
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    padding: 10px 0;

    &:hover {
      cursor: pointer;
    }
  }

    .link-sub-item {
      @media (max-width: 768px) {
        padding: 16px 24px;
      }
    }

  @media ${device.laptop} {
    padding: 10px 0;
  }

  @media (max-width: 768px) {
    width: 100%;

    .img-back-mobile {
      width: 0.625em;
    }

    .link-back-mobile {
      align-items: center;
      display: flex;
      gap: 13px;
      padding: 16px 24px;
    }

    .link-see-all {
      color: ${(props) => props.theme.colors.primary};
      font-size: 14px;
      font-weight: normal;
    }
    .li-back-mobile {
      background-color: ${(props) => props.theme.colors.neutral50};
      border: 1px solid rgb(237 237 237);
      width: 100%;
    }
  }


    @media (min-width: 1024px) {
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 10px;
    }

    .align-left {
      left: unset;
    }

    .align-right {

      @media (min-width: 900px) {
        left: calc(100% - 66vw);
        /* margin-top: 38px; */
      }
      @media (min-width: 1280px) {
        left: calc(100% - 53vw);
      }
      @media (min-width: 1370px) {
        left: calc(100% - 47vw);
        margin-left: -38px;
      }
      @media (min-width: 1440px) {
        left: calc(100% - 46vw);
      }
  }
  .align-left{
    @media (min-width: 1024px) {
        /* margin-top: 38px; */
      }
  }

.dropdown {
    padding: 10px 20px;
    text-align: center;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

  a {
    width: 100%;
    cursor: pointer;
  }

  @media (min-width: 900px) {
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.12) 0px 10px 15px -5px;
      background-color: #f7f7f7;
    }
  }

  &:hover .dropdown-content {
    display: flex;
  }
}

  .negrito {
    font-weight: 700;
  }
`;

export const SubMenuMobile = styled.div`
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: stretch;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    margin-top: 20px;

    .menu-top-sub-item {
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 10px 20px 0px 30px;

      h2 {
        color: #151922;
      }
      img {
        width: 36px;
      }
    }
  }

  a {
    color: ${(props) => props.theme.colors.neutral900};
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    padding: 10px 0;

    &:hover {
      cursor: pointer;
    }
  }

    .link-sub-item {
      @media (max-width: 768px) {
        padding: 16px 24px;
      }
    }

  @media ${device.laptop} {
    padding: 10px 0;
  }

  @media (max-width: 768px) {
    width: 100%;

    .img-back-mobile {
      width: 0.625em;
    }

    .link-back-mobile {
      align-items: center;
      display: flex;
      gap: 13px;
      padding: 16px 24px;
    }

    .link-see-all {
      color: ${(props) => props.theme.colors.primary};
      font-size: 14px;
      font-weight: normal;
    }
    .li-back-mobile {
      background-color: ${(props) => props.theme.colors.neutral50};
      border: 1px solid rgb(237 237 237);
      width: 100%;
    }
  }

.dropdown {
  text-align: left;
  a {
    cursor: pointer;
  }
}

  .negrito {
    font-weight: 700;
  }
`;

export const Navigation = styled.nav`
  display: flex;
  justify-content: stretch;
  align-items: center;
  flex-wrap: nowrap;
  gap: 2px;
  min-width: 240px;

  a {
    align-self: center;
  }

  @media (max-width: 900px) {
    justify-content: flex-end;
    padding: 0 45px;
    min-width: 0px;
  }

  @media (max-width: 1370px) {
    padding: 0 17px;
  }

  @media (max-width: 600px) {
    padding: 0;
  }
`;

export const LoginButton = styled.div`
  display: flex;

  > a {
    margin-right: 4px;
    display: flex;
    background: #fdfdfd;
    color: #7a797a;
    align-items: center;
    padding: 1.2rem;
    border-radius: 50px;
    font-weight: 500;
    font-size: 1.6rem;
    transition: 0.2s all;
  }

  > a:hover {
    background: #ebebeb;
  }

  > a > svg {
    color: rgba(122, 121, 122, 1);
    margin-right: 0.5rem;
    font-size: 2.6rem;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

export const User = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex: 1;
  flex-direction: row;
  font-size: 14px;
  gap: 10px;

  .user-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 120px;
    > a {
      font-size: 14px;
      font-style: italic;
      color: ${(props) => props.theme.colors.bodyLight};
      display: block;
      width: 50px;
    }
  }

  @media (max-width: 900px) {
    .user-text {
      display: none;
    }
  }
`;

export const Cart = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > a {
    margin-right: 8px;
    display: flex;
    border-radius: 50px;
    transition: background 0.3s, color 0.3s;
    position: relative;

    :hover {
      background: #ebebeb;
    }

    > svg {
      color: ${(props) => props.theme.colors.bodyLight};
    }

    > span {
      background: #143c97;
      align-items: center;
      justify-content: center;
      display: flex;
      color: #fff;
      border-radius: 50px;
      width: 19px;
      height: 19px;
      right: -2px;
      top: -2px;
      position: absolute;
      font-size: 12px;
      font-weight: 500;
      border: solid 1px #fff;
    }
  }

  .amount {
    > b {
      font-weight: 500;
      font-size: 1.6rem;
      padding: 0.2rem 0;
      color: ${(props) => props.theme.colors.primary};
    }
  }

  @media (max-width: 900px) {
    > a {
      padding: 1rem;
      > span {
        top: 1px;
        right: 1px;
      }
    }
  }

  @media (max-width: 600px) {
    > a {
      padding: 0.75rem;
    }
  }
`;

export const ToggleSearch = styled.button`
  margin-right: 8px;
  display: flex;
  border: none;
  background: #fdfdfd;
  padding: 1rem;
  border-radius: 50px;
  transition: background 0.3s, color 0.2s;
  position: relative;

  :hover {
    background: #ebebeb;
  }

  > svg {
    color: ${(props) => props.theme.colors.bodyLight};
    font-size: 2.6rem;
  }

  > span {
    background: ${(props) => props.theme.colors.secondary};
    align-items: center;
    justify-content: center;
    display: flex;
    color: #fff;
    border-radius: 50px;
    width: 21px;
    height: 21px;
    right: 5px;
    top: 5px;
    position: absolute;
    font-size: 1.3rem;
    font-weight: 500;
  }

  @media (max-width: 600px) {
    padding: 0.75rem;
  }

  @media (min-width: 900px) {
    display: none;
  }
`;

export const SearchForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin-left: 70px;
  margin-right: 84px;
  flex-direction: column;

  .search-container {
    position: relative;
    width: 100%;
    border: 1px solid #c1c1c1;
    display: flex;
    align-items: center;
    height: 50px;
    border-radius: 8px;
    border: 1px solid var(--neutral-200, #d0d1d3);
    padding-left: 24px;
    padding-right: 24px;

    input {
      width: 100%;
      border: none;
      outline: none;
      color: #666666;
      border-radius: 4px 0 0 4px;
      margin-left: 15px;
      font-size: 16px;
      padding-right: 10px;
      background-color: #ffffff;

      [type="search"]::-webkit-search-decoration {
        -webkit-appearance: none;
      }
    }

    img {
      outline: none;
      border: none;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 4px 4px 0;
      width: 24px;

      svg {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
      }
    }
  }

  @media (max-width: 600px) {
    padding: 0;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

export const Suggest = styled.ul`
  max-height: 600px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: -1px;
  top: 40px;
  padding-top: 10px;
  background: #fafafa;
  width: calc(100% + 2px);
  list-style: none;
  border: 1px solid #c1c1c1;
  border-top: 0;
  z-index: 2000 !important;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.primary};
    border-radius: 4px;
  }

  .li-recent {
    font-size: 13px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .span-recent {
    padding-left: 15px !important;
    font-size: 11px !important;
    opacity: 0.6 !important;
    display: flex;
    height: 18px;
    align-items: center;
  }

  li {
    font-size: 1.6rem;
    padding: 0.8rem 0.7rem 0.8rem 1.6rem;
    color: #666666;
    cursor: pointer;
    background: #fafafa;
    justify-content: space-between;
    display: flex;
    align-items: center;

    span {
      width: 85%;
    }

    .span-desc {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .lowerCase {
      text-transform: lowercase;
    }

    svg {
      color: #dddddd;
      font-size: 2.2rem;
    }

    :hover {
      background: ${darken(0.05, "#fff")};
    }

    .old-price {
      font-size: 12px;
      font-weight: 500;
      text-decoration: line-through;
    }

    .price {
      color: var(--neutral-900, #151922);
      font-family: Red Hat Display;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
`;

export const Helper = styled.div`
  display: none;
  height: 85px;

  @media (max-width: 900px) {
    top: 41px;
    height: ${(props) => (props.visible ? "125px" : "65px")};
  }
`;

export const ContainerSearchMobile = styled.div`
  background-color: #fff;
  margin-top: 15px;
  display: none;
  @media (max-width: 900px) {
    display: flex;
    transition: height 0.2s ease-out;
  }
`;

export const MobileSearchForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: stretch;
  background: #fff;
  width: 100%;

  .search-container {
    position: relative;
    width: 100%;
    border: 1px solid #c1c1c1;
    display: flex;
    align-items: center;
    height: 50px;
    border-radius: 24px;
    padding-left: 24px;
    padding-right: 24px;

    input {
      width: 100%;
      border: none;
      outline: none;
      color: #666666;
      border-radius: 4px 0 0 4px;
      margin-left: 15px;
      font-size: 16px;
      padding-right: 10px;
      background-color: #ffffff;

      [type="search"]::-webkit-search-decoration {
        -webkit-appearance: none;
      }
    }

    img {
      outline: none;
      border: none;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 4px 4px 0;
      width: 24px;

      svg {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .hide {
    max-height: 0;
    pointer-events: none;
  }
`;

export const RightSide = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  display: none;
`;

export const RightSideItem = styled.ul`
  list-style: none;
  display: none;
  justify-content: flex-start;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 6px;
  width: 275px;
  position: absolute;
  left: 230px;
  top: 31px;
  background: #f1f1f1;
  height: calc(100% - 44px);
  border-radius: 6px;

  a {
    font-size: 14px;
    line-height: 32px;
    color: ${(props) => props.theme.colors.neutral900};
    text-decoration: none;
    padding: 0 15px;
    display: block;
    margin: 4px 0 4px 0;
    &:hover {
      cursor: pointer;
      background-color: #eee;
    }
  }

  .link-see-all {
    color: ${(props) => props.theme.colors.primary};
    font-size: 14px;
    font-weight: normal;
  }
`;

export const RightSideImage = styled.div`
  display: flex;
`;

export const ContainerImage = styled.div`
  cursor: pointer;
  border-radius: 8px;
  -ms-flex-item-align: end;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  max-width: 200px;
`;

export const Image = styled.img`
  object-fit: cover;
  border-radius: 8px;
`;

export const SubMenuArea = styled.div`
  display: flex;
  width: 100%;
`;

export const LeftSideSubMenu = styled.ul`
  display: flex;
  align-items: normal;
  justify-content: stretch;
  flex-direction: column;
  max-width: 238px;
  width: 100%;
  list-style: none;
  padding-right: 12px;

  li {
    &:hover{
      background-color: #f1f1f1;
      border-radius: 6px 0px 0px 6px;
      ul {
        display: flex;
      }
    }
  }

  a {
    font-size: 14px;
    line-height: 32px;
    color: ${props => props.theme.colors.neutral900};
    text-decoration: none;
    padding: 0 15px;
    display: block;
    margin: 4px 0 4px 0;
    &:hover{
        cursor: pointer;
        background-color: #eee;
      }
  }

  .link-see-all {
    color: ${props => props.theme.colors.primary};
    font-size: 14px;
    font-weight: normal;
  }
`;

export const ContainerPromo = styled.div`
  background-color: ${(props) => props.theme.colors.promotion};
  border-radius: 0px 8px 0px 8px;
  margin-top: 3px;
  padding: 4px 4px;
  width: 75px;
  color: white;
  font-size: 12px;
  text-align: center;

  span {
    font-size: 14px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.white};
  }
`;

export const ImageProduct = styled.img`
  width: 75px !important;
  height: 75px;
  margin-right: 10px;
`;
