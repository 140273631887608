export const Types = {
  SEARCH_PRODUCTS_REQUEST: "search/SEARCH_PRODUCTS_REQUEST",
  SEARCH_PRODUCTS_SUCCESS: "search/SEARCH_PRODUCTS_SUCCESS",
  SEARCH_PRODUCTS_FAILURE: "search/SEARCH_PRODUCTS_FAILURE",
  LOAD_MORE_REQUEST: "search/LOAD_MORE_REQUEST",
  SET_QUANTITY_FOUND_PRODUCTS_SUCCESS:
    "search/SET_QUANTITY_FOUND_PRODUCTS_SUCCESS",
  SET_SEARCH_TERM: "search/SET_SEARCH_TERM",
  SET_FILTER_TERMS: "search/SET_FILTER_TERMS"
};

const INITIAL_STATE = {
  productsFound: [],
  loading: true,
  offset: 0,
  limit: 20,
  searchTerm: "",
  suggestions: [],
  filters: [],
  hasMore: true,
  error: null,
  category: "",
  subcategory: "",
  totalizadorGeral: 0,
  resetCategory: false
};

export default function search(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SEARCH_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
        searchTerm: action.payload.searchTerm,
        error: null,
        filters: action.payload.filters,
        category: action.payload.category,
        subcategory: action.payload.subcategory,
        offset: INITIAL_STATE.offset,
        limit: INITIAL_STATE.limit,
        productsFound: [],
        resetCategory: false
      };
    case Types.LOAD_MORE_REQUEST:
      return {
        ...state,
        loading: true,
        filters: action.payload.filters,
        category: action.payload.category,
        subcategory: action.payload.subcategory,
        offset: action.payload.offset,
        limit: action.payload.limit,
        resetCategory: false
      };
    case Types.SEARCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        searchTerm: "",
        error: null,
        filters: action.payload.filters,
        category: action.payload.category,
        subcategory: action.payload.subcategory,
        loading: false,
        productsFound: [
          ...action.payload.productsFound
        ],
        totalizadorGeral: action.payload.totalizadorGeral,
        offset:
          state.productsFound.length + action.payload.productsFound.length + 1,
        hasMore: action.payload.productsFound.length ? true : false,
        resetCategory: false
      };
    case Types.SEARCH_PRODUCTS_FAILURE:
      return { error: action.payload.message, productsFound: [] };
    case Types.SET_QUANTITY_FOUND_PRODUCTS_SUCCESS:
      return {
        ...state,
        productsFound: action.payload.productsFound
      };
    case Types.SET_SEARCH_TERM:
      return {
        ...state, searchTerm:
        action.payload.searchTerm
      };
    case Types.SET_FILTER_TERMS:
      return {
        ...state,
        searchTerm: action.payload.searchTerm,
        filters: action.payload.filters,
        category: action.payload.category,
        subcategory: action.payload.subcategory,
        offset: INITIAL_STATE.offset,
        limit: INITIAL_STATE.limit,
        productsFound: [],
        loading: true,
        error: null,
        totalizadorGeral: INITIAL_STATE.totalizadorGeral,
        resetCategory: action.payload.resetCategory
      };
    default:
      return state;
  }
}

export const Creators = {
  searchProductsRequest: searchTerm => ({
    type: Types.SEARCH_PRODUCTS_REQUEST,
    payload: { searchTerm }
  }),

  loadMoreRequest: (searchTerm, filters, searchOrdering, category, subcategory, offset, limit) => ({
    type: Types.LOAD_MORE_REQUEST,
    payload: { searchTerm,filters,searchOrdering, category, subcategory, offset, limit }
  }),

  setSearchTerm: searchTerm => ({
    type: Types.SET_SEARCH_TERM,
    payload: { searchTerm }
  }),

  setSearchFilters: (filters, searchTerm, searchOrdering, category, subcategory, resetCategory) => ({
    type: Types.SET_FILTER_TERMS,
    payload: { filters, searchTerm, searchOrdering, category, subcategory, resetCategory }
  }),

  searchProductsSuccess: (productsFound, totalizadorGeral) => ({
    type: Types.SEARCH_PRODUCTS_SUCCESS,
    payload: { productsFound, totalizadorGeral }
  }),

  searchProductsFailure: message => ({
    type: Types.SEARCH_PRODUCTS_FAILURE,
    payload: { message }
  }),

  setQuantityProductsFoundSuccess: productsFound => ({
    type: Types.SET_QUANTITY_FOUND_PRODUCTS_SUCCESS,
    payload: { productsFound }
  })
};
