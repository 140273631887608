import React, { useEffect, useState } from "react";
import { Card, Container, Options, PickupContainer } from "./styles";
import LoaderAsync from "../LoaderAsync";
import api from "../../services/api";
import { toast } from "react-toastify";
import Radio from "../Radio";
import { formatPrice } from "../../util/format";
import { DeliveryType } from "../../util/deliveryType";
import StoreCard from "../StoreCard";
import { FiEdit2 } from "react-icons/fi";
export default function DeliveryOptionPicker({ customerAddressId, cart, store, mainOptionDelivery, setMainOptionDelivery, showOverlay, showPickupModal, responsibleWithdrawal, deliveryMultiplicationFactor }) {
  const [loading, setLoading] = useState(false);
  const [storeForDelivery, setStoreForDelivery] = useState(null);

  useEffect(() => {
    getDeliveryOptions(customerAddressId, cart, store)
  }, [customerAddressId, cart, store])

  async function getDeliveryOptions(customerAddressId, cart, store) {
    if (customerAddressId && cart) {
      try {
        setLoading(true);
        showOverlay(true);
        clearOptions();
        const products = getProductsInCart(cart.data);
        const { data } = await api.post(`/stores/customerAddress/${customerAddressId}`, { products: products, storeIdPrimary: store?.id })
        if (data) {
          setStoreForDelivery({
            ...data
          });
        }
      } catch (e) {
        toast.error(e.response?.data?.user || 'Erro ao obter opções de entrega');
      } finally {
        setLoading(false);
        showOverlay(false);
      }
    }
  }

  function clearOptions() {
    setStoreForDelivery(null);
    setMainOptionDelivery(null);
  }

  function getProductsInCart(updatedCart) {
    if (updatedCart) {
      return updatedCart.map(item => {
        return {
          productId: item.id,
          amount: item.quantidade
        }
      });
    } else {
      return cart.map(item => {
        return {
          productId: item.id,
          amount: item.quantidade
        }
      });
    }
  }

  function onClickPickupStore(e) {
    e.preventDefault();
    showPickupModal();
  }

  return (
    <Container>
      <h2>Escolha uma opção de entrega</h2>
      {loading && (
        <LoaderAsync message="Carregando mais opções de entrega..." />
      )}
      <Options>
        <div className="line">
          {storeForDelivery && (
            <Card selected={mainOptionDelivery?.tipoEntrega === DeliveryType.RECEBER_EM_CASA}>
              <Radio id={DeliveryType.RECEBER_EM_CASA} value={DeliveryType.RECEBER_EM_CASA} name={`deliveryStore-${DeliveryType.RECEBER_EM_CASA}`} checked={mainOptionDelivery?.tipoEntrega === DeliveryType.RECEBER_EM_CASA} onChange={() => setMainOptionDelivery(storeForDelivery)} onBlur={() => setMainOptionDelivery(storeForDelivery)} />
              <div className="info">
                <p className="description">Receba no seu endereço</p>
                <p className="price">{formatPrice(parseFloat((storeForDelivery?.local_entrega?.custo_entrega || storeForDelivery?.custo_entrega) * deliveryMultiplicationFactor))}</p>
              </div>
            </Card>
          )}
          {mainOptionDelivery?.tipoEntrega === DeliveryType.RETIRAR_NA_LOJA ? (
            <StoreCard
              name="delivery-option-picker"
              store={{
                storeId: mainOptionDelivery.id,
                storeName: mainOptionDelivery.nome_fantasia,
                address: {
                  street: mainOptionDelivery.endereco,
                  number: mainOptionDelivery.endereco_numero,
                  neighborhood: mainOptionDelivery.bairro
                },
                expectedDate: {
                  startTime: mainOptionDelivery.lojaHorario.hora_inicio,
                  endTime: mainOptionDelivery.lojaHorario.hora_fim,
                  availabilityDate: mainOptionDelivery.lojaHorario.data_disponibilidade,
                  timeToReceive: mainOptionDelivery.lojaHorario.tempo_para_retirada
                }
              }}
              responsibleWithdrawal={responsibleWithdrawal ? {
                withdrawalByThird: responsibleWithdrawal?.retirada_terceiro,
                fullName: responsibleWithdrawal?.nome_completo,
                cpf: responsibleWithdrawal?.cpf,
                phone: responsibleWithdrawal?.telefone_contato,
              } : null}
              selectedStore={true}
            />
          ) : (
            <Card selected={mainOptionDelivery?.tipoEntrega === DeliveryType.RETIRAR_NA_LOJA}>
              <Radio id={DeliveryType.RETIRAR_NA_LOJA} value={DeliveryType.RETIRAR_NA_LOJA} name="deliveryStore" checked={mainOptionDelivery?.tipoEntrega === DeliveryType.RETIRAR_NA_LOJA} onChange={onClickPickupStore} />
              <div className="info">
                <p className="description">Retire na farmácia</p>
                <p className="price">Grátis</p>
              </div>
            </Card>
          )}
        </div>
        {mainOptionDelivery?.tipoEntrega === DeliveryType.RETIRAR_NA_LOJA && (
          <button className="choose-address-btn" onClick={onClickPickupStore}> <FiEdit2 size={14} /> Editar loja de retirada</button>
        )}
      </Options>
    </Container >
  );
}