import { DeliveryType } from "./deliveryType";

/**
 * @description Retorna a data no formato amigável
 * @param dateString data no padrão yyyy-MM-dd HH:mm-HH:mm
 */
export function getFriendlyDateForDeliveryType(dateString, deliveryType, pickupTime) {
    const [date, time] = dateString.split(" ");
    const [startTime, endTime] = time.split("-");

    const currentDate = new Date();
    const startTimeStore = new Date(`${date} ${startTime}`);
    const endTimeStore = new Date(`${date} ${endTime}`);

    let friendlyDay = "";
    const willOpenToday = currentDate < endTimeStore && currentDate.getDate() === endTimeStore.getDate() && currentDate.getMonth() === endTimeStore.getMonth() && currentDate.getFullYear() === endTimeStore.getFullYear();
    const willOpenTomorrow = currentDate.getDate() + 1 === endTimeStore.getDate() && currentDate.getMonth() === endTimeStore.getMonth() && currentDate.getFullYear() === endTimeStore.getFullYear();
    const isOpenNow = currentDate > startTimeStore && currentDate < endTimeStore;

    if (willOpenToday) {
        friendlyDay = "Hoje";
    }
    else if (willOpenTomorrow) {
        friendlyDay = "Amanhã";
    }
    else {
        if (getWeekNumber(currentDate) === getWeekNumber(startTimeStore)) {
            const daysOfWeek = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];
            friendlyDay = daysOfWeek[startTimeStore.getDay()];
        } else {
            const [year, month, day] = date.split("-");
            friendlyDay = `${day}/${month}/${year}`;
        }
    }

    let friendlyPreposition = isOpenNow ? (deliveryType === DeliveryType.RECEBER_EM_CASA ? "até às" : "em") : "à partir das";

    let friendlyTime = isOpenNow ? (deliveryType === DeliveryType.RECEBER_EM_CASA ? endTime : formatTimeout(pickupTime)) : startTime;
    return {
        friendlyDay,
        friendlyPreposition,
        friendlyTime
    };
}

function formatTimeout(timeout) {
    // Dividir o tempo no formato HH:MM:SS
    const [hours, minutes] = timeout.split(":").map(Number);

    let result = [];

    // Adicionar horas se for maior que 0
    if (hours > 0) {
        result.push(`${hours} ${hours === 1 ? 'hora' : 'horas'}`);
    }

    // Adicionar minutos se for maior que 0
    if (minutes > 0) {
        result.push(`${minutes} ${minutes === 1 ? 'minuto' : 'minutos'}`);
    }

    // Unir o array com "e"
    return result.join(" e ");
}

/**
 * @description Retorna a data no formato amigável em string
 * @param dateString data no padrão yyyy-MM-dd HH:mm-HH:mm
 */
export function getFriendlyDateForDeliveryTypeString(dateString, deliveryType, pickupTime) {
    const friendlyDate = getFriendlyDateForDeliveryType(dateString, deliveryType, pickupTime);
    return `${friendlyDate.friendlyDay} ${friendlyDate.friendlyPreposition} ${friendlyDate.friendlyTime}`;
}

/**
 * @description Retorna o número da semana do ano
 * @param {*} date data a ser verificada
 */
const getWeekNumber = (date) => {
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
    var yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    var weekNo = Math.ceil((((date - yearStart) / 86400000) + 1) / 7);
    return weekNo;
}