import produce from "immer";

export const Types = {
  GET_REQUEST: "category/GET_REQUEST",
  GET_SUCCESS: "category/GET_SUCCESS",
};

const INITIAL_STATE = {
  categories: [],
  loading: false,
};

export default function category(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_SUCCESS: {
        draft.categories = action.payload.categories;
        draft.loading = false;
        break;
      }
      default:
    }
  });
}

export const Creators = {
  getCategoriesRequest: () => ({
    type: Types.GET_REQUEST
  }),

  getCategoriesSuccess: categories => ({
    type: Types.GET_SUCCESS,
    payload: { categories }
  })
};
