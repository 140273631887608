/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useRef, useState } from "react";
import { FiMenu } from "react-icons/fi";
import { MdSearch } from "react-icons/md";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { ThemeContext } from "styled-components";
import imgMenu from "../../assets/images/Menu.svg";
import carrinhoCheio from "../../assets/images/carrinho-cheio.svg";
import carrinhoVazio from "../../assets/images/carrinho-vazio.svg";
import imgFechar from "../../assets/images/close-popup.svg";
import user from "../../assets/images/no-user.svg";
import searchSvg from "../../assets/images/search.svg";
import backSvg from "../../assets/images/back.svg";
import { Creators as SearchProductsActions } from "../../store/ducks/search";
import { Creators as SideMenuActions } from "../../store/ducks/sideMenu";
import { Creators as SuggestActions } from "../../store/ducks/suggest";
import HeaderPromotion from "../HeaderPromotion";
import SelectStoreHeader from "../SelectStoreHeader";
import SideMenu from "../SideMenu";
import {
  Cart,
  Center,
  ContainerSearchMobile,
  Helper,
  LoaderContainer,
  Menu,
  MobileSearchForm,
  Navigation,
  SearchForm,
  Signed,
  Suggest,
  User,
  SubMenuArea,
  LeftSideSubMenu,
  RightSideItem,
  MenuUl,
  ModalMenu,
  ContainerImage,
  Image,
  RightSideImage,
  SubMenuMobile,
  ContainerPromo,
  ImageProduct,
} from "./styles";
import { formatPrice } from "../../util/format";

export default function Header() {
  const history = useHistory();
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();
  const suggestRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const { suggestions } = useSelector((state) => state.suggest);
  const { profile, loading: userLoading } = useSelector((state) => state.user);
  const { signed } = useSelector((state) => state.auth);
  const cartItems = useSelector((state) => state.cart.data);
  const headerRef = useRef(null);
  const { searchTerm: searchTermReducer } = useSelector(
    (state) => state.search
  );
  const { data } = useSelector((state) => state.store);
  const { data: menusModel } = useSelector((state) => state.menu);
  const [showMenuMobile, setShowMenuMobile] = useState(false);
  const [showSubMenuMobile, setShowSubMenuMobile] = useState(false);
  const [showItemMobile, setShowItemMobile] = useState(false);
  const [menuSelected, setMenuSelected] = useState(null);
  const [subMenuSelected, setSubMenuSelected] = useState(null);
  const [recentSearches, setRecentSearches] = useState([]);

  useEffect(() => {
    window.onscroll = () => {
      if (suggestions.length === 0) {
        // scrollFunction();
      }
    };
  }, [suggestions.length]);

  useEffect(() => {
    window.addEventListener("storage", (e) => {
      if (document.visibilityState === "hidden") {
        window.location.reload(false);
      }
    });
  }, []);

  useEffect(() => {
    const savedSearches =
      JSON.parse(localStorage.getItem("@ConexaoGAM:searchTermLocally")) || [];

    setRecentSearches(
      savedSearches
        .filter(
          (search) => search.term.toLowerCase() !== searchTerm.toLowerCase()
        )
        .sort((a, b) => new Date(b.date) - new Date(a.date)) // Ordena por data decrescente
    );
  }, [suggestions]);

  function handleSuggestion(event, url = null) {
    if (url) {
      dispatch(SuggestActions.clearSuggestions());
      history.push("/produtos/visualizar/" + url);
      setSearchTerm("");
    }
    window.location.reload();
  }

  function handleSubmit(event, suggest = null, searchTermLocally = null) {
    event.preventDefault();
    let term = suggest !== null ? suggest : searchTerm;

    if (searchTermLocally !== null) {
      term = searchTermLocally;
    }

    saveSearchTermLocally(term);

    if (searchTermReducer !== "") {
      dispatch(SuggestActions.clearSuggestions());
      history.push("/produtos/buscar/" + term);
      setSearchTerm("");
    }
    window.location.reload();
  }

  function setSearchTermState(searchTerm) {
    if (searchTerm === "") {
      dispatch(SuggestActions.clearSuggestions());
    } else if (searchTerm?.length > 2) {
      dispatch(SearchProductsActions.setSearchTerm(searchTerm));
    }
  }

  function doSearch(evt) {
    const searchTerm = evt.target.value;
    setSearchTerm(searchTerm);

    setSearchTermState(searchTerm);
  }

  function saveSearchTermLocally(searchTerm) {
    if (searchTerm === undefined || searchTerm.length < 3) return;

    // Recupera as buscas existentes
    const existingSearches =
      JSON.parse(localStorage.getItem("@ConexaoGAM:searchTermLocally")) || [];

    // Verifica se o termo já existe
    const existingIndex = existingSearches.findIndex(
      (entry) => entry.term.toLowerCase() === searchTerm.toLowerCase()
    );

    if (existingIndex !== -1) {
      // Remove o termo duplicado para reordená-lo
      existingSearches.splice(existingIndex, 1);
    }

    // Cria um novo registro
    const searchEntry = {
      term: searchTerm,
      date: new Date().toISOString(),
    };

    // Adiciona o novo registro ao início do array
    existingSearches.push(searchEntry);

    // Remove os registros mais antigos, mantendo apenas os 6 mais recentes
    if (existingSearches.length > 3) {
      existingSearches.shift(); // Remove o primeiro (mais antigo)
    }

    // Salva o array atualizado no localStorage
    localStorage.setItem(
      "@ConexaoGAM:searchTermLocally",
      JSON.stringify(existingSearches)
    );
  }

  function getHighlightedText(suggest, higlight) {
    let parts = suggest.descricao.split(new RegExp(`(${higlight})`, "i"));
    return (
      <span className="span-desc">
        {" "}
        {parts.map((part, i) => (
          <span
            className="lowerCase"
            key={i}
            style={
              part.toLowerCase() === higlight.toLowerCase()
                ? { fontWeight: "500" }
                : {}
            }
          >
            {part}
          </span>
        ))}{" "}
        <p />
        <span>
          {suggest.percentual_desconto > 0 && (
            <span>
              <ContainerPromo>
                {parseFloat(suggest.percentual_desconto)
                  .toString()
                  .replace(".", ",")}
                % OFF
              </ContainerPromo>
              <p />
            </span>
          )}

          {suggest.preco_de !== suggest.preco_por && (
            <span>
              <span className="old-price">
                {" "}
                de: {formatPrice(suggest.preco_de)}
                <p />
              </span>
              <span className="price">
                por: {formatPrice(suggest.preco_por)}
              </span>
            </span>
          )}
          {suggest.preco_de === suggest.preco_por && (
            <span className="price">{formatPrice(suggest.preco_por)}</span>
          )}
        </span>
      </span>
    );
  }

  function searchMenu(menu, tipoMenu, event) {
    setSubMenuSelected(null);

    if (tipoMenu === "menu") {
      setMenuSelected(menu);
    }
    if (tipoMenu === "submenu") {
      setSubMenuSelected(menu);
    }
  }

  function clickMenu(menu) {
    setMenuSelected(null);

    if (showMenuMobile) closeAllMenusMobile();

    if (menu.tipo_link === "url") {
      window.open(menu.url_link, menu.tipo_abertura_link);
    } else if (menu.tipo_link === "categoria" && menu.categoria) {
      const path = `/produtos/categoria/${menu.categoria.descricao}/${menu.categoria.id}`;
      history.push(path);
    } else if (menu.tipo_link === "subcategoria" && menu.subcategoria) {
      const path = `/produtos/subcategoria/${menu.subcategoria.descricao}/${menu.subcategoria.id}`;
      history.push(path);
    }
  }

  function clickMenuMobile(menu) {
    if (menu.tipo === "menu") {
      setMenuSelected(menu);
      setShowSubMenuMobile(true);
    } else if (menu.tipo === "submenu") {
      setSubMenuSelected(menu);
      setShowItemMobile(true);
    } else {
      clickMenu(menu);
    }
  }

  function clickImage(menu) {
    if (
      menu.link_propaganda_imagem &&
      menu.tipo_abertura_link_propaganda_imagem
    ) {
      window.open(
        menu.link_propaganda_imagem,
        menu.tipo_abertura_link_propaganda_imagem
      );
    }
  }

  function closeAllMenusMobile() {
    setShowMenuMobile(false);
    setShowSubMenuMobile(false);
    setShowItemMobile(false);
  }

  function hasChildren(menu) {
    return menusModel.find((x) => x.pai_menu_id === menu.id) !== undefined;
  }

  return (
    <>
      <HeaderPromotion visible={false} />
      {/* <HeaderLocationDelivery /> */}
      <SelectStoreHeader />
      <Helper id="helper" visible={showSearch} />
      <Menu ref={headerRef} visible={showSearch} hidden={false}>
        <Center>
          <div className="menu-btn-abrir">
            <span
              onClick={() => {
                setShowMenuMobile(true);
              }}
            >
              <img src={imgMenu} alt={"Abrir"} />
            </span>
          </div>
          <div className="logo">
            <button
              onClick={() => {
                dispatch(SideMenuActions.toggleSideMenu("openWithButton"));
              }}
              id="menu"
            >
              <FiMenu size={30} />
            </button>
            <Link to="/">
              <img
                src={`${process.env.REACT_APP_API_URL}/companies/images/${data?.empresa?.url}/logos/logo.png`}
                title={data?.empresa?.nome}
                alt={`${data?.empresa?.nome}`}
              />
            </Link>
          </div>

          <SearchForm
            onSubmit={handleSubmit}
            openSuggest={suggestions.length > 0}
          >
            <div className="search-container">
              <img src={searchSvg} alt="Procurar" />
              <input
                type="search"
                value={searchTerm}
                placeholder="O que você procura?"
                name="search"
                onChange={(e) => doSearch(e)}
                autoComplete="off"
                inputMode="search"
              />
              <div className="div-custom-scroll">
                {suggestions.length > 0 && window.innerWidth >= 900 && (
                  <Suggest ref={suggestRef}>
                    {recentSearches.length > 0 && (
                      <span>
                        <span className="span-recent">Pesquisas recentes</span>
                        {recentSearches.map((search, index) => (
                          <li
                            className="li-recent"
                            key={index}
                            onClick={(e) => handleSubmit(e, null, search.term)}
                          >
                            {search.term}
                            <MdSearch />
                          </li>
                        ))}
                      </span>
                    )}
                    <br />
                    <span className="span-recent">Resultados da busca</span>
                    {suggestions &&
                      suggestions.map((suggest, index) => (
                        <li
                          key={index}
                          onClick={(e) => handleSuggestion(e, suggest.url)}
                        >
                          <ImageProduct
                            src={suggest.caminho_imagem}
                            alt={suggest.id}
                          />
                          {getHighlightedText(suggest, searchTerm)}
                          <MdSearch />
                        </li>
                      ))}
                  </Suggest>
                )}
              </div>
            </div>
          </SearchForm>

          <Navigation>
            <Cart id="carrinho">
              <Link to="/carrinho" title="Minha Cesta">
                {cartItems?.length > 0 ? (
                  <>
                    <img src={carrinhoCheio} width={50} alt={"Carrinho"}></img>
                    <span>{cartItems.length}</span>
                  </>
                ) : (
                  <img src={carrinhoVazio} width={50} alt={"Carrinho"}></img>
                )}
              </Link>
            </Cart>

            {!signed && !userLoading && (
              <User>
                <Link to="/entrar" title="Login">
                  <img src={user} alt="Usuário"></img>
                </Link>
                <Link to="/entrar" title="Login">
                  <div className="user-text">
                    <b>Olá,</b>
                    Faça o login
                  </div>
                </Link>
              </User>
            )}

            {signed && userLoading && (
              <Signed>
                <LoaderContainer>
                  <Loader
                    type="Oval"
                    color={theme.colors.primary}
                    height={16}
                    width={16}
                  />
                </LoaderContainer>
              </Signed>
            )}

            {signed && !userLoading && profile !== null && (
              <User>
                <Link to="/painel" title="Meus dados">
                  <img src={user} alt="Usuário"></img>
                </Link>
                <Link to="/painel" title="Meus dados">
                  <div className="user-text">
                    <b>Olá,</b>
                    {profile && profile.nome.includes(" ")
                      ? profile.nome.substring(0, profile.nome.indexOf(" "))
                      : profile.nome}
                  </div>
                </Link>
              </User>
            )}
            {/* <ToggleSearch onClick={() => toggleSearchBar()}>
            <MdSearch />
          </ToggleSearch> */}
          </Navigation>
        </Center>
        <ContainerSearchMobile>
          <MobileSearchForm
            openSuggest={suggestions.length > 0}
            onSubmit={handleSubmit}
            id="mobile-search-form"
          >
            <div className="search-container">
              <img src={searchSvg} alt="Procurar" />
              <input
                type="search"
                value={searchTerm}
                placeholder="O que você procura?"
                name="search-mobile"
                onChange={(e) => doSearch(e)}
                autoComplete="off"
                inputMode="search"
              />

              {suggestions.length > 0 && window.innerWidth <= 900 && (
                <Suggest ref={suggestRef}>
                  {recentSearches.length > 0 && (
                    <span>
                      <span className="span-recent">Pesquisas recentes</span>
                      {recentSearches.map((search, index) => (
                        <li
                          key={index}
                          onClick={(e) => handleSubmit(e, null, search.term)}
                        >
                          {search.term}
                          <MdSearch />
                        </li>
                      ))}
                    </span>
                  )}
                  <br />
                  <span className="span-recent">Resultados da busca</span>
                  {suggestions &&
                    suggestions.map((suggest, index) => (
                      <li
                        key={index}
                        onClick={(e) => handleSuggestion(e, suggest.url)}
                      >
                        <ImageProduct
                          src={suggest.caminho_imagem}
                          alt={suggest.id}
                        />
                        {getHighlightedText(suggest, searchTerm)}
                        <MdSearch />
                      </li>
                    ))}
                </Suggest>
              )}
            </div>
          </MobileSearchForm>
        </ContainerSearchMobile>
        <Center>
          <div
            className={`menu-categorias ${
              showMenuMobile ? "open-modal-mobile" : ""
            }`}
          >
            <div className="menu-topo">
              <h2>Menu</h2>
              <img
                src={imgFechar}
                alt={"Fechar"}
                onClick={closeAllMenusMobile}
              />
            </div>
            <MenuUl>
              {menusModel &&
                menusModel
                  .filter((menu) => menu.tipo === "menu")
                  .map((menu) => (
                    <div
                      id={menu.id}
                      className={`dropdown`}
                      onMouseEnter={(e) => {
                        if (showMenuMobile) return;
                        const menuItem = e.currentTarget;
                        const submenu =
                          menuItem.querySelector(".dropdown-content");

                        if (submenu) {
                          const rect = submenu.getBoundingClientRect();
                          const isOutOfBounds =
                            rect.right + 50 > window.innerWidth;
                          const parentRect = menuItem.getBoundingClientRect();
                          const distancia =
                            window.innerWidth -
                            (parentRect.left + parentRect.width);
                          if (isOutOfBounds) {
                            submenu.style.left = "auto";
                            submenu.style.right = distancia - 15 + "px";
                          }
                        }
                      }}
                    >
                      <a
                        onClick={() => {
                          if (showMenuMobile && hasChildren(menu))
                            clickMenuMobile(menu);
                          else clickMenu(menu);
                        }}
                      >
                        {menu.descricao}
                      </a>
                      {window.innerWidth >= 900 &&
                        menusModel.filter(
                          (subMenu) =>
                            subMenu.tipo === "submenu" &&
                            subMenu.pai_menu_id === menu.id
                        ).length > 0 && (
                          <ModalMenu className={`dropdown-content`}>
                            <SubMenuArea>
                              <LeftSideSubMenu>
                                {menusModel &&
                                  menusModel
                                    .filter(
                                      (subMenu) =>
                                        subMenu.tipo === "submenu" &&
                                        subMenu.pai_menu_id === menu.id
                                    )
                                    .map((subMenu, indexS) => (
                                      <>
                                        {indexS === 0 && (
                                          <li>
                                            <a
                                              className="link-see-all"
                                              onClick={() => clickMenu(menu)}
                                            >
                                              {"Ver Tudo em " + menu.descricao}
                                            </a>
                                          </li>
                                        )}
                                        <li>
                                          <a
                                            onClick={() => {
                                              if (showMenuMobile)
                                                clickMenuMobile(subMenu);
                                              else clickMenu(subMenu);
                                            }}
                                          >
                                            {subMenu.descricao}
                                          </a>
                                          {menusModel.filter(
                                            (item) =>
                                              item.tipo === "item" &&
                                              item.pai_menu_id === subMenu.id
                                          ).length > 0 && (
                                            <RightSideItem>
                                              {menusModel &&
                                                menusModel
                                                  .filter(
                                                    (item) =>
                                                      item.tipo === "item" &&
                                                      item.pai_menu_id ===
                                                        subMenu.id
                                                  )
                                                  .map((item, index) => (
                                                    <>
                                                      {index === 0 && (
                                                        <li>
                                                          <a
                                                            className="link-see-all"
                                                            onClick={() =>
                                                              clickMenu(subMenu)
                                                            }
                                                          >
                                                            {"Ver Tudo em " +
                                                              subMenu.descricao}
                                                          </a>
                                                        </li>
                                                      )}
                                                      <li>
                                                        <a
                                                          onClick={() => {
                                                            if (showMenuMobile)
                                                              clickMenuMobile(
                                                                item
                                                              );
                                                            else
                                                              clickMenu(item);
                                                          }}
                                                        >
                                                          {item.descricao}
                                                        </a>
                                                      </li>
                                                    </>
                                                  ))}
                                            </RightSideItem>
                                          )}
                                        </li>
                                      </>
                                    ))}
                              </LeftSideSubMenu>
                            </SubMenuArea>
                            <RightSideImage>
                              <ContainerImage>
                                {menu && menu.url_imagem_propaganda && (
                                  <a
                                    onClick={() => {
                                      clickImage(menu);
                                    }}
                                  >
                                    <Image
                                      src={menu.url_imagem_propaganda}
                                      alt={menu.descricao}
                                      style={{ width: "100%" }}
                                    />
                                  </a>
                                )}
                              </ContainerImage>
                            </RightSideImage>
                          </ModalMenu>
                        )}
                      {showMenuMobile && (
                        <div
                          className={`menu-mobile ${
                            showSubMenuMobile ? "open-modal-mobile" : ""
                          }`}
                        >
                          <div className="menu-top-sub-item">
                            <h2>{menuSelected?.descricao}</h2>
                            <img
                              src={imgFechar}
                              alt={"Fechar"}
                              onClick={closeAllMenusMobile}
                            />
                          </div>
                          <SubMenuMobile>
                            <li className="li-back-mobile">
                              <a
                                className="link-back-mobile"
                                onClick={() => {
                                  setShowSubMenuMobile(false);
                                }}
                              >
                                <img
                                  className="img-back-mobile"
                                  src={backSvg}
                                  alt={"Voltar"}
                                />
                                {"Voltar"}
                              </a>
                            </li>
                            {menusModel &&
                              menusModel.find(
                                (menu) =>
                                  menu.tipo === "submenu" &&
                                  menu.pai_menu_id === menuSelected?.id
                              ) && (
                                <li className="link-sub-item">
                                  <a
                                    className="link-see-all"
                                    onClick={() => clickMenu(menuSelected)}
                                  >
                                    {"Ver Tudo em " + menuSelected?.descricao}
                                  </a>
                                </li>
                              )}
                            {menusModel &&
                              menusModel
                                .filter(
                                  (menu) =>
                                    menu.tipo === "submenu" &&
                                    menu.pai_menu_id === menuSelected?.id
                                )
                                .map((menu) => (
                                  <li className="link-sub-item">
                                    <a
                                      onClick={() => {
                                        if (hasChildren(menu))
                                          clickMenuMobile(menu);
                                        else clickMenu(menu);
                                      }}
                                    >
                                      {menu.descricao}
                                    </a>
                                    {showMenuMobile && (
                                      <div
                                        className={`menu-mobile ${
                                          showItemMobile
                                            ? "open-modal-mobile"
                                            : ""
                                        }`}
                                      >
                                        <div className="menu-top-sub-item">
                                          <h2>{subMenuSelected?.descricao}</h2>
                                          <img
                                            src={imgFechar}
                                            alt={"Fechar"}
                                            onClick={closeAllMenusMobile}
                                          />
                                        </div>
                                        <SubMenuMobile>
                                          <li className="li-back-mobile">
                                            <a
                                              className="link-back-mobile"
                                              onClick={() => {
                                                setShowItemMobile(false);
                                              }}
                                            >
                                              <img
                                                className="img-back-mobile"
                                                src={backSvg}
                                                alt={"Voltar"}
                                              />
                                              {"Voltar"}
                                            </a>
                                          </li>
                                          {menusModel &&
                                            menusModel.find(
                                              (menu) =>
                                                menu.tipo === "item" &&
                                                menu.pai_menu_id ===
                                                  subMenuSelected?.id
                                            ) && (
                                              <li className="link-sub-item">
                                                <a
                                                  className="link-see-all"
                                                  onClick={() =>
                                                    clickMenu(subMenuSelected)
                                                  }
                                                >
                                                  {"Ver Tudo em " +
                                                    subMenuSelected?.descricao}
                                                </a>
                                              </li>
                                            )}
                                          {menusModel &&
                                            menusModel
                                              .filter(
                                                (menu) =>
                                                  menu.tipo === "item" &&
                                                  menu.pai_menu_id ===
                                                    subMenuSelected?.id
                                              )
                                              .map((menu) => (
                                                <li className="link-sub-item">
                                                  <a
                                                    onClick={() =>
                                                      clickMenu(menu)
                                                    }
                                                  >
                                                    {menu.descricao}
                                                  </a>
                                                </li>
                                              ))}
                                        </SubMenuMobile>
                                      </div>
                                    )}
                                  </li>
                                ))}
                          </SubMenuMobile>
                        </div>
                      )}
                    </div>
                  ))}
            </MenuUl>
          </div>
        </Center>
      </Menu>

      <SideMenu />
    </>
  );
}
