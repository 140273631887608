/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { Creators as SearchActions } from "../../store/ducks/search";
import {
  Container,
  ContainerButton,
  ContainerCheckFilter,
  ContainerCloseRound,
  ContainerFilters,
  ContainerInfoFilter,
  ContentFilter,
  ContainerFiltersSelected,
  Header,
  Title,
  InputField,
} from "./styles";

import { useDispatch, useSelector } from "react-redux";
import { OrdenationContext } from "../../contexts/OrdenationContext";
import api from "../../services/api";
import { useHistory } from "react-router-dom";
import { FilterContext } from "../../contexts/FilterContext";
import ButtonPrimary from "../ButtonPrimary";
import CloseRound from "../CloseRound";
import ButtonSecondary from "../ButtonSecondary";

export default function Dropdown({ params, isModal, setIsOpen }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const searchTerm = params.term || "";
  const store = useSelector((state) => state.storeByUser.data);
  const { productsFound } = useSelector((state) => state.search);
  const { ordenation } = useContext(OrdenationContext);
  const { setFilters } = useContext(FilterContext);
  const { resetCategory } = useSelector((state) => state.search);
  const [categoryReseted, setCategoryReseted] = useState(false);
  const [lastCategorySetted, setLastCategorySetted] = useState(params.ctgId);

  const itemsDefault = [
    {
      title: "Valores",
      val: [
        {
          label: "Promoção",
          key: "desconto",
          value: true,
          selected: false,
        },
        {
          label: "Leve mais pague menos",
          key: "leveMaisPagueMenos",
          value: true,
          selected: false,
        },
      ],
      visible: false,
      filterVisible: false,
    },
    {
      title: "Faixa de preço",
      val: [
        {
          index: 0,
          label: "Até R$ 49,99",
          key: "preco",
          value: '{"min":0,"max":49.99}',
          selected: false,
        },
        {
          index: 1,
          label: "R$ 50,00 a R$ 99,99",
          key: "preco",
          value: '{"min":50,"max":99.99}',
          selected: false,
        },
        {
          index: 2,
          label: "R$ 100,00 a R$ 149,99",
          key: "preco",
          value: '{"min":100,"max":149.99}',
          selected: false,
        },
        {
          index: 3,
          label: "R$ 150,00 a R$ 199,99",
          key: "preco",
          value: '{"min":150,"max":199.99}',
          selected: false,
        },
        {
          index: 4,
          label: "R$ 200,00 a R$ 249,99",
          key: "preco",
          value: '{"min":200,"max":249.99}',
          selected: false,
        },
        {
          index: 5,
          label: "R$ 250,00 a R$ 299,99",
          key: "preco",
          value: '{"min":250,"max":299.99}',
          selected: false,
        },
        {
          index: 6,
          label: "R$ 300,00 a R$ 399,99",
          key: "preco",
          value: '{"min":300,"max":399.99}',
          selected: false,
        },
        {
          index: 7,
          label: "R$ 400,00 a R$ 499,99",
          key: "preco",
          value: '{"min":400,"max":499.99}',
          selected: false,
        },
        {
          index: 8,
          label: "Acima de R$ 500,00",
          key: "preco",
          value: '{"min":500,"max":null}',
          selected: false,
        },
      ],
      unique: true,
    },
  ];

  const [selectedItems, setSelectedItems] = useState([]);
  const [callSearch, setCallSearch] = useState(Math.random());
  const [itemsCache, setItemsCache] = useState(itemsDefault);
  const [items, setItems] = useState(itemsDefault);

  useEffect(() => {
    callHandleSearchFilters();
  }, [callSearch, ordenation]);

  useEffect(() => {
    setCategoryReseted(false);
  }, []);

  useEffect(() => {
    if (lastCategorySetted !== params.ctgId) {
      setLastCategorySetted(params.ctgId);
      setCategoryReseted(false);
    }
  }, [params.ctgId]);

  useEffect(() => {
    setSelectedItemsByUrl();
    setCallSearch(Math.random());
  }, [window.location.href]);

  useEffect(() => {
    if (productsFound.length >= 1) {
      const hasGeneric = productsFound.find(
        (product) => product.generico === true
      );
      const hasNonGeneric = productsFound.find(
        (product) => product.generico === false
      );

      const existMedicine = items.find((item) => item.title === "Medicamento");

      if (existMedicine) {
        const filteredItems = items.filter(
          (item) => item.title !== "Medicamento"
        );

        saveItems(filteredItems, true);

        return;
      }

      if (hasGeneric && hasNonGeneric) {
        saveItems(
          [
            ...items,
            {
              title: "Medicamento",
              val: [
                {
                  label: "Genérico",
                  key: "generico",
                  value: true,
                  selected: false,
                },
                {
                  label: "Não Genérico",
                  key: "generico",
                  value: false,
                  selected: false,
                },
              ],
              unique: true,
              visible: false,
              filterVisible: false,
            },
          ],
          true
        );
      }
    }
  }, [productsFound]);

  const handleSearchFilters = async () => {
    const removeAccents = searchTerm
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    const removeUpperCase = removeAccents.toLowerCase();
    await api
      .post(
        "/products/search/filters",
        {
          termo: removeUpperCase ? removeUpperCase : "",
          store: store ? store : null,
        },
        {
          params: {
            categoria: params.ctgId ? params.ctgId : null,
            subcategoria: params.subCtgId ? params.subCtgId : null,
          },
        }
      )
      .then((response) => {
        // Filtro por fabricantes

        const filteredProducers = response.data.producers.map((e) => {
          return {
            label: e.fabricante,
            key: "fabricante",
            value: e.fabricante,
            selected: false,
          };
        });

        const finalObjectProducers = {
          title: "Fabricante",
          val: filteredProducers,
          visible: false,
          filterVisible: true,
          categorie: params.ctgId,
        };

        const alreadyExistentProducer = items.find(
          (e) => e.title === "Fabricante" && e.categorie === params.ctgId
        );

        if (alreadyExistentProducer) {
          let unique = items.filter((e) => {
            return e.title !== alreadyExistentProducer.title;
          });

          saveItems([...unique, finalObjectProducers], true);
        }

        if (!alreadyExistentProducer) {
          let indexToRemove = items.findIndex((r) => r.title === "Fabricante");

          if (indexToRemove !== -1) items.splice(indexToRemove, 1);

          items.push(finalObjectProducers);

          saveItems(items, true);

          setSelectedItemsByUrl();
        }

        // Filtro por tipos de produtos
        const queryParams = new URLSearchParams(window.location.search);
        const tpProd = queryParams.getAll("tipoProduto");
        const filteredProductTypes = response.data.typeProduct.map((e) => {
          return {
            label: e.descricao,
            key: "tipoProduto",
            value: e.tipo,
            selected: tpProd.includes(e.tipo.toString()),
          };
        });

        const finalObject = {
          title: "Tipo Produto",
          val: filteredProductTypes,
          visible: false,
          filterVisible: false,
          categorie: params.ctgId,
        };

        const alreadyExistentProductType = items.find(
          (e) => e.title === "Tipo Produto" && e.categorie === params.ctgId
        );

        if (alreadyExistentProductType) {
          let unico = items.filter((e) => {
            return e.title !== alreadyExistentProductType.title;
          });

          saveItems([...unico, finalObject], true);
        }

        if (!alreadyExistentProductType) {
          let indexToRemove = items.findIndex(
            (r) => r.title === "Tipo Produto"
          );

          if (indexToRemove !== -1) items.splice(indexToRemove, 1);

          items.push(finalObject);

          saveItems(items, true);

          setSelectedItemsByUrl();
        }

        // subCat
        const subCat = queryParams.getAll("subCat");
        const filteredSubcategories = response.data.subcategories.map((e) => {
          return {
            label: e.descricao,
            key: "subCat",
            value: e.id,
            selected: subCat.includes(e.id.toString()),
          };
        });

        const finalSubCategoriesObject = {
          title: "Subcategorias",
          val: filteredSubcategories,
          visible: false,
          filterVisible: false,
        };

        const alreadyExistentSubcategories = items.find(
          (e) => e.title === "Subcategorias"
        );

        if (alreadyExistentSubcategories) {
          let unico = items.filter((e) => {
            return e.title !== alreadyExistentSubcategories.title;
          });

          saveItems([...unico, finalSubCategoriesObject], true);
        }

        if (!alreadyExistentSubcategories) {
          items.push(finalSubCategoriesObject);

          saveItems(items, true);

          setSelectedItemsByUrl();
        }
      });
  };

  const callHandleSearchFilters = () => {
    setFilters(selectedItems);
    handleSearchFilters().then(() => {
      if (!isModal && !resetCategory) callSearchFilters();
    });
  };

  const callSearchFilters = (clearCat = false) => {
    if (clearCat) setCategoryReseted(true);

    dispatch(
      SearchActions.setSearchFilters(
        selectedItems,
        searchTerm,
        ordenation,
        params.ctg && !categoryReseted && !clearCat ? params.ctgId : null,
        params.subCtg && !categoryReseted && !clearCat ? params.subCtgId : null,
        clearCat
      )
    );
  };

  const handleChangeSelected = (
    value,
    indexCategory,
    categoria,
    isUnique = false
  ) => {
    categoria.val.map((filter) => {
      if (filter.label === value.label) filter.selected = !filter.selected;
      else if (isUnique) filter.selected = false;

      return filter;
    });
    itemsCache[indexCategory] = categoria;
    saveItems([...itemsCache], !isModal);
    handleChangeSelectedModal([...itemsCache]);
  };

  const handleChangeSelectedModal = (itemsTarget) => {
    let montaUrl = decodeURIComponent(window.location.href);
    let precoSelected = undefined;

    if (itemsTarget.length > 0) {
      const itemSelected = [];
      itemsTarget.forEach((item) => {
        item.val.forEach((val) => {
          if (val.selected) {
            if (val.key === "desconto") {
              montaUrl = limparDescontoUrl(montaUrl);
              if (montaUrl.includes("?")) montaUrl += "&";
              else montaUrl += "?";
              montaUrl += `${val.key}=${val.value}`;
            } else if (val.key === "leveMaisPagueMenos") {
              montaUrl = limparLeveMaisPagueMenosUrl(montaUrl);
              if (montaUrl.includes("?")) montaUrl += "&";
              else montaUrl += "?";
              montaUrl += `${val.key}=${val.value}`;
            } else if (val.key === "generico") {
              montaUrl = limparGenericoUrl(montaUrl, val.value);
              if (montaUrl.includes("?")) montaUrl += "&";
              else montaUrl += "?";
              montaUrl += `${val.key}=${val.value}`;
            } else if (val.key === "preco") {
              precoSelected = val.index;
              montaUrl = limparPrecoUrl(montaUrl, precoSelected, false);
              if (montaUrl.includes("?")) montaUrl += "&";
              else montaUrl += "?";
              montaUrl += `${val.key}=${val.index}`;
            } else if (val.key === "fabricante") {
              if (!montaUrl.includes(val.value)) {
                if (montaUrl.includes("?")) montaUrl += "&";
                else montaUrl += "?";
                montaUrl += `${val.key}=${val.value}`;
              }
            } else if (val.key === "tipoProduto") {
              if (!montaUrl.includes(`tipoProduto=${val.value}`)) {
                if (montaUrl.includes("?")) montaUrl += "&";
                else montaUrl += "?";
                montaUrl += `${val.key}=${val.value}`;
              }
            } else if (val.key === "subCat") {
              if (!montaUrl.includes(`subCat=${val.value}`)) {
                if (montaUrl.includes("?")) montaUrl += "&";
                else montaUrl += "?";
                montaUrl += `${val.key}=${val.value}`;
              }
            }

            itemSelected.push(val);
          } else {
            if (val.key === "desconto") {
              montaUrl = limparDescontoUrl(montaUrl);
            } else if (val.key === "leveMaisPagueMenos") {
              montaUrl = limparLeveMaisPagueMenosUrl(montaUrl);
            } else if (val.key === "generico") {
              montaUrl = limparGenericoUrl(montaUrl, val.value);
            } else {
              montaUrl = configUrlFabricante(montaUrl, val.value);
              montaUrl = limparPrecoUrl(montaUrl, precoSelected, false);
              montaUrl = configUrlTipoProduto(montaUrl, val.value);
              montaUrl = configUrlsubCat(montaUrl, val.value);
            }
          }
        });
      });

      setSelectedItems(itemSelected);

      if (
        decodeURIComponent(montaUrl) !==
        decodeURIComponent(window.location.href)
      )
        window.history.pushState(null, "", montaUrl);
    }
  };

  const handleChangeUnSelectedItems = (unSelectedItem) => {
    let montaUrl = decodeURIComponent(window.location.href);
    let precoSelected = undefined;

    if (unSelectedItem.key === "buscar") {
      if (montaUrl.includes("categoria") && montaUrl.includes("?")) {
        const urlObj = new URL(montaUrl);
        const params = urlObj.searchParams;

        params.delete("subCat");
        const updatedUrl = urlObj.toString();

        const queryIndex = updatedUrl.indexOf("?");

        callSearchFilters(true);

        if (queryIndex === -1)
          window.history.pushState(null, "", `/produtos/buscar`);
        else {
          const pathWithoutQuery = updatedUrl.substring(queryIndex);
          window.history.pushState(
            null,
            "",
            `/produtos/buscar${pathWithoutQuery}`
          );
        }
      } else {
        callSearchFilters(true);
        window.history.pushState(null, "", `/produtos/buscar`);
      }
    } else if (unSelectedItem.key === "desconto") {
      montaUrl = limparDescontoUrl(montaUrl);
    } else if (unSelectedItem.key === "leveMaisPagueMenos") {
      montaUrl = limparLeveMaisPagueMenosUrl(montaUrl);
    } else if (unSelectedItem.key === "generico") {
      montaUrl = limparGenericoUrl(montaUrl, unSelectedItem.value);
    } else if (unSelectedItem.key === "preco") {
      precoSelected = unSelectedItem.index;
      montaUrl = limparPrecoUrl(montaUrl, precoSelected, true);
    } else if (unSelectedItem.key === "fabricante") {
      if (montaUrl.includes(unSelectedItem.value)) {
        montaUrl = configUrlFabricante(montaUrl, unSelectedItem.value);
      }
    } else if (unSelectedItem.key === "tipoProduto") {
      if (montaUrl.includes(unSelectedItem.value)) {
        montaUrl = configUrlTipoProduto(montaUrl, unSelectedItem.value);
      }
    } else if (unSelectedItem.key === "subCat") {
      if (montaUrl.includes(unSelectedItem.value)) {
        montaUrl = configUrlsubCat(montaUrl, unSelectedItem.value);
      }
    }

    itemsCache.forEach((item) => {
      item.val.forEach((itemVal) => {
        if (
          itemVal.key === unSelectedItem.key &&
          itemVal.value === unSelectedItem.value
        )
          itemVal.selected = false;
      });
    });

    saveItems(itemsCache, true);

    if (
      unSelectedItem.key !== "buscar" &&
      decodeURIComponent(montaUrl) !== decodeURIComponent(window.location.href)
    )
      window.history.pushState(null, "", montaUrl);
  };

  const configUrlFabricante = (url, value) => {
    if (url.includes(`?fabricante=${value}&`)) {
      url = url.replace(`?fabricante=${value}&`, "?");
    } else if (url.includes(`?fabricante=${value}`)) {
      url = url.replace(`?fabricante=${value}`, "");
    } else if (url.includes(`&fabricante=${value}`)) {
      url = url.replace(`&fabricante=${value}`, "");
    }

    return url;
  };

  const limparPrecoUrl = (url, precoSelected, unSelected) => {
    itemsDefault
      .find((item) => item.title === "Faixa de preço")
      .val.forEach((itemVal) => {
        if (!unSelected && precoSelected === itemVal.index) return url;

        if (url.includes(`?preco=${itemVal.index}&`)) {
          url = url.replace(`?preco=${itemVal.index}&`, "?");
        } else if (url.includes(`?preco=${itemVal.index}`)) {
          url = url.replace(`?preco=${itemVal.index}`, "");
        } else if (url.includes(`&preco=${itemVal.index}`)) {
          url = url.replace(`&preco=${itemVal.index}`, "");
        }
      });
    return url;
  };

  const limparDescontoUrl = (url) => {
    if (url.includes(`?desconto=true&`)) {
      url = url.replace(`?desconto=true&`, "?");
    } else if (url.includes(`?desconto=true`)) {
      url = url.replace(`?desconto=true`, "");
    } else if (url.includes(`&desconto=true`)) {
      url = url.replace(`&desconto=true`, "");
    }

    return url;
  };

  const limparLeveMaisPagueMenosUrl = (url) => {
    if (url.includes(`?leveMaisPagueMenos=true&`)) {
      url = url.replace(`?leveMaisPagueMenos=true&`, "?");
    } else if (url.includes(`?leveMaisPagueMenos=true`)) {
      url = url.replace(`?leveMaisPagueMenos=true`, "");
    } else if (url.includes(`&leveMaisPagueMenos=true`)) {
      url = url.replace(`&leveMaisPagueMenos=true`, "");
    }

    return url;
  };

  const configUrlTipoProduto = (url, value) => {
    if (url.includes(`?tipoProduto=${value}&`)) {
      url = url.replace(`?tipoProduto=${value}&`, "?");
    } else if (url.includes(`?tipoProduto=${value}`)) {
      url = url.replace(`?tipoProduto=${value}`, "");
    } else if (url.includes(`&tipoProduto=${value}`)) {
      url = url.replace(`&tipoProduto=${value}`, "");
    }

    return url;
  };

  const configUrlsubCat = (url, value) => {
    if (url.includes(`?subCat=${value}&`)) {
      url = url.replace(`?subCat=${value}&`, "?");
    } else if (url.includes(`?subCat=${value}`)) {
      url = url.replace(`?subCat=${value}`, "");
    } else if (url.includes(`&subCat=${value}`)) {
      url = url.replace(`&subCat=${value}`, "");
    }

    return url;
  };

  const limparGenericoUrl = (url, value) => {
    if (url.includes(`?generico=${value}&`)) {
      url = url.replace(`?generico=${value}&`, "?");
    } else if (url.includes(`?generico=${value}`)) {
      url = url.replace(`?generico=${value}`, "");
    } else if (url.includes(`&generico=${value}`)) {
      url = url.replace(`&generico=${value}`, "");
    }

    return url;
  };

  const saveItems = (param, saveOriginalItems) => {
    if (saveOriginalItems) setItems(param);
    setItemsCache(param);
  };

  const handleFilterApply = () => {
    setItems(itemsCache);
    handleChangeSelectedModal(itemsCache);
  };

  const clearSelectedItems = () => {
    setSelectedItems([]);

    items.map((item) => {
      item.val.map((val) => {
        val.selected = false;
      });
    });
    saveItems(items);
  };

  const clearAllSelectedItems = () => {
    history.push(`/produtos/buscar`);
  };

  const closeDropdownFilter = () => {
    setItemsCache(items);
    setIsOpen();
  };

  const [filterText, setFilterText] = useState({});

  const handleFilterChange = (index, value) => {
    setFilterText((prev) => ({ ...prev, [index]: value }));
  };

  const getQueryParam = (url, target) => {
    const basePath = target;
    const queryIndex = url.indexOf("?");
    const pathWithoutQuery =
      queryIndex !== -1 ? url.substring(0, queryIndex) : url;

    if (pathWithoutQuery.includes(basePath)) {
      const targetPath = pathWithoutQuery.split(basePath)[1];
      return targetPath.split("/")[0];
    }
    return null;
  };

  const getQueryParamBuscar = (url) => {
    return getQueryParam(url, "/buscar/");
  };

  const getQueryParamCategoria = (url) => {
    return getQueryParam(url, "/categoria/");
  };

  const setSelectedItemsByUrl = () => {
    const queryParams = new URLSearchParams(window.location.search);

    const fabrs = queryParams.getAll("fabricante");
    const precos = queryParams.getAll("preco");
    const desconto = queryParams.get("desconto");
    const leveMaisPagueMenos = queryParams.get("leveMaisPagueMenos");
    const tpProd = queryParams.getAll("tipoProduto");
    const subCat = queryParams.getAll("subCat");
    const generico = queryParams.get("generico");

    const itemSelected = [];

    if (window.location.href.includes("/buscar/")) {
      const queryBuscar = getQueryParamBuscar(
        decodeURIComponent(window.location.href)
      );
      itemSelected.push({
        label: queryBuscar,
        key: "buscar",
        value: queryBuscar,
        selected: true,
      });
    }

    if (window.location.href.includes("/categoria/")) {
      const queryCat = getQueryParamCategoria(
        decodeURIComponent(window.location.href)
      );
      itemSelected.push({
        label: queryCat,
        key: "buscar",
        value: queryCat,
        selected: true,
      });
    }

    if (itemsCache.length > 0) {
      itemsCache.forEach((item) => {
        item.val.forEach((val) => {
          if (val.key === "desconto" && desconto === "true") {
            val.selected = true;
            itemSelected.push(val);
          } else if (
            val.key === "leveMaisPagueMenos" &&
            leveMaisPagueMenos === "true"
          ) {
            val.selected = true;
            itemSelected.push(val);
          } else if (
            val.key === "generico" &&
            val.value === true &&
            generico === "true"
          ) {
            val.selected = true;
            itemSelected.push(val);
          } else if (
            val.key === "preco" &&
            precos.length > 0 &&
            val.index === Number.parseInt(precos[0])
          ) {
            val.selected = true;
            itemSelected.push(val);
          } else if (val.key === "fabricante" && fabrs.includes(val.value)) {
            val.selected = true;
            itemSelected.push(val);
          } else if (
            val.key === "tipoProduto" &&
            tpProd.includes(val.value.toString())
          ) {
            val.selected = true;
            itemSelected.push(val);
          } else if (
            val.key === "subCat" &&
            subCat.includes(val.value.toString())
          ) {
            val.selected = true;
            itemSelected.push(val);
          } else val.selected = false;
        });
      });

      if (itemSelected.length > 0) {
        setSelectedItems(itemSelected);
        saveItems(itemsCache, true);
      } else clearSelectedItems();
    }
  };

  return (
    <Container id="dropdownContainer">
      {isModal && (
        <ContainerCloseRound>
          <CloseRound onClick={closeDropdownFilter} className={"closeRound"} />
        </ContainerCloseRound>
      )}

      <ContainerInfoFilter hasPadding={isModal}>
        <Title>Filtros</Title>

        <ContainerFilters>
          {itemsCache &&
            itemsCache.map((item, index) => {
              const filteredItems = item.val.filter((e) =>
                e.label
                  .toLowerCase()
                  .includes((filterText[index] || "").toLowerCase())
              );

              const queryParams = new URLSearchParams(window.location.search);

              const fabrs = queryParams.getAll("fabricante");
              const precos = queryParams.getAll("preco");
              const tpProd = queryParams.getAll("tipoProduto");
              const subCat = queryParams.getAll("subCat");
              const desconto = queryParams.get("desconto");
              const leveMaisPagueMenos = queryParams.get("leveMaisPagueMenos");
              const generico = queryParams.get("generico");

              return (
                <div>
                  {index === 0 && selectedItems.length > 0 && (
                    <ContainerFiltersSelected>
                      <span>Filtros Selecionados</span>
                      <ul>
                        {selectedItems.map((it) => {
                          return (
                            <li>
                              <span>{it.label}</span>
                              <a
                                href=""
                                onClick={(e) => {
                                  e.preventDefault();
                                  const novaLista = selectedItems.filter(
                                    (selectedItem) =>
                                      selectedItem.key !== it.key ||
                                      (selectedItem.key === it.key &&
                                        selectedItem.value !== it.value)
                                  );
                                  setSelectedItems(novaLista);
                                  handleChangeUnSelectedItems(it);
                                }}
                              >
                                X
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                      <a
                        className="cleanAll"
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          clearAllSelectedItems();
                        }}
                      >
                        Limpar todos
                      </a>
                    </ContainerFiltersSelected>
                  )}

                  {item.val.length >= 1 && (
                    <div key={`container-${index}`}>
                      <ContentFilter>
                        {item.val.length >= 1 && (
                          <Header>
                            {item.title}
                            <hr />
                          </Header>
                        )}
                        {item.filterVisible && (
                          <InputField
                            type="text"
                            value={filterText[index] || ""}
                            onChange={(e) =>
                              handleFilterChange(index, e.target.value)
                            }
                          />
                        )}

                        <div className="items div-custom-scroll">
                          {filteredItems.map((e, indexItem) => (
                            <ContainerCheckFilter
                              key={`filter${e.key}-${indexItem}`}
                            >
                              <input
                                id={`filter${indexItem}`}
                                checked={
                                  e.selected &&
                                  (fabrs?.includes(e.value) ||
                                    e.key !== "fabricante") &&
                                  (tpProd?.includes(e.value.toString()) ||
                                    e.key !== "tipoProduto") &&
                                  (subCat?.includes(e.value.toString()) ||
                                    e.key !== "subCat") &&
                                  (desconto?.includes(e.value.toString()) ||
                                    e.key !== "desconto") &&
                                  (leveMaisPagueMenos?.includes(
                                    e.value.toString()
                                  ) ||
                                    e.key !== "leveMaisPagueMenos") &&
                                  (generico?.includes(e.value.toString()) ||
                                    e.key !== "generico") &&
                                  ((precos?.length > 0 &&
                                    Number.parseInt(precos[0]) === e.index) ||
                                    e.key !== "preco")
                                }
                                type="checkbox"
                                name={`filter${indexItem}`}
                                onClick={() =>
                                  handleChangeSelected(
                                    e,
                                    index,
                                    item,
                                    item.unique
                                  )
                                }
                              />
                              <label htmlFor={`filter${indexItem}`}>
                                {e.label}
                              </label>
                            </ContainerCheckFilter>
                          ))}
                        </div>
                      </ContentFilter>
                    </div>
                  )}
                </div>
              );
            })}
        </ContainerFilters>
      </ContainerInfoFilter>

      {isModal && (
        <ContainerButton>
          <ButtonPrimary
            className={"buttonFilter"}
            text={"Aplicar filtros"}
            disabled={selectedItems.length == 0}
            onClick={handleFilterApply}
          />
          <ButtonSecondary
            className={"buttonFilter"}
            text={"Limpar filtros"}
            disabled={selectedItems.length == 0}
            onClick={clearSelectedItems}
          />
        </ContainerButton>
      )}
    </Container>
  );
}
