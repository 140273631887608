import React from "react";
import { AddressSchema } from "./validation";
import { Formik } from "formik";
import { Container, FieldErrorMessage, FormGroup, InputField, Label, Line } from "./styles";
import MaskedInput from "react-text-mask";
import { cepMask, phoneMask } from "../../util/mask";
import { getAddressByCEP } from "../../services/cep";

export default function AddressForm({ formRef, address: { id, contactName, contactPhone, zipCode, state, city, neighborhood, number, street, complement, ibge }, onAddressSubmit }) {
    return (
        <Formik
            ref={formRef}
            initialValues={{
                id: id || 0,
                nome_contato: contactName,
                telefone_contato: contactPhone,
                cep: zipCode,
                uf: state,
                cidade: city,
                bairro: neighborhood,
                numero: number,
                rua: street,
                complemento: complement,
                ibge: ibge,
            }}
            validationSchema={AddressSchema}
            onSubmit={onAddressSubmit}
        >
            {({
                values,
                handleChange,
                handleBlur,
                errors,
                touched,
                handleSubmit,
                setFieldValue,
                isValid,
            }) => (
                <Container>
                    <input type="hidden" name="id" value={values.id} />
                    <FormGroup>
                        <Label>Digite o CEP de entrega</Label>
                        <MaskedInput
                            invalid={errors.cep && touched.cep}
                            id="cep"
                            type="text"
                            name="cep"
                            className="mediumSize"
                            mask={cepMask}
                            onChange={handleChange}
                            onKeyUp={async () => {
                                const cep = values.cep.replace(/[^a-zA-Z0-9]/g, "");
                                if (cep.length === 8) {
                                    const { data } = await getAddressByCEP(cep);
                                    if (data.erro) {
                                        setFieldValue("uf", "");
                                        setFieldValue("cidade", "");
                                        setFieldValue("rua", "");
                                        setFieldValue("bairro", "");
                                        setFieldValue("ibge", "");
                                    } else {
                                        setFieldValue("uf", data.uf || "");
                                        setFieldValue("cidade", data.localidade || "");
                                        setFieldValue("rua", data.logradouro || "");
                                        setFieldValue("bairro", data.bairro || "");
                                        setFieldValue("ibge", data.ibge || "");
                                    }

                                }
                            }}
                            value={values.cep}
                            onBlur={handleBlur}
                            placeholder="Ex: _____-___"
                            render={(ref, props) => (
                                <InputField ref={(input) => ref(input)} {...props} />
                            )}
                        />
                        {errors.cep && touched.cep && (
                            <FieldErrorMessage>{errors.cep}</FieldErrorMessage>
                        )}

                    </FormGroup>

                    {values.uf && (
                        <>
                            <Line>
                                <FormGroup style={{ flex: 2 }}>
                                    <Label>Rua/Avenida</Label>
                                    <InputField
                                        invalid={errors.rua && touched.rua}
                                        id="rua"
                                        type="text"
                                        name="rua"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.rua}
                                        placeholder="Ex: Rua/Av. Fulano da Silva"
                                    />
                                    {errors.rua && touched.rua && (
                                        <FieldErrorMessage>{errors.rua}</FieldErrorMessage>
                                    )}
                                </FormGroup>
                                <FormGroup style={{ flex: 1 }}>
                                    <Label>Número</Label>
                                    <InputField
                                        invalid={errors.numero && touched.numero}
                                        id="numero"
                                        type="text"
                                        name="numero"
                                        className="smallSize"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.numero}
                                        placeholder="Ex: 1191 ou s/n"
                                    />
                                    {errors.numero && touched.numero && (
                                        <FieldErrorMessage>{errors.numero}</FieldErrorMessage>
                                    )}
                                </FormGroup>
                            </Line>
                            <Line>
                                <FormGroup style={{ flex: 2 }}>
                                    <Label>Cidade</Label>
                                    <InputField
                                        disabled
                                        invalid={errors.cidade && touched.cidade}
                                        id="cidade"
                                        type="text"
                                        name="cidade"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.cidade}
                                        placeholder="Ex: Tubarão"
                                    />
                                    {errors.cidade && touched.cidade && (
                                        <FieldErrorMessage>{errors.cidade}</FieldErrorMessage>
                                    )}
                                </FormGroup>
                                <FormGroup style={{ flex: 1 }}>
                                    <Label>Estado</Label>
                                    <InputField
                                        invalid={errors.uf && touched.uf}
                                        id="uf"
                                        type="text"
                                        name="uf"
                                        className="smallSize"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled
                                        value={values.uf}
                                        placeholder="Ex: Santa Catarina"
                                    />
                                    {errors.uf && touched.uf && (
                                        <FieldErrorMessage>{errors.uf}</FieldErrorMessage>
                                    )}
                                </FormGroup>
                            </Line>
                            <FormGroup>
                                <Label>Bairro</Label>
                                <InputField
                                    invalid={errors.bairro && touched.bairro}
                                    id="bairro"
                                    type="text"
                                    name="bairro"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.bairro}
                                    placeholder="Ex: Santo Antonio de Pádua"
                                />
                                {errors.bairro && touched.bairro && (
                                    <FieldErrorMessage>{errors.bairro}</FieldErrorMessage>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label>Complemento</Label>
                                <InputField
                                    invalid={errors.complemento && touched.complemento}
                                    id="complemento"
                                    type="text"
                                    name="complemento"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.complemento}
                                    placeholder="Ex: Andar/Apartamento (opcional)"
                                />
                                {errors.complemento && touched.complemento && (
                                    <FieldErrorMessage>
                                        {errors.complemento}
                                    </FieldErrorMessage>
                                )}

                            </FormGroup>
                            <Line>
                                <FormGroup style={{ flex: 2 }}>
                                    <Label>Nome para contato</Label>
                                    <InputField
                                        invalid={errors.nome_contato && touched.nome_contato}
                                        id="nome_contato"
                                        type="text"
                                        name="nome_contato"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.nome_contato}
                                        placeholder="Ex: Fulano da Silva"
                                    />
                                    {errors.nome_contato && touched.nome_contato && (
                                        <FieldErrorMessage>
                                            {errors.nome_contato}
                                        </FieldErrorMessage>
                                    )}
                                </FormGroup>
                                <FormGroup style={{ flex: 1 }}>
                                    <Label>Telefone Contato</Label>
                                    <MaskedInput
                                        name="telefone_contato"
                                        type="text"
                                        placeholder="Ex: (__) ____-____"
                                        mask={phoneMask}
                                        className="smallSize"
                                        value={values.telefone_contato}
                                        invalid={
                                            errors.telefone_contato && touched.telefone_contato
                                        }
                                        id="registerNumber"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        render={(ref, props) => (
                                            <InputField ref={(input) => ref(input)} {...props} />
                                        )}
                                    />
                                    {errors.telefone_contato && touched.telefone_contato && (
                                        <FieldErrorMessage>
                                            {errors.telefone_contato}
                                        </FieldErrorMessage>
                                    )}
                                </FormGroup>
                            </Line>
                        </>
                    )}
                </Container>
            )}
        </Formik>
    );
}