import React from "react";
import { Container } from "./styles";
import { FiEdit2 } from "react-icons/fi";
import LoaderAsync from "../LoaderAsync";
import { formatAddress } from "../../util/formatAddress";

export default function SelectedAddress({ address, loading, showSelectedAddressModal }) {
    return (
        <Container>
            <h4>Selecione um endereço</h4>
            {loading ? (<LoaderAsync message="Carregando seus endereços..." />) : (
                <div className="address-management">
                    {address && (
                        <div className="info-customer">
                            <p className="customer-name">{address?.nome_contato}</p>
                            <p className="customer-address">{formatAddress(address?.endereco, address?.numero, address?.bairro, `${address?.cidade?.nome} - ${address?.cidade?.uf_sigla}`, address?.complemento)}</p>
                        </div>
                    )}
                    <button className="choose-address-btn" onClick={showSelectedAddressModal}> <FiEdit2 size={14} /> Escolher outro endereço</button>
                </div>
            )}
        </Container>
    )
}