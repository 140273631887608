import { call, put } from "redux-saga/effects";
import api from "../../services/api";
import { Creators as StoreCreators } from "../ducks/store";
import { Creators as DomainCreators } from "../ducks/domain";
import { Creators as UserCreators } from "../ducks/user";
import { Creators as MenuCreators } from "../ducks/menu";

export function* getStoreByDomain() {
  try {
    const response = yield call(api.get, `/stores/domain`);

    if (!response.data.store.empresa.politica_privacidade && !response.data.store.empresa.politica_privacidade_html)
      response.data.store.empresa.politica_privacidade = `/companies/${response.data.store.empresa.url}/politica_privacidade.html`

    yield put(UserCreators.removeLoading());
    yield put(MenuCreators.getSuccess(response.data.menu));
    yield put(StoreCreators.getSuccess(response.data.store));
    yield put(DomainCreators.getDomainSuccess());
  } catch (err) {
    yield put(DomainCreators.getDomainFailure(err.response.data.user));
  }
}
