import styled from "styled-components";
import ButtonPrimary from "../../components/ButtonPrimary";
import InputCustom from "../../components/InputCustom";
import { device } from "../../styles/device";

export const Container = styled.div`
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  position: fixed;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 60000;
  -webkit-overflow-scrolling: touch;
  
  h2{
    font-size: 20px;
    font-weight: 700;
    color: ${props => props.theme.colors.neutral900};
    margin-bottom: 40px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 8px;
  position: absolute;
  margin-top: 6%;
  padding: 12px 16px;
  width: 100%;
  max-width: 360px;

  .closePopup{
    height: 40px;
    width: 40px;
    align-self: flex-end;
    cursor: pointer;
  }

  @media ${device.tablet} {
    padding: 24px 56px 64px;
    max-width: 674px;
  }
`;

export const FormContainer = styled.form`

  .novaSenhaContainer{
    display: flex;
    flex-direction: column;
    align-items: start;

    @media ${device.tablet} {
      flex-direction: row;

      .senhaNovaContainer{
        margin-left: 24px;
      }
    }
  }

  .botoesContainer{
    margin-top: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media ${device.tablet} {
      justify-content: flex-start;
      flex-direction: row;

      .buttonSubmit{
        margin-right: 24px;
      }
    }
  }
`;

export const StyledInputCustom = styled(InputCustom)`
  width: 100%;
  max-width: 328px;

  @media ${device.tablet} {
    max-width: 269px;
  }
  
`;

export const StyledButtonPrimary = styled(ButtonPrimary)`
  width: 100%;

  @media ${device.tablet} {
    width: auto;
  }
`;
