import React, { useEffect } from 'react';
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import ModalReader from '../ModalReader';

function TermsOfUseModal({ visible, closeModal }) {

  const { termos_uso } = useSelector((state) =>
    state.store.data == null ? "" : state.store.data.empresa
  );

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [visible]);

  return (
    <>
      {visible && (
        <ModalReader visible={visible} closeModal={closeModal}>
          {ReactHtmlParser(termos_uso)}
        </ModalReader>
      )}
    </>
  );
}

export default TermsOfUseModal;
