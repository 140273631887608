import { call, put } from "redux-saga/effects";
import api from "../../services/api";
import { Creators as CategoryActions } from "../ducks/category";

export function* getCategories() {
  try {
    const { data } = yield call(
      api.get,
      `/categories`
    );

    yield put(CategoryActions.getCategoriesSuccess(data));
  } catch (err) {
    yield put();
  }
}

