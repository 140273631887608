import React, { useContext, useEffect, useState } from "react";
import BuyStepsTemplate from "../../components/BuyStepsTemplate";
import SubHeader from "../../components/SubHeader";
import { ContainerProducts, ContentWrap, LoaderContainer, Overlay, PriceInfoContent, PriceInfoWrap, RevisionWrap, StyledButtonPrimary, StyledButtonSecondary, StyledInputRadioCustom, TitleWrap, WarnContainer, WhatWouldLikeToDoTitleWrap } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { Creators as AddressActions } from "../../store/ducks/address";
import { Creators as OrderActions } from "../../store/ducks/order";
import Loader from "react-loader-spinner";
import { ThemeContext } from "styled-components";
import AddressSelectionModal from "../../components/AddressSelectionModal";
import DeliveryOptionPicker from "../../components/DeliveryOptionPicker";
import SelectedAddress from "../../components/SelectedAddress";
import api from "../../services/api";
import { toast } from "react-toastify";
import PartlyFulfilledOrderModal from "../../components/PartlyFulfilledOrderModal";
import { formatPrice } from "../../util/format";
import { DeliveryType } from "../../util/deliveryType";
import { getFriendlyDateForDeliveryTypeString } from "../../util/friendlyDate";
import { setTitle } from "../../services/browser";
import ProductCard from "../../components/ProductCard";
import AlertInfo from "../../components/AlertInfo";
import ShowAcceptGetItOnDifferentDays from "../../components/ShowAcceptGetItOnDifferentDays";
import PickupModal from "../../components/PickupModal";

export default function NewOrderRevision({ history }) {

    const [mainAddress, setMainAddress] = useState(null);
    const [showAddressSelectionModal, setShowAddressSelectionModal] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const [mainOptionDelivery, setMainOptionDelivery] = useState(null);
    const [productsList, setProductsList] = useState([]);
    const [receiveInDifferentsDays, setReceiveInDifferentsDays] = useState(undefined);
    const [productsWithAssociadaStock, setProductsWithAssociadaStock] = useState([]);
    const [productsWithoutAssociadaStock, setProductsWithoutAssociadaStock] = useState([]);
    const [amount, setAmount] = useState(0);
    const [subtotal, setSubtotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [acceptReceiveOnDifferentsDays, setAcceptReceiveOnDifferentsDays] = useState(false);
    const [acceptReceiveOnDifferentsDaysModal, setAcceptReceiveOnDifferentsDaysModal] = useState(false);
    const [showPickupModal, setShowPickupModal] = useState(false);
    const [responsibleWithdrawal, setResponsibleWithdrawal] = useState(null);
    const [deliveryMultiplicationFactor, setDeliveryMultiplicationFactor] = useState(1);

    const { loading: loadingAddresses, data: addresses } = useSelector(state => state.address);
    const cart = useSelector((state) => state.cart);
    const store = useSelector(state => state.storeByUser.data);

    const dispatch = useDispatch();
    const theme = useContext(ThemeContext);

    useEffect(() => {
        setTitle("Revisão");
    }, []);

    useEffect(() => {
        dispatch(AddressActions.getAddressesRequest());
    }, [dispatch])

    useEffect(() => {
        if (loadingAddresses)
            setShowOverlay(true)
        else {
            setShowOverlay(false)
            selectMainAddress(addresses)
        }
    }, [loadingAddresses, addresses])

    useEffect(() => {
        loadProductsFromStore(cart, mainOptionDelivery)
        if (mainOptionDelivery?.tipoEntrega === DeliveryType.RECEBER_EM_CASA) {
            setResponsibleWithdrawal(null)
        }
    }, [mainOptionDelivery, cart])

    useEffect(() => {
        splitProductsByContingency(productsList, receiveInDifferentsDays)
    }, [productsList, receiveInDifferentsDays])

    useEffect(() => {
        if(receiveInDifferentsDays)
            setDeliveryMultiplicationFactor(2)
        else
            setDeliveryMultiplicationFactor(1)
    }, [receiveInDifferentsDays])

    function selectMainAddress(addresses) {
        if (addresses && addresses.length > 0)
            setMainAddress(addresses[0])
    }

    async function verifyExistenceProductStock(cart, store) {
        let isValid = false;
        if (cart && cart.data && store) {

            let finalBasket = [];

            cart.data.forEach((product) => {
                const productFound = finalBasket.find((item) => item.id === product.id);

                if (!productFound) {
                    finalBasket.push({ id: product.id, nome: product.nome, quantidade: product.quantidade + (product.brotherQuantity ?? 0) })
                }
            })

            try {
                setShowOverlay(true)
                const { data } = await api.post(
                    `/orders/check-store-stock${store !== null ? `/${store.uuid}` : ''}`,
                    {
                        storeId: store.id,
                        items: finalBasket
                    }
                )
                if (data.itensNaoAtendidos.length > 0) {
                    dispatch(OrderActions.showOutOfStockItems(data));
                } else {
                    isValid = true
                }
            } catch (e) {
                toast.error(e.response?.data?.user || 'Erro ao validar estoque');
            } finally {
                setShowOverlay(false)
            }
        }

        return isValid
    }

    function loadProductsFromStore(productsCart, store) {
        if (productsCart) {
            let treatedProducts = [];

            let canWaitStore = true;

            if (store) {
                const nextTimeStoreOpened = getNextTimeStoreOpened(store);
                let [date, timeRange] = nextTimeStoreOpened.split(' ');
                let [, endTime] = timeRange.split('-');
                const endTimeBeforeClose = new Date(`${date} ${endTime}`);
                const maxWaitingDate = new Date();
                maxWaitingDate.setHours(maxWaitingDate.getHours() + store.tempo_contingencia_gam);
                canWaitStore = maxWaitingDate <= endTimeBeforeClose;
            }

            productsCart.data.forEach(product => {
                if (product.estoques && product.estoques.length > 0) {
                    const stock = product.estoques.find(e => e.loja_id = store?.id)
                    var quantityNeededInStoreStock = stock && stock.quantidade_associada > 0 ? (product.quantidade > stock.quantidade_associada ? stock.quantidade_associada : product.quantidade) : 0;
                    const quantityMissing = product.quantidade - quantityNeededInStoreStock;
                    const gamStock = stock ? (stock.quantidade - stock.quantidade_associada) : 0;
                    var quantityNeededInGamStock = gamStock > 0 ? (quantityMissing > gamStock ? gamStock : quantityMissing) : 0;

                    //predict whether the product will be in stock by the next store opening
                    if (canWaitStore) {
                        quantityNeededInStoreStock += quantityNeededInGamStock;
                        quantityNeededInGamStock = 0;
                    }

                    treatedProducts.push({ ...product, quantidadeAssociada: quantityNeededInStoreStock, quantidadeGam: quantityNeededInGamStock });
                }
            });

            loadFinalPrices(productsCart);

            if (treatedProducts.length > 0) {
                setProductsList(treatedProducts);
            }
        }
    }

    const loadFinalPrices = (cart) => {
        setAmount(cart.amount);
        const subtotal = cart?.data?.reduce(
            (acc, product) =>
                acc +
                parseFloat(
                    product?.preco_mostrar_cesta?.preco_de || product?.preco_mostrar_cesta
                ) *
                product.quantidade,
            0
        );
        setSubtotal(subtotal);
        setDiscount(subtotal - cart.amount);
    }

    function splitProductsByContingency(products, receiveInDifferentsDays) {
        let productsWithStock = [];
        let productsWithoutStock = [];
        if (products.length > 0) {
            const thereAreProductsInStockAssociadaAndGam = productsList.some(p => p.quantidadeAssociada > 0) && productsList.some(p => p.quantidadeGam > 0);
            setAcceptReceiveOnDifferentsDays(thereAreProductsInStockAssociadaAndGam);

            products.forEach(product => {
                let finalGamQuantity = product.quantidadeGam;
                let finalAssociadaQuantity = product.quantidadeAssociada;
                if (thereAreProductsInStockAssociadaAndGam && receiveInDifferentsDays !== undefined && !receiveInDifferentsDays) {
                    finalGamQuantity += product.quantidadeAssociada;
                    finalAssociadaQuantity = 0;
                }

                if (finalAssociadaQuantity > 0) {
                    productsWithStock.push({ ...product, quantidade: finalAssociadaQuantity });
                }
                if (finalGamQuantity > 0) {
                    productsWithoutStock.push({ ...product, quantidade: finalGamQuantity });
                }
            });
        }
        setProductsWithAssociadaStock(productsWithStock);
        setProductsWithoutAssociadaStock(productsWithoutStock);
    }

    function getNextTimeStoreOpened(selectedStore) {
        if (selectedStore) {
            const initTime = selectedStore.lojaHorario?.hora_inicio;
            const endTime = selectedStore.lojaHorario?.hora_fim;
            const storeOpeningDate = selectedStore.lojaHorario?.data_disponibilidade;
            return `${storeOpeningDate} ${initTime.split(':').slice(0, 2).join(':')}-${endTime.split(':').slice(0, 2).join(':')}`;
        }
    }

    function getFriendlyDateToDeliveryOrPickup(selectedStore) {
        const nextTimeStoreOpened = getNextTimeStoreOpened(selectedStore);
        return getFriendlyDateForDeliveryTypeString(nextTimeStoreOpened, mainOptionDelivery.tipoEntrega, selectedStore.lojaHorario.tempo_para_retirada);
    }

    const handleReveived = (e) => {
        setReceiveInDifferentsDays(e.target.value === "S");
    }

    function continueToPayment() {
        const showModal = productsWithAssociadaStock.length > 0 && productsWithoutAssociadaStock.length > 0 && receiveInDifferentsDays;
        if (showModal)
            setAcceptReceiveOnDifferentsDaysModal(true);
        else
            confirmContinueToPayment(receiveInDifferentsDays !== undefined ? receiveInDifferentsDays : undefined);
    }

    const confirmContinueToPayment = async (receiveInDifferentsDays) => {

        const hasStock = await verifyExistenceProductStock(cart, mainOptionDelivery)
        if (hasStock) {
            let finalBasket = [];

            cart.data.forEach((product) => {
                const productFound = finalBasket.find((item) => item.id === product.id);

                if (!productFound) {
                    finalBasket.push({ id: product.id, nome: product.nome, quantidade: product.quantidade + (product.brotherQuantity ?? 0) })
                }
            })

            localStorage.setItem("@ConexaoGAM:checkout", JSON.stringify({
                dadosEntrega: {
                    tipoEntrega: mainOptionDelivery.tipoEntrega,
                    lojaSelecionada: mainOptionDelivery,
                    dadosTerceiro: responsibleWithdrawal && responsibleWithdrawal.retirada_terceiro === "true" ? { nomeCompleto: responsibleWithdrawal.nome_completo, cpf: responsibleWithdrawal.cpf?.replace(/[^\d]+/g, ""), telefone: responsibleWithdrawal.telefone_contato?.replace(/[^\d]+/g, "") } : null,
                    enderecoCliente: mainAddress,
                    receberEmDiasDiferentes: receiveInDifferentsDays
                },
                address: mainAddress,
                itensAtendidos: finalBasket
            }));
            history.push("/checkout/pagamento")
        }
    }

    function keepBuying() {
        history.push("/");
    }

    function onChoosePickupStore(store, responsibleWithdrawal) {
        setMainOptionDelivery(store);
        setResponsibleWithdrawal(responsibleWithdrawal);
    }

    return (
        <BuyStepsTemplate step={2} history={history}>
            <SubHeader title="Revisão do pedido" />
            <ContentWrap>
                <RevisionWrap>
                    <SelectedAddress loading={loadingAddresses} address={mainAddress} showSelectedAddressModal={() => setShowAddressSelectionModal(true)} />
                    {mainAddress && (
                        <DeliveryOptionPicker customerAddressId={mainAddress?.id} cart={cart} store={store} mainOptionDelivery={mainOptionDelivery} setMainOptionDelivery={setMainOptionDelivery} showOverlay={setShowOverlay} showPickupModal={() => setShowPickupModal(true)} responsibleWithdrawal={responsibleWithdrawal} deliveryMultiplicationFactor={deliveryMultiplicationFactor} />
                    )}
                    {mainOptionDelivery && acceptReceiveOnDifferentsDays && (
                        <WarnContainer>
                            <AlertInfo title="Confira os produtos e quantidades antes de finalizar a compra." text={`Alguns produtos da sua cesta estão com datas diferentes para ${mainOptionDelivery.tipoEntrega === DeliveryType.RECEBER_EM_CASA ? 'entrega' : 'retirada'}.`} />

                            <WhatWouldLikeToDoTitleWrap>O que você deseja fazer?</WhatWouldLikeToDoTitleWrap>
                            <StyledInputRadioCustom name="receiveInDifferentsDays" handleChange={handleReveived} options={[{ value: "S", label: `Quero ${mainOptionDelivery.tipoEntrega === DeliveryType.RECEBER_EM_CASA ? 'receber' : 'retirar'} os produtos em datas diferentes` }, { value: "N", label: `Quero ${mainOptionDelivery.tipoEntrega === DeliveryType.RECEBER_EM_CASA ? 'receber' : 'retirar'} todos os produtos em ${mainOptionDelivery.tempo_contingencia_gam}h` }]} rowDirection={false} />
                        </WarnContainer>
                    )}
                    {mainOptionDelivery && (productsWithAssociadaStock.length > 0 || productsWithoutAssociadaStock.length > 0) ? (
                        <ContainerProducts>
                            {productsWithAssociadaStock.length > 0 && (
                                <div>
                                    <TitleWrap>Produtos para {mainOptionDelivery?.tipoEntrega === DeliveryType.RECEBER_EM_CASA ? 'entrega' : 'retirada'} {getFriendlyDateToDeliveryOrPickup(mainOptionDelivery)}</TitleWrap>
                                    {
                                        productsWithAssociadaStock.map((product, index) => (
                                            <ProductCard key={index} index={index} product={product} allowHandleQuantity={false} />
                                        ))
                                    }
                                </div>
                            )}
                            {productsWithoutAssociadaStock.length > 0 && (
                                <div>
                                    <TitleWrap>Produtos para {mainOptionDelivery?.tipoEntrega === DeliveryType.RECEBER_EM_CASA ? 'entrega' : 'retirada'} em até {mainOptionDelivery?.tempo_contingencia_gam}h</TitleWrap>
                                    {
                                        productsWithoutAssociadaStock.map((product, index) => (
                                            <ProductCard key={index} index={index} product={product} allowHandleQuantity={false} />
                                        ))
                                    }
                                </div>
                            )}
                        </ContainerProducts>
                    ) : (
                        <ContainerProducts>
                            <div>
                                <TitleWrap>Produtos</TitleWrap>
                                {productsList.map((product, index) => (
                                    <ProductCard key={index} index={index} product={product} allowHandleQuantity={false} />
                                ))}
                            </div>
                        </ContainerProducts>
                    )}

                </RevisionWrap>
                <PriceInfoWrap>
                    <TitleWrap>Resumo do Pedido</TitleWrap>
                    <PriceInfoContent>
                        <span className="price-description">Subtotal ({productsList.length} itens)</span>
                        <span className="price-value">{formatPrice(subtotal)}</span>
                    </PriceInfoContent>

                    {discount > 0 && (
                        <PriceInfoContent>
                            <span className="price-description">Descontos</span>
                            <span className="price-value">{formatPrice(discount)}</span>
                        </PriceInfoContent>
                    )}

                    {(mainOptionDelivery?.tipoEntrega === DeliveryType.RECEBER_EM_CASA) && (
                        <PriceInfoContent>
                            <span className="price-description">Taxa de entrega</span>
                            <span className="price-value">{formatPrice((mainOptionDelivery.local_entrega?.custo_entrega || mainOptionDelivery.custo_entrega) * deliveryMultiplicationFactor )}</span>
                        </PriceInfoContent>
                    )}

                    <PriceInfoContent className="total">
                        <span className="price-description total">
                            Total
                        </span>
                        <span className="price-value total">{formatPrice(amount + (mainOptionDelivery?.tipoEntrega === DeliveryType.RECEBER_EM_CASA ? parseFloat((mainOptionDelivery.local_entrega?.custo_entrega || mainOptionDelivery.custo_entrega) * deliveryMultiplicationFactor) : 0))}</span>
                    </PriceInfoContent>

                    <StyledButtonPrimary
                        onClick={continueToPayment}
                        text="Avançar"
                        disabled={!mainAddress || !mainOptionDelivery || (productsWithAssociadaStock.length > 0 && productsWithoutAssociadaStock.length > 0 ? receiveInDifferentsDays === undefined : false)}
                    />
                    <StyledButtonSecondary text={"Continuar comprando"} onClick={keepBuying} />
                </PriceInfoWrap>
            </ContentWrap>
            {showAddressSelectionModal && (
                <AddressSelectionModal addresses={addresses} selectedAddress={mainAddress} setSelectedAddress={setMainAddress} closeModal={() => setShowAddressSelectionModal(false)} showOverlay={setShowOverlay} />
            )}
            {showPickupModal && (
                <PickupModal customerAddressId={mainAddress?.id} cart={cart} store={store} closeModal={() => setShowPickupModal(false)} showOverlay={setShowOverlay} onChoosePickupStore={onChoosePickupStore} />
            )}
            {showOverlay && (
                <Overlay>
                    <div>
                        <LoaderContainer className="loader-container">
                            <Loader
                                type="ThreeDots"
                                color={theme.colors.primary}
                                height={25}
                                width={25}
                            />
                        </LoaderContainer>

                        <span>Carregando...</span>
                    </div>
                </Overlay>
            )}
            <PartlyFulfilledOrderModal />
            <ShowAcceptGetItOnDifferentDays showModal={acceptReceiveOnDifferentsDaysModal} handleCloseModal={() => setAcceptReceiveOnDifferentsDaysModal(false)} handleContinue={() => { confirmContinueToPayment(true) }} deliveryType={mainOptionDelivery?.tipoEntrega} />
        </BuyStepsTemplate>
    )
}