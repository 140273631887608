import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;

  span {
    font-weight: 400;
    color: ${props => props.theme.colors.primary};
    font-size: 16px;
  }
`;